import * as React from 'react';
import { Paper, TextField, MenuItem, Typography } from '@material-ui/core';
import { withStyles, createStyles, Theme, WithStyles, emphasize } from '@material-ui/core/styles';
import AsyncSelect from 'react-select/async';
import { ValueContainerProps } from 'react-select/src/components/containers';
import { ControlProps } from 'react-select/src/components/Control';
import { MenuProps, NoticeProps } from 'react-select/src/components/Menu';
import { OptionProps } from 'react-select/src/components/Option';
import { PlaceholderProps } from 'react-select/src/components/Placeholder';
import { SingleValueProps } from 'react-select/src/components/SingleValue';
import { Omit } from '@material-ui/types';
import { BaseTextFieldProps } from '@material-ui/core/TextField';
import { ValueType } from 'react-select/src/types';

import * as ApiService from 'services/apiService';

interface IOptionType {
	label: string;
	value: string;
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			height: 400,
			minWidth: 400,
		},
		input: {
			display: 'flex',
			padding: 0,
			height: 'auto',
		},
		valueContainer: {
			display: 'flex',
			flexWrap: 'wrap',
			flex: 1,
			alignItems: 'center',
			overflow: 'hidden',
		},
		chip: {
			margin: theme.spacing(0.5, 0.25),
		},
		chipFocused: {
			backgroundColor: emphasize(
				theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
				0.08,
			),
		},
		noOptionsMessage: {
			padding: theme.spacing(1, 2),
		},
		singleValue: {
			fontSize: 16,
		},
		placeholder: {
			position: 'absolute',
			left: 2,
			bottom: 6,
			fontSize: 16,
		},
		paper: {
			position: 'absolute',
			zIndex: 1,
			marginTop: theme.spacing(1),
			left: 0,
			right: 0,
		},
		divider: {
			height: theme.spacing(2),
		},
	});

interface IEntitySelectProps extends WithStyles<typeof styles> {
	entityType: string;
	selected?: IOptionType;
	optional: boolean;
	onChange: (entityId: string | null) => void;
}

function NoOptionsMessage(props: NoticeProps<IOptionType>) {
	return (
		<Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
			{props.children}
		</Typography>
	);
}

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> & React.HTMLAttributes<HTMLDivElement>;

function inputComponent({ inputRef, ...props }: InputComponentProps) {
	return <div ref={inputRef} {...props} />;
}

function Control(props: ControlProps<IOptionType>) {
	const {
		children,
		innerProps,
		innerRef,
		selectProps: { classes, TextFieldProps },
	} = props;

	return (
		<TextField
			fullWidth={true}
			InputProps={{
				inputComponent,
				inputProps: {
					className: classes.input,
					ref: innerRef,
					children,
					...innerProps,
				},
			}}
			{...TextFieldProps}
		/>
	);
}

function Option(props: OptionProps<IOptionType>) {
	return (
		<MenuItem
			ref={props.innerRef}
			selected={props.isFocused}
			component="div"
			style={{
				fontWeight: props.isSelected ? 500 : 400,
			}}
			{...props.innerProps}
		>
			{props.children}
		</MenuItem>
	);
}

type MuiPlaceholderProps = Omit<PlaceholderProps<IOptionType>, 'innerProps'> &
	Partial<Pick<PlaceholderProps<IOptionType>, 'innerProps'>>;

function Placeholder(props: MuiPlaceholderProps) {
	const { selectProps, innerProps = {}, children } = props;
	return (
		<Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
			{children}
		</Typography>
	);
}

function SingleValue(props: SingleValueProps<IOptionType>) {
	return (
		<Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
			{props.children}
		</Typography>
	);
}

function ValueContainer(props: ValueContainerProps<IOptionType>) {
	return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props: MenuProps<IOptionType>) {
	return (
		<Paper square={true} className={props.selectProps.classes.paper} {...props.innerProps}>
			{props.children}
		</Paper>
	);
}

class EntitySelect extends React.Component<IEntitySelectProps> {
	public render() {
		const { classes, onChange, selected } = this.props;
		return (
			<div className={classes.root}>
				<AsyncSelect
					loadOptions={this.fetchOptions}
					cacheOptions={true}
					classes={classes}
					components={{
						Control,
						Menu,
						NoOptionsMessage,
						Option,
						Placeholder,
						SingleValue,
						ValueContainer,
					}}
					inputId="react-select"
					TextFieldProps={{
						label: 'Related entity',
						InputLabelProps: {
							htmlFor: 'react-select',
							shrink: true,
						},
					}}
					placeholder="Search entities"
					onChange={(selectedValue: ValueType<IOptionType>) =>
						onChange(
							selectedValue && (selectedValue as IOptionType).value ? (selectedValue as IOptionType).value : null,
						)
					}
					defaultValue={selected}
					defaultOptions={true}
					defaultMenuIsOpen={true}
				/>
			</div>
		);
	}

	private fetchOptions = (inputValue: string) => {
		return new Promise(resolve => {
			const { entityType, optional } = this.props;
			const params = inputValue ? { search: inputValue } : undefined;

			ApiService.get<IOptionType[]>(
				`${process.env.REACT_APP_API_URL}/metadata/secure/entities/search/${entityType}`,
				params,
			).then(entities => {
				if (optional) {
					entities.unshift({ label: 'None', value: '' });
				}

				resolve(entities);
			});
		});
	};
}

export default withStyles(styles)(EntitySelect);

import { observable, action, reaction, toJS, runInAction } from 'mobx';
import { IApiTemplate, IApiTemplatesRequest, TemplateType } from '@mitie/metadata-api-types';

import { Root } from '.';
import { Status } from 'DataTypes';
import * as ApiService from '../services/apiService';
import { handleError } from 'errors';
import { Template } from './template';

abstract class Templates<T> {
	@observable public fetchStatus: Status = Status.None;
	public rootStore: Root;
	public templateType: TemplateType;
	@observable public templatesToFetch: string[] = [];

	constructor(rootStore: Root, templateType: TemplateType) {
		this.rootStore = rootStore;
		this.templateType = templateType;

		reaction(
			() => toJS(this.templatesToFetch),
			templatesToFetch => {
				if (templatesToFetch.length === 0) {
					return;
				}

				const chunkSize = 100;

				while (templatesToFetch.length > 0) {
					const chunk = templatesToFetch.splice(0, chunkSize);
					this.fetchTemplatesByIds(chunk);
				}

				this.templatesToFetch = [];
			},
			{ delay: 300 },
		);
	}

	public abstract getTemplate(id: string): Template<T> | undefined;

	public abstract addAndGetTemplate(templateId: string): Template<T>;

	public abstract get templates(): Array<Template<T>>;

	@action.bound
	public async fetchAll() {
		if (this.fetchStatus !== Status.None) {
			return;
		}

		this.fetchStatus = Status.Loading;

		try {
			const resp: Array<IApiTemplate<T>> = await ApiService.post(
				`${process.env.REACT_APP_API_URL}/metadata/secure/templates/fetch`,
				{
					template_type: this.templateType,
				} as IApiTemplatesRequest,
			);

			runInAction(() => {
				resp.reduce((acc, t) => {
					const template = this.addAndGetTemplate(t.id);
					template.setDataFromApi(t);

					return acc;
				}, {});
			});

			this.fetchStatus = Status.Done;
		} catch (error) {
			handleError(new Error(`Failed to load templates list (${error.message})`));
			this.fetchStatus = Status.Error;
		}
	}

	private async fetchTemplatesByIds(ids: string[]) {
		try {
			const resp: Array<IApiTemplate<T>> = await ApiService.post(
				`${process.env.REACT_APP_API_URL}/metadata/secure/templates/fetch`,
				{
					template_type: this.templateType,
					ids,
				} as IApiTemplatesRequest,
			);

			runInAction(() => {
				let idsMissing = [...ids];

				resp.forEach(templateData => {
					const template = this.addAndGetTemplate(templateData.id);
					template.setDataFromApi(templateData);

					idsMissing = idsMissing.filter(id => id !== templateData.id);
				});

				for (const id of idsMissing) {
					const template = this.getTemplate(id);

					if (template) {
						template.dataRequest = Status.Empty;
					}
				}
			});
		} catch (error) {
			for (const id of ids) {
				const template = this.getTemplate(id);

				if (template) {
					template.dataRequest = Status.Error;
				}
			}

			handleError(new Error(`Failed to load templates details (${error.message})`));
		}
	}
}

export default Templates;

import { IApiTemplateUpdate, IExternalSystemTemplate } from '@mitie/metadata-api-types';
import { action } from 'mobx';

import { Template } from './template';
import { cloneDeep } from 'lodash';
import { Status } from 'DataTypes';

export class ExternalSystemTemplate extends Template<IExternalSystemTemplate> {
	public constructor(id: string) {
		super(id, 'external_system');
	}

	@action.bound
	public setDataFromUi(template: IApiTemplateUpdate<IExternalSystemTemplate>) {
		this.data = this.extractDetails(template);
	}

	@action.bound
	public discardChanges() {
		if (this.savedData) {
			this.data = cloneDeep(this.savedData);
		}
	}

	@action.bound
	public async save() {
		if (this.unsaved) {
			this.saveRequest = Status.Loading;
		}
	}
}

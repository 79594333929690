import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { LinearProgress, TextField, InputAdornment, Button } from '@material-ui/core';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { observable, IReactionDisposer, autorun } from 'mobx';

import Entities from '../store/entities';
import Routes from '../store/routes';
import { Status } from 'DataTypes';
import TreeItem from './TreeItem';
import LocationSelectPopup from './LocationSelectPopup';

const styles = (theme: Theme) =>
	createStyles({
		container: {
			overflow: 'auto',
			padding: theme.spacing(1),
		},
		unsavedCount: {
			height: '1rem',
			lineHeight: '1rem',
			padding: '0.125rem 0.5rem',
			display: 'inline',
			background: theme.palette.secondary.main,
			borderRadius: '0.75rem',
			color: theme.palette.secondary.contrastText,
			marginTop: '13px',
			border: `1px solid ${theme.palette.secondary.dark}`,
		},
		titleText: {
			lineHeight: '48px',
			margin: '0 0.5rem',
		},
		topBar: {
			display: 'flex',
		},
		grow: {
			flexGrow: 1,
		},
		moreMenu: {
			zIndex: 2,
		},
		locationSelect: {
			width: '100%',
		},
		input: {
			disabled: 'disabled',
		},
	});

interface IAssetTreeProps extends WithStyles<typeof styles> {}

@inject('entities', 'routes')
@observer
class AssetTree extends React.Component<IAssetTreeProps> {
	private disposables: IReactionDisposer[] = [];
	@observable private expandedChildren: string[] = [];
	@observable private popupOpen = false;

	private get injected() {
		return (this.props as any) as {
			routes: Routes;
			entities: Entities;
		};
	}

	public componentDidMount() {
		const { entities } = this.injected;

		entities.fetchRootDevices();

		this.disposables.push(
			autorun(() => {
				// Makes sure that the parents of the selected entity are always expanded
				const { parentDeviceIds } = this.injected.routes;

				if (parentDeviceIds && parentDeviceIds.length > 0) {
					for (const rootDevice of entities.rootDevices) {
						if (parentDeviceIds.includes(rootDevice.id) && !this.expandedChildren.includes(rootDevice.id)) {
							this.expandedChildren.push(rootDevice.id);
						}
					}
				}
			}),
		);
	}

	public componentWillUnmount() {
		this.disposables.forEach(d => d());
	}

	public render() {
		const { classes } = this.props;
		const { routes } = this.injected;

		return (
			<div className={classes.container}>
				<TextField
					margin="normal"
					className={classes.locationSelect}
					value={
						routes.location
							? `${routes.location.displayName} > ${routes.location.locationParents
									?.map(l => l.displayName)
									.join(' > ')}`
							: 'Choose location'
					}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Button onClick={() => (this.popupOpen = true)}>...</Button>
							</InputAdornment>
						),
						className: classes.input,
						disabled: true,
					}}
				/>
				{this.popupOpen && (
					<LocationSelectPopup
						location={routes.location}
						onClose={selected => {
							if (selected) {
								routes.setFilters({ location: selected, entity: null });
							}

							this.popupOpen = false;
						}}
					/>
				)}
				{routes.location && (
					<>
						{routes.location.childrenRequest === Status.Loading && <LinearProgress />}
						{routes.location.children.location &&
							routes.location.children.location.map(entity => {
								if (!entity.isEquip) {
									return null;
								}

								return (
									<TreeItem
										key={entity.id}
										entity={entity}
										childrenRelationType="equip"
										expanded={this.expandedChildren.includes(entity.id)}
										onExpand={() => this.expandedChildren.push(entity.id)}
										onCollapse={() => (this.expandedChildren = this.expandedChildren.filter(id => id !== entity.id))}
									/>
								);
							})}
					</>
				)}
			</div>
		);
	}
}

export default withStyles(styles)(AssetTree);

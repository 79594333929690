import { observable, computed } from 'mobx';

class Globals {
	@observable public websocketConnected: boolean = false;
	@observable public savePendingCount = 0;
	@observable public saveTotalCount = 0;

	@computed public get connected() {
		return this.websocketConnected;
	}

	@computed
	public get saveProgress() {
		if (this.saveTotalCount === 0 || this.savePendingCount === 0) {
			return 100;
		}

		return Math.round((1 - this.savePendingCount / this.saveTotalCount) * 100);
	}
}

export default Globals;

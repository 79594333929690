import { IApiTemplate, IApiTemplateUpdate, TemplateType } from '@mitie/metadata-api-types';
import { action, observable, computed } from 'mobx';
import { cloneDeep } from 'lodash';

import { Status } from 'DataTypes';
import { deepEqual } from 'utils';
import { logError } from 'errors';
import * as ApiService from '../services/apiService';

export interface ITemplateData<T> {
	name: string;
	template: T;
}

export abstract class Template<T> {
	public id: string;
	@observable public dataRequest: Status = Status.None;
	@observable public data?: ITemplateData<T>;
	@observable public savedData?: ITemplateData<T>;
	@observable public saveRequest = Status.None;
	@observable public modifiedTime?: Date;
	@observable public usage?: number;
	@observable public usageRequest = Status.None;
	public templateType: TemplateType;

	public constructor(id: string, templateType: TemplateType) {
		this.id = id;
		this.templateType = templateType;
	}

	@computed
	public get created() {
		return Boolean(this.data && !this.savedData);
	}

	@computed
	public get deleted() {
		return Boolean(!this.data && this.savedData);
	}

	@computed
	public get modified() {
		if (!this.data || !this.savedData) {
			return false;
		}

		if (!deepEqual(this.data, this.savedData)) {
			return true;
		}

		return false;
	}

	@computed
	public get unsaved() {
		return this.created || this.deleted || this.modified;
	}

	@computed
	public get displayName() {
		if (!this.data) {
			if (this.savedData) {
				return this.savedData.name;
			} else {
				return this.id;
			}
		}

		return this.data.name;
	}

	@action.bound
	public setDataFromApi(template: IApiTemplate<T>, override: boolean = false) {
		this.savedData = this.extractDetails(template);
		this.modifiedTime = new Date(template.modified_time);

		if (override || !this.data) {
			this.data = cloneDeep(this.savedData);
		}

		this.dataRequest = Status.Done;
	}

	@action.bound
	public setDataFromUi(template: IApiTemplateUpdate<T>) {
		this.data = this.extractDetails(template);
	}

	@action.bound
	public discardChanges() {
		if (this.savedData) {
			this.data = cloneDeep(this.savedData);
		}
	}

	@action.bound
	public delete() {
		if (!this.data) {
			return;
		}

		this.data = undefined;
	}

	@action.bound
	public async fetchUsage() {
		this.usageRequest = Status.Loading;

		try {
			const data: { count: number } = await ApiService.get(
				`${process.env.REACT_APP_API_URL}/metadata/secure/templates/usage/${this.templateType}/${this.id}`,
			);

			this.usage = data.count;

			this.usageRequest = Status.Done;
		} catch (error) {
			logError(error);
			this.usageRequest = Status.Error;
		}
	}

	protected extractDetails({ name, template }: IApiTemplate<T> | IApiTemplateUpdate<T>): ITemplateData<T> {
		return {
			name,
			template,
		};
	}

	protected parseForApi() {
		if (!this.data) {
			return;
		}

		const data: IApiTemplateUpdate<T> = {
			id: this.id,
			name: this.data.name,
			template_type: this.templateType,
			template: this.data.template,
		};

		return data;
	}
}

import * as React from 'react';
import {
	TextField,
	InputAdornment,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@material-ui/core';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import Entity from 'store/entity';
import { autorun, IReactionDisposer, computed, observable, action } from 'mobx';

import { Entities } from 'store';
import { RelationType } from '@mitie/metadata-api-types';
import { Status } from 'DataTypes';
import EntitySelect from './EntitySelect';

const styles = (theme: Theme) =>
	createStyles({
		input: {
			disabled: 'disabled',
		},
	});

interface IRelationSelectProps extends WithStyles<typeof styles> {
	entity: Entity;
	relationType: RelationType;
	label: string;
	className: string;
	optional: boolean;
	disabled: boolean;
	onChange: (entityId: string | null) => void;
}

@inject('entities')
@observer
class RelationSelect extends React.Component<IRelationSelectProps> {
	private disposables: IReactionDisposer[] = [];
	@observable private popupOpen = false;
	@observable private newRelatedEntityId: string | null = null;

	private get injected() {
		return (this.props as any) as {
			entities: Entities;
		};
	}

	@computed
	private get relatedEntity() {
		const { entity, relationType } = this.props;

		if (!entity.data) {
			return undefined;
		}

		const relatedEntityId = entity.data.relations[relationType];

		if (!relatedEntityId) {
			return undefined;
		}

		const relatedEntity = this.injected.entities.addAndGetEntity(relatedEntityId);

		return relatedEntity;
	}

	@computed
	private get relatedEntityChanged() {
		const id = this.relatedEntity ? this.relatedEntity.id : null;

		return id !== this.newRelatedEntityId;
	}

	public componentDidMount() {
		this.disposables.push(
			autorun(() => {
				const { entity, relationType } = this.props;

				if (!entity.data) {
					return;
				}

				const relatedEntityId = entity.data.relations[relationType];

				if (!relatedEntityId) {
					return;
				}

				const relatedEntity = this.injected.entities.addAndGetEntity(relatedEntityId);

				if (relatedEntity.dataRequest === Status.None) {
					relatedEntity.fetchData();
				}
			}),
		);
	}

	public componentWillUnmount() {
		this.disposables.forEach(d => d());
	}

	public render() {
		const { label, className, classes, disabled, relationType, optional } = this.props;

		return (
			<>
				<TextField
					label={label}
					margin="normal"
					variant="outlined"
					className={className}
					disabled={disabled}
					value={this.relatedEntity ? this.relatedEntity.displayName : 'None'}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Button onClick={() => (this.popupOpen = true)}>...</Button>
							</InputAdornment>
						),
						className: classes.input,
						disabled: true,
					}}
				/>
				<Dialog open={this.popupOpen} onClose={() => (this.popupOpen = false)}>
					<DialogTitle>{`Select related ${relationType} entity`}</DialogTitle>
					<DialogContent>
						<EntitySelect
							selected={
								this.relatedEntity ? { label: this.relatedEntity.displayName, value: this.relatedEntity.id } : undefined
							}
							entityType={relationType}
							optional={optional}
							onChange={v => (this.newRelatedEntityId = v)}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => (this.popupOpen = false)} color="primary">
							Cancel
						</Button>
						<Button onClick={this.updateRelatedEntity} color="primary" disabled={!this.relatedEntityChanged}>
							OK
						</Button>
					</DialogActions>
				</Dialog>
			</>
		);
	}

	@action.bound
	private updateRelatedEntity() {
		this.popupOpen = false;
		this.props.onChange(this.newRelatedEntityId);
	}
}

export default withStyles(styles)(RelationSelect);

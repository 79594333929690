import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { action, observable, toJS, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { IParsedCsvFile, IApiEntityUpdate, IApiCsvResponse, IApiCsvRequest } from '@mitie/metadata-api-types';

import { Status } from 'DataTypes';
import { post, socketId, registerImporter } from 'services/apiService';
import Uploader from './Uploader';
import { Entities } from 'store';

const styles = (theme: Theme) =>
	createStyles({
		dropzone: {
			border: '1px solid black',
			margin: '1em',
			padding: '1em',
		},
	});

interface IImportDialogProps extends WithStyles<typeof styles> {
	onClose: () => void;
}

@inject('entities')
@observer
export class ImportDialog extends React.Component<IImportDialogProps> {
	private get injected() {
		return (this.props as any) as {
			entities: Entities;
		};
	}

	@computed
	private get entitiesToImport() {
		const entities: IApiEntityUpdate[] = [];

		for (const file of this.files) {
			if (file.status === Status.Done && file.content && file.content.data) {
				if (file.content.fileType !== null) {
					if (file.content.data.new) {
						entities.push(...file.content.data.new);
					}

					if (file.content.data.changed) {
						entities.push(...file.content.data.changed);
					}
				}
			}
		}

		return entities;
	}
	@observable
	private files: Array<{
		name: string;
		status: Status;
		statusText?: string;
		error?: string;
		content?: IParsedCsvFile;
	}> = [];
	@observable private expandedFile: { name: string; content: IParsedCsvFile } | null = null;

	public render() {
		const { onClose } = this.props;
		return (
			<Dialog open={true} onClose={onClose} maxWidth="md" fullWidth={true}>
				<DialogTitle>{`Entities bulk import`}</DialogTitle>
				<DialogContent style={{ display: 'flex' }}>
					<Uploader
						files={toJS(this.files)}
						expandedFile={this.expandedFile}
						addFiles={this.addFiles}
						removeFile={this.removeFile}
						removeAllFiles={this.removeAllFiles}
						toggleDetailsPanel={this.toggleDetailsPanel}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose} color="primary">
						Cancel
					</Button>
					<Button onClick={this.import} color="primary" autoFocus={true} disabled={this.entitiesToImport.length === 0}>
						{this.entitiesToImport.length === 0
							? 'Import'
							: `Import ${this.entitiesToImport.length} ${this.entitiesToImport.length > 1 ? 'entities' : 'entity'}`}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	@action.bound
	public setFileContent(
		i: number,
		content: { content?: IParsedCsvFile; status: Status; statusText?: string; error?: string },
	) {
		if (!this.files[i]) {
			return;
		}

		this.files[i] = { ...this.files[i], ...content };
	}

	@action.bound
	private addFiles(files: File[]) {
		this.expandedFile = null;

		const initialLength = this.files.length;

		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			this.files.push({ name: file.name, status: Status.Loading });
			this.readFile(file, i + initialLength);
		}
	}

	@action.bound
	private removeFile(index: number) {
		this.files.splice(index, 1);
		this.expandedFile = null;
	}

	@action.bound
	private removeAllFiles() {
		this.files = [];
		this.expandedFile = null;
	}

	@action.bound
	private toggleDetailsPanel(index: number | null) {
		if (this.expandedFile === index || index === null) {
			this.expandedFile = null;
		} else {
			const file = this.files[index];

			if (!file || !file.content) {
				this.expandedFile = null;
			} else {
				this.expandedFile = { name: file.name, content: file.content };
			}
		}
	}

	private readFile(file: File, i: number): Promise<IParsedCsvFile> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = async () => {
				const content = reader.result;

				if (typeof content === 'string') {
					try {
						const { importId } = await post<IApiCsvResponse>(`${process.env.REACT_APP_API_URL}/file-parser/csv`, {
							content,
							socketId: socketId(),
						} as IApiCsvRequest);

						registerImporter(importId, this, i);
					} catch (e) {
						this.setFileContent(i, { status: Status.Error, error: 'Failed to parse file' });
					}
				} else {
					this.setFileContent(i, {
						status: Status.Error,
						error: 'Invalid file format. Must be a text file in CSV format',
					});
				}
			};

			reader.onerror = () => {
				reader.abort();

				this.setFileContent(i, { status: Status.Error, error: `Unable to read file '${file.name}'` });
			};

			reader.readAsText(file);
		});
	}

	private import = () => {
		const { entities } = this.injected;
		entities.addEntitiesFromUi(this.entitiesToImport);

		this.props.onClose();
	};
}

export default withStyles(styles)(ImportDialog);

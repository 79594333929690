import * as React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { List as ListIcon } from '@material-ui/icons';
import { useObserver } from 'mobx-react';

import { useStores } from 'store';

interface INavBarProps {
	open: boolean;
	onClose: () => void;
}

export default function NavBar({ open, onClose }: INavBarProps) {
	const { routes } = useStores();

	return useObserver(() => (
		<Drawer anchor="left" open={open} onClose={onClose}>
			<List>
				{Object.keys(routes.routes).map(routeName => {
					if (routeName === 'default') {
						return null;
					}

					const route = routes.routes[routeName];

					return (
						<ListItem
							button={true}
							key={routeName}
							onClick={() => {
								routes.navigate({ name: routeName });
								onClose();
							}}
						>
							<ListItemIcon>
								<ListIcon />
							</ListItemIcon>
							<ListItemText primary={route.label} />
						</ListItem>
					);
				})}
			</List>
		</Drawer>
	));
}

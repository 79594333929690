export function clone(object: any) {
	return JSON.parse(JSON.stringify(object));
}

export function deepEqual(object1: any, object2: any) {
	return JSON.stringify(object1) === JSON.stringify(object2);
}

export const templateTypeLabel = {
	device: 'device',
	entity: 'entity',
	external_system: 'integration',
};

import * as React from 'react';
import { Chip, TextField, InputAdornment } from '@material-ui/core';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import classNames from 'classnames';

const styles = (theme: Theme) =>
	createStyles({
		tagsContainer: {
			flex: 1,
			height: 'auto',
			maxHeight: 'none',
			flexWrap: 'wrap',
			margin: '0.5rem 0',
		},
		input: {
			flexGrow: 1,
		},
		container: {
			'& input': {
				width: '10rem',
			},
		},
		chip: {
			margin: '0.25rem',
		},
	});

interface ITagsInputProps extends WithStyles<typeof styles> {
	value: string[];
	label?: string;
	className?: string;
	disabled?: boolean;
	onChange: (tags: string[]) => void;
}

@observer
class TagsInput extends React.Component<ITagsInputProps> {
	@observable private input = '';
	private delimiterKeys = ['Enter', 'Tab', ',', ' '];

	public render() {
		const { value, label, className, disabled, classes } = this.props;
		const variant = label ? 'outlined' : 'standard';
		return (
			<TextField
				value={this.input}
				label={label}
				onKeyDown={this.keyDown}
				onChange={event => (this.input = event.currentTarget.value)}
				onBlur={() => (this.input = '')}
				placeholder="Add tag"
				margin="normal"
				variant={variant as any}
				className={classNames([className, classes.container])}
				disabled={disabled}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start" className={classes.tagsContainer}>
							{value.map(tag => (
								<Chip key={tag} label={tag} onDelete={() => this.removeTag(tag)} className={classes.chip} />
							))}
						</InputAdornment>
					),
				}}
			/>
		);
	}

	@action.bound
	private keyDown(event: React.KeyboardEvent<HTMLInputElement>) {
		if (this.delimiterKeys.includes(event.key)) {
			const tag = this.formatTag(this.input);

			if (tag !== '') {
				this.addTag(tag);
				this.input = '';
			}

			event.preventDefault(); // prevent triggering the onChange event
		}
	}

	private addTag(tag: string) {
		const { value, onChange } = this.props;

		if (!value.includes(tag)) {
			onChange([...value, tag]);
		}
	}

	private removeTag(tag: string) {
		const { value, onChange } = this.props;

		if (value.includes(tag)) {
			onChange(value.filter(t => t !== tag));
		}
	}

	private formatTag(str: string) {
		return str.replace(/\W/g, '');
	}
}

export default withStyles(styles)(TagsInput);

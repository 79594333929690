import * as React from 'react';
import { CircularProgress, FormControl, InputLabel, Select } from '@material-ui/core';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { autorun, IReactionDisposer, observable, action, toJS, computed } from 'mobx';
import { subDays, subHours, subWeeks } from 'date-fns';
import { Line } from 'react-chartjs-2';

import Entity from 'store/entity';
import { Status } from 'DataTypes';
import { socket, subscribeToPointData } from 'services/apiService';
import { subMinutes } from 'date-fns/esm';

const styles = (theme: Theme) =>
	createStyles({
		container: {
			margin: '1rem',
			display: 'flex',
			flexDirection: 'column',
		},
		chartContainer: {},
		formControl: {
			margin: theme.spacing(1),
			width: 200,
			marginBottom: '1rem',
		},
	});

interface ISensorGraphProps extends WithStyles<typeof styles> {
	entity: Entity;
}

@observer
class SensorGraph extends React.Component<ISensorGraphProps> {
	private disposables: IReactionDisposer[] = [];
	@observable private fetchStatus: Status = Status.None;
	@observable private data: Array<{ x: Date; y: number }> = [];
	@observable private dateRange = '1d';

	public componentDidMount() {
		socket.on('telemetry', ({ time, value, pointId }: { time: string; value: number; pointId: string }) => {
			if (pointId === this.props.entity.id) {
				const newPoint = { x: new Date(time), y: value };
				this.refreshData([newPoint]);
			}
		});

		this.disposables.push(
			autorun(async () => {
				const { entity } = this.props;
				this.fetchStatus = Status.Loading;
				this.data = [];
				subscribeToPointData(entity.id);

				try {
					const data = await this.fetchData();
					this.refreshData(data);

					this.fetchStatus = Status.Done;
				} catch (e) {
					this.fetchStatus = Status.Error;
				}
			}),
		);
	}

	public componentWillUnmount() {
		this.disposables.forEach(d => d());
	}

	public render() {
		const { entity, classes } = this.props;
		const data = {
			datasets: [
				{
					label: entity.id,
					data: toJS(this.data),
					pointHitRadius: 20,
					pointHoverRadius: 0,
					pointRadius: 0,
					borderColor: '#FF5733',
					fill: false,
				},
			],
		};

		return (
			<div className={classes.container}>
				<FormControl className={classes.formControl}>
					<InputLabel htmlFor="daterange-select">Date range</InputLabel>
					<Select
						native={true}
						value={this.dateRange}
						onChange={this.changeDateRange}
						inputProps={{
							id: 'daterange-select',
						}}
					>
						<option value="10m">Last 10 minutes</option>
						<option value="1h">Last 1 hour</option>
						<option value="6h">Last 6 hours</option>
						<option value="1d">Last 24 hours</option>
						<option value="1w">Past Week</option>
					</Select>
				</FormControl>
				<div className={classes.chartContainer}>
					{this.fetchStatus === Status.Loading ? (
						<CircularProgress />
					) : (
						<Line
							data={data}
							options={{
								scales: {
									xAxes: [
										{
											type: 'time',
										},
									],
									yAxes: [
										{
											ticks: {
												userCallback: (value: any) => (this.units ? `${value} ${this.units}` : value),
											},
										},
									],
								},
								legend: {
									display: false,
								},
								tooltips: {
									mode: 'single',
									callbacks: {
										label: ({ value }: { value: string }) => (this.units ? `${value} ${this.units}` : value),
									},
								},
								animation: false,
							}}
						/>
					)}
				</div>
			</div>
		);
	}

	@computed
	private get units() {
		const { entity } = this.props;

		if (!entity.data || !entity.data.properties.units) {
			return null;
		}

		return entity.data.properties.units;
	}

	@action.bound
	private async changeDateRange(
		e: React.ChangeEvent<{
			name?: string | undefined;
			value: unknown;
		}>,
	) {
		this.dateRange = e.currentTarget.value as string;
		const data = await this.fetchData();
		this.refreshData(data);
	}

	private async fetchData() {
		const { entity } = this.props;
		const endDate = new Date();
		const apiData = await entity.fetchTelemetry(this.getStartDate(endDate), endDate);

		return apiData.map(p => ({ x: new Date(p.time), y: p.value }));
	}

	@action.bound
	private refreshData(newPoints?: Array<{ x: Date; y: number }>) {
		const newData = toJS(this.data);
		if (newPoints) {
			for (const newPoint of newPoints) {
				if (!newData.find(p => p.x.getTime() === newPoint.x.getTime())) {
					newData.push(newPoint);
				}
			}
		}

		const endDate = new Date();
		const startDate = this.getStartDate(endDate);
		this.data = newData.filter(p => p.x > startDate && p.x <= endDate).sort((a, b) => a.x.getTime() - b.x.getTime());
	}

	private getStartDate(endDate: Date) {
		switch (this.dateRange) {
			case '10m':
				return subMinutes(endDate, 10);
			case '1h':
				return subHours(endDate, 1);
			case '6h':
				return subHours(endDate, 6);
			case '1w':
				return subWeeks(endDate, 1);
			case '1d':
			default:
				return subDays(endDate, 1);
		}
	}
}

export default withStyles(styles)(SensorGraph);

import React from 'react';
import { useObserver } from 'mobx-react';
import { Typography, LinearProgress, FormGroup, FormControlLabel, Checkbox, Radio } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { Status } from 'DataTypes';
import EntityProperty from './EntityProperty';
import CustomPropertiesTable from './CustomPropertiesTable';
import { ExternalSystemTemplate } from 'store/externalSystemTemplate';
import DeviceTwinTagsList from './DeviceTwinTagsList';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			margin: theme.spacing(2),
			overflow: 'auto',
		},
		propertyValue: {
			display: 'inline',
		},
		field: {
			marginRight: '1rem',
			minWidth: '25rem',
		},
		sectionTitle: {
			marginTop: theme.spacing(1),
		},
	}),
);

interface IIntegrationTemplateDetailsProps {
	template: ExternalSystemTemplate;
}

export default function IntegrationTemplateDetails({ template }: IIntegrationTemplateDetailsProps) {
	const classes = useStyles();

	return useObserver(() => (
		<div className={classes.root}>
			{template.dataRequest === Status.Loading && <LinearProgress />}
			{template.data && (
				<>
					<EntityProperty
						label="Template ID"
						type="string"
						value={template.id}
						onChange={value => (template.id = value as string)}
						className={classes.field}
						disabled={true}
					/>
					<EntityProperty
						label="Name"
						type="string"
						value={template.data?.name}
						onChange={value => template.data && (template.data.name = value as string)}
						className={classes.field}
					/>
					<div>
						<Typography variant="body1">{`Last modified: ${template.modifiedTime?.toLocaleString()}`}</Typography>
					</div>
					<div>
						<Typography variant="h6" className={classes.sectionTitle}>
							Integration features
						</Typography>
						<FormGroup>
							<FormControlLabel
								control={
									<Radio
										checked={template.data.template.features.includes('manual')}
										onChange={e => {
											if (!template.data) {
												return;
											}

											if (e.target.checked) {
												template.data.template.features.push('manual');
												template.data.template.features = template.data.template.features.filter(f => f !== 'slave');
											} else {
												template.data.template.features.push('slave');
												template.data.template.features = template.data.template.features.filter(f => f !== 'manual');
											}
										}}
									/>
								}
								label="No automated synchronisation. The corresponding entity in the external system needs to be created
								manually and the corresponding IDs to be added manually below"
							/>
							<FormControlLabel
								control={
									<Radio
										checked={template.data.template.features.includes('slave')}
										onChange={e => {
											if (!template.data) {
												return;
											}

											if (e.target.checked) {
												template.data.template.features.push('slave');
												template.data.template.features = template.data.template.features.filter(f => f !== 'manual');
											} else {
												template.data.template.features.push('manual');
												template.data.template.features = template.data.template.features.filter(f => f !== 'slave');
											}
										}}
									/>
								}
								label="One-way automated synchronisation. Entity in the external system is created and updated based on
								the entity data"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={template.data.template.features.includes('telemetry')}
										onChange={e => {
											if (!template.data) {
												return;
											}

											if (e.target.checked) {
												template.data.template.features.push('telemetry');
											} else {
												template.data.template.features = template.data.template.features.filter(
													f => f !== 'telemetry',
												);
											}
										}}
									/>
								}
								label="The telemetry will be forwarded automatically to the external system if the entity is a device and
								the corresponding entity exists in the external system"
							/>
						</FormGroup>
					</div>
					<CustomPropertiesTable properties={template.data.template.properties} />
					<Typography variant="h6" className={classes.sectionTitle}>
						Device twin tags
					</Typography>
					<DeviceTwinTagsList
						tags={template.data?.template.device_twin_tags || {}}
						setTags={tags => {
							if (!template.data) {
								return;
							}

							template.data.template.device_twin_tags = tags;
						}}
					/>
				</>
			)}
		</div>
	));
}

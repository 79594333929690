import { observable, action, computed } from 'mobx';
import { IEntityTemplate } from '@mitie/metadata-api-types';
import { v4 } from 'uuid';

import Templates from './templates';
import { Root } from 'store';
import { EntityTemplate } from './entityTemplate';

export default class EntityTemplates extends Templates<IEntityTemplate> {
	@observable private list: { [id: string]: EntityTemplate } = {};

	constructor(rootStore: Root) {
		super(rootStore, 'entity');
	}

	public getTemplate(id: string): EntityTemplate | undefined {
		return this.list[id];
	}

	@action.bound
	public addAndGetTemplate(templateId: string): EntityTemplate {
		const template = this.getTemplate(templateId);

		if (template) {
			return template;
		}

		const newTemplate = new EntityTemplate(templateId);
		this.list[templateId] = newTemplate;

		return newTemplate;
	}

	@computed
	public get templates() {
		return Object.keys(this.list)
			.map(id => this.list[id])
			.sort((a, b) => a.displayName.localeCompare(b.displayName));
	}

	public createTemplate() {
		const entityId = v4();
		const newTemplate = this.addAndGetTemplate(entityId);

		newTemplate.setDataFromUi({
			id: entityId,
			name: 'New entity template',
			template_type: 'entity',
			template: {
				properties: [],
				tags: [],
				points_templates: [],
				children: [],
			},
		});

		return newTemplate;
	}

	public deleteTemplate(template: EntityTemplate) {
		delete this.list[template.id];
	}
}

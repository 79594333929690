import React, { useState, forwardRef } from 'react';
import { useObserver } from 'mobx-react';
import { reaction, runInAction } from 'mobx';
import { Chip } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Edit, Check, AddBox, DeleteOutline, ArrowUpward, Clear, Remove } from '@material-ui/icons';
import { IEntityPointTemplate, IApiTemplateUpdate } from '@mitie/metadata-api-types';
import MaterialTable from 'material-table';

import { useStores } from 'store';
import { EntityTemplate } from 'store/entityTemplate';
import { Template } from 'store/template';
import TagsInput from './TagsInput';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			margin: theme.spacing(2),
			overflow: 'auto',
		},
		selectChip: {
			marginRight: theme.spacing(1),
			marginBottom: theme.spacing(1) / 2,
		},
		propertyValue: {
			display: 'inline',
		},
		tabs: {
			borderBottom: `1px solid ${theme.palette.divider}`,
		},
		tab: {
			'&>span:first-child': {
				flexDirection: 'row',
			},
		},
		field: {
			marginRight: '1rem',
			minWidth: '25rem',
		},
		tagsInput: {
			margin: 0,
		},
	}),
);

interface IEntityPointsDefinitionTableProps {
	template: EntityTemplate;
}

interface ITableRow {
	id: string;
	name: string;
	tags: string[];
	datatype: 'number' | 'boolean' | 'any';
	defaultUnits: string | '';
	tableData?: {
		editing?: boolean;
	};
}

export default function EntityPointsDefinitionTable({ template }: IEntityPointsDefinitionTableProps) {
	const classes = useStyles();

	const { entityPointTemplates: entityPointTemplatesStore } = useStores();
	const [tableData, setTableData] = useState([] as ITableRow[]);
	const [epTemplates, setEpTemplates] = useState([] as Array<Template<IEntityPointTemplate>>);

	// Fetch entity points data
	React.useEffect(() => {
		if (!template.data?.template.points_templates) {
			setEpTemplates([]);
		} else {
			const data = template.data.template.points_templates.map(entityPointTemplateId => {
				const epTemplate = entityPointTemplatesStore.addAndGetTemplate(entityPointTemplateId);
				entityPointTemplatesStore.templatesToFetch.push(entityPointTemplateId);

				return epTemplate;
			});

			setEpTemplates(data);
		}
	}, [entityPointTemplatesStore, template.data]);

	// Update table datta from template content
	React.useEffect(() => {
		reaction(
			() => epTemplates.map(t => t.data),
			() => {
				setTableData(
					epTemplates.map(epTemplate => {
						return {
							id: epTemplate.id,
							name: epTemplate.data?.name,
							tags: epTemplate.data?.template.tags,
							datatype: epTemplate.data?.template.datatype || 'any',
							defaultUnits: epTemplate.data?.template.default_units || '',
						} as ITableRow;
					}),
				);
			},
			{ fireImmediately: true },
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [epTemplates]);

	return useObserver(() => (
		<MaterialTable<ITableRow>
			columns={[
				{ title: 'Name', field: 'name', editable: 'onAdd' },
				{ title: 'Data type', field: 'datatype', lookup: { any: 'Any', number: 'Number', boolean: 'Boolean' } },
				{ title: 'Default units', field: 'defaultUnits' },
				{
					title: 'Tags',
					field: 'tags',
					editComponent: ({ value, onChange }) => <TagsInput value={value || []} onChange={onChange} />,
					render: ({ tags }) => (tags || []).map(tag => <Chip className={classes.selectChip} key={tag} label={tag} />),
				},
			]}
			data={tableData}
			editable={{
				onRowAdd: newData => {
					if (!newData.name || tableData.find(p => p.name === newData.name)) {
						return Promise.reject();
					} else {
						if (!template.data) {
							return Promise.reject();
						}

						const epTemplate = entityPointTemplatesStore.createTemplate(
							newData.name,
							newData.tags,
							newData.datatype === 'any' ? undefined : newData.datatype,
							newData.defaultUnits || undefined,
						);

						newData.id = epTemplate.id;

						if (!template.data!.template.points_templates) {
							template.data!.template.points_templates = [];
						}

						template.data!.template.points_templates.push(epTemplate.id);

						const newTableData = [...tableData];
						newTableData.push(newData);
						setTableData(newTableData);

						return Promise.resolve();
					}
				},
				onRowUpdate: (newData, oldData) => {
					if (!newData.name || tableData.filter(p => p.name === newData.name).length > 1) {
						return Promise.reject();
					} else {
						if (oldData) {
							const rowIndex = tableData.findIndex(p => p.name === oldData.name);

							if (rowIndex !== -1) {
								const epTemplate = entityPointTemplatesStore.getTemplate(newData.id);

								if (!epTemplate || !epTemplate.data) {
									return Promise.reject();
								}

								const data: IApiTemplateUpdate<IEntityPointTemplate> = {
									id: newData.id,
									name: newData.name,
									template_type: 'entity_point',
									template: {
										tags: newData.tags,
									},
								};

								if (newData.datatype !== 'any') {
									data.template.datatype = newData.datatype;
								}

								if (newData.defaultUnits) {
									data.template.default_units = newData.defaultUnits;
								}

								epTemplate.setDataFromUi(data);

								const newTableData = [...tableData];
								newTableData[rowIndex] = newData;
								setTableData(newTableData);
							}
						}
						return Promise.resolve();
					}
				},
				onRowDelete: oldData => {
					const rowIndex = tableData.findIndex(p => p.name === oldData.name);
					if (rowIndex !== -1) {
						const epTemplate = entityPointTemplatesStore.getTemplate(oldData.id);

						if (!epTemplate || !epTemplate.data) {
							return Promise.reject();
						}

						runInAction(() => {
							if (template.data!.template.points_templates) {
								template.data!.template.points_templates = template.data!.template.points_templates.filter(
									epId => epId !== epTemplate.id,
								);
							}
							epTemplate.delete();
						});

						const newTableData = [...tableData];
						newTableData.splice(rowIndex, 1);
						setTableData(newTableData);
					}
					return Promise.resolve();
				},
			}}
			options={{ paging: false, padding: 'dense', search: false, draggable: false, showTitle: false }}
			icons={{
				Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
				Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
				Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
				SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
				Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
				Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
				ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
			}}
		/>
	));
}

export type NotificationTypes = 'success' | 'error' | 'warning' | 'info';

export interface INotification {
	id: string;
	message: string;
	type: NotificationTypes;
	timeout: number | null;
}

export interface IDataPoint {
	time: string;
	value: number;
}

export enum Status {
	None,
	Loading,
	Error,
	Done,
	Empty,
}

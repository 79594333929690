import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { LinearProgress } from '@material-ui/core';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { observable, IReactionDisposer, autorun, computed } from 'mobx';

import Entities from '../store/entities';
import DeviceTemplates from '../store/deviceTemplates';
import Routes from '../store/routes';
import { Status } from 'DataTypes';
import TreeItemGroup from './TreeItemGroup';
import Entity from 'store/entity';

const styles = (theme: Theme) =>
	createStyles({
		container: {
			overflow: 'auto',
			padding: theme.spacing(1),
		},
		unsavedCount: {
			height: '1rem',
			lineHeight: '1rem',
			padding: '0.125rem 0.5rem',
			display: 'inline',
			background: theme.palette.secondary.main,
			borderRadius: '0.75rem',
			color: theme.palette.secondary.contrastText,
			marginTop: '13px',
			border: `1px solid ${theme.palette.secondary.dark}`,
		},
		titleText: {
			lineHeight: '48px',
			margin: '0 0.5rem',
		},
		topBar: {
			display: 'flex',
		},
		grow: {
			flexGrow: 1,
		},
		moreMenu: {
			zIndex: 2,
		},
	});

interface IDeviceTreeProps extends WithStyles<typeof styles> {}

@inject('entities', 'routes', 'deviceTemplates')
@observer
class DeviceTree extends React.Component<IDeviceTreeProps> {
	private disposables: IReactionDisposer[] = [];
	@observable private expandedChildren: string[] = [];

	private get injected() {
		return (this.props as any) as {
			routes: Routes;
			entities: Entities;
			deviceTemplates: DeviceTemplates;
		};
	}

	@computed private get rootDevicesByType() {
		const { entities } = this.injected;

		return entities.rootDevices.reduce((acc, cur) => {
			const deviceTemplateId = cur.data?.templates.device || 'none';

			if (!acc[deviceTemplateId]) {
				acc[deviceTemplateId] = [];
			}

			acc[deviceTemplateId].push(cur);

			return acc;
		}, {} as { [templateId: string]: Entity[] });
	}

	public componentDidMount() {
		const { entities } = this.injected;

		entities.fetchRootDevices();

		this.disposables.push(
			autorun(() => {
				// Makes sure that the parents of the selected entity are always expanded
				const { parentDeviceIds } = this.injected.routes;

				if (parentDeviceIds && parentDeviceIds.length > 0) {
					for (const rootDevice of entities.rootDevices) {
						if (parentDeviceIds.includes(rootDevice.id) && !this.expandedChildren.includes(rootDevice.id)) {
							this.expandedChildren.push(rootDevice.id);
						}
					}
				}
			}),
		);
	}

	public componentWillUnmount() {
		this.disposables.forEach(d => d());
	}

	public render() {
		const { classes } = this.props;
		const { entities, deviceTemplates } = this.injected;

		return (
			<div className={classes.container}>
				{entities.rootDevicesFetchStatus === Status.Loading ? (
					<LinearProgress />
				) : (
					Object.keys(this.rootDevicesByType).map(templateId => {
						const devices = this.rootDevicesByType[templateId];

						return (
							<TreeItemGroup
								key={templateId}
								label={deviceTemplates.getTemplate(templateId)?.displayName || 'Unknown template'}
								children={devices}
								childrenRelationType="gateway"
								expanded={this.expandedChildren.includes(templateId)}
								onExpand={() => this.expandedChildren.push(templateId)}
								onCollapse={() => (this.expandedChildren = this.expandedChildren.filter(id => id !== templateId))}
							/>
						);
					})
				)}
			</div>
		);
	}
}

export default withStyles(styles)(DeviceTree);

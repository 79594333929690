import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import store from './store';

const appInsights = new ApplicationInsights({
	config: {
		instrumentationKey: `${process.env.REACT_APP_APPINSIGHT_KEY}`,
		disableFetchTracking: false,
		appId: `${process.env.REACT_APP_AZURE_AD_CLIENT_ID}`,
	},
});
appInsights.loadAppInsights();

export function logError(error: Error) {
	if (process.env.NODE_ENV === 'development') {
		// tslint:disable-next-line: no-console
		console.error(error);
	}
}

function showError(message: string) {
	store.notifications.addNotification('error', message);
}

export function handleError(error: Error) {
	showError(error.message);
	logError(error);
}

window.addEventListener('error', ev => {
	showError(ev.message);
});

import * as React from 'react';
import { inject, observer } from 'mobx-react';
import {
	Typography,
	TextField,
	IconButton,
	Menu,
	MenuItem,
	Tooltip,
	Divider,
	LinearProgress,
	Tab,
	Tabs,
	ListItemIcon,
} from '@material-ui/core';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import {
	Save as SaveIcon,
	MoreVert as MoreVertIcon,
	RemoveCircle as RemoveCircleIcon,
	Undo as UndoIcon,
	OfflineBoltOutlined,
	Done,
} from '@material-ui/icons';
import classNames from 'classnames';

import Entities from '../store/entities';
import Routes from '../store/routes';
import { action, observable, computed, autorun, IReactionDisposer } from 'mobx';
import { Status } from 'DataTypes';
import TagsInput from './TagsInput';
import Templates from '../store/templates';
import DeviceTwinProperty from './DeviceTwinProperty';
import DeviceReadOnlyProperty from './DeviceReadOnlyProperty';
import EntityExternalSystemMapping from './EntityExternalInterfaceMapping';
import DevicePointsList from './DevicePointsList';
import EntityPointsList from './EntityPointsList';
import EntityProperty from './EntityProperty';
import {
	IDeviceTemplate,
	IEntityTemplate,
	IExternalSystemTemplate,
	IEntityPointTemplate,
	IDevicePointTemplate,
	EntityLifecycleStatus,
} from '@mitie/metadata-api-types';
import Relations from './Relations';

const styles = (theme: Theme) =>
	createStyles({
		container: {
			padding: '0 1rem',
		},
		field: {
			marginRight: '1rem',
			minWidth: '25rem',
		},
		badge: {
			height: '1rem',
			lineHeight: '1rem',
			padding: '0.125rem 0.5rem',
			display: 'inline',
			borderRadius: '0.75rem',
			marginTop: '13px',
		},
		unsavedbadge: {
			background: theme.palette.secondary.main,
			color: theme.palette.secondary.contrastText,
			// border: `1px solid ${theme.palette.secondary.dark}`,
		},
		errorbadge: {
			background: theme.palette.error.main,
			color: theme.palette.secondary.contrastText,
			// border: `1px solid ${theme.palette.secondary.dark}`,
		},
		okBadge: {
			background: 'green',
			color: theme.palette.secondary.contrastText,
			// border: `1px solid ${theme.palette.secondary.dark}`,
		},
		grow: {
			flexGrow: 1,
		},
		topBar: {
			display: 'flex',
		},
		moreMenu: {
			zIndex: 2,
		},
		titleText: {
			lineHeight: '48px',
			margin: '0 0.5rem',
		},
		iconsDivider: {
			width: '1px',
			backgroundColor: theme.palette.grey[400],
			height: '48px',
			margin: '0 12px',
		},
		sectionTitle: {
			marginTop: '1rem',
			'&>p': {
				fontSize: '16px',
				fontWeight: 500,
				display: 'inline',
				marginRight: '1rem',
			},
		},
		button: {
			cursor: 'pointer',
		},
		externalMappings: {},
		tabs: {
			borderBottom: `1px solid ${theme.palette.divider}`,
		},
		tab: {
			'&>span:first-child': {
				flexDirection: 'row',
			},
		},
		tabIcon: {
			marginLeft: '0.5rem',
			color: '#009900',
		},
	});

interface IEntityViewProps extends WithStyles<typeof styles> {}

export enum TabName {
	Templates = 0,
	Entity = 1,
	DeviceTwin = 2,
	DevicePoint = 3,
	EntityPoint = 4,
	Integration = 5,
}

@inject(
	'entities',
	'routes',
	'deviceTemplates',
	'entityTemplates',
	'externalSystemTemplates',
	'devicePointTemplates',
	'entityPointTemplates',
)
@observer
class EntityView extends React.Component<IEntityViewProps> {
	private disposables: IReactionDisposer[] = [];
	@observable private moreMenuAnchorEl: HTMLElement | null = null;

	private get injected() {
		return (this.props as any) as {
			routes: Routes;
			entities: Entities;
			entityTemplates: Templates<IEntityTemplate>;
			deviceTemplates: Templates<IDeviceTemplate>;
			externalSystemTemplates: Templates<IExternalSystemTemplate>;
			devicePointTemplates: Templates<IDevicePointTemplate>;
			entityPointTemplates: Templates<IEntityPointTemplate>;
		};
	}

	public componentDidMount() {
		const {
			deviceTemplates,
			entityTemplates,
			externalSystemTemplates,
			devicePointTemplates,
			entityPointTemplates,
		} = this.injected;

		entityTemplates.fetchAll();
		deviceTemplates.fetchAll();
		externalSystemTemplates.fetchAll();
		devicePointTemplates.fetchAll();
		entityPointTemplates.fetchAll();

		this.disposables.push(
			autorun(() => {
				if (this.entity && !this.entity.created && this.entity.dataRequest === Status.None) {
					this.entity.fetchData();
				}
			}),
			autorun(() => {
				// Switch tab if selected tab does not exist for entity
				const { setFilters } = this.injected.routes;
				if (
					this.entity &&
					!this.entity.isDevice &&
					(this.selectedTab === TabName.DeviceTwin || this.selectedTab === TabName.DevicePoint)
				) {
					setFilters({ selectedTab: TabName.Entity });
				} else if (!this.hasDeviceTwin && this.selectedTab === TabName.DeviceTwin) {
					setFilters({ selectedTab: TabName.Entity });
				}
			}),
		);
	}

	public componentWillUnmount() {
		this.disposables.forEach(d => d());
	}

	@computed
	private get entity() {
		const { entity } = this.injected.routes;

		if (!entity) {
			return undefined;
		}

		return entity;
	}

	@computed
	private get entityLoading() {
		if (!this.entity) {
			return false;
		}

		return this.entity.dataRequest === Status.Loading || this.entity.saveRequest === Status.Loading;
	}

	@computed
	private get hasDeviceTwin() {
		if (!this.entity || !this.entity.data) {
			return false;
		}

		return !!this.entity.data.deviceProperties;
	}

	@computed
	private get entityTemplate() {
		if (!this.entity || !this.entity.data || !this.entity.data.templates.entity) {
			return undefined;
		}

		return this.injected.entityTemplates.getTemplate(this.entity.data.templates.entity);
	}

	@computed
	private get deviceTemplate() {
		if (!this.entity || !this.entity.data || !this.entity.data.templates.device) {
			return undefined;
		}

		return this.injected.deviceTemplates.getTemplate(this.entity.data.templates.device);
	}

	@computed private get selectedTab() {
		return this.injected.routes.selectedTab;
	}

	public render() {
		const { classes } = this.props;
		const { templates: deviceTemplates } = this.injected.deviceTemplates;
		const { templates: entityTemplates } = this.injected.entityTemplates;

		if (!this.entity) {
			return null;
		}

		return (
			<div className={classes.container}>
				<div className={classes.topBar}>
					<Typography variant="h6" className={classes.titleText}>
						Entity details
					</Typography>
					{this.entity.unsaved && (
						<Typography variant="caption" className={classNames([classes.badge, classes.unsavedbadge])}>
							{this.entity.created ? 'New' : this.entity.deleted ? 'Deleted' : 'Modified'}
						</Typography>
					)}
					<div className={classes.grow} />
					{!this.entity.created && !this.entity.deleted && (
						<Tooltip title="Delete entity" placement="bottom">
							<IconButton color="default" onClick={this.deleteEntity}>
								<RemoveCircleIcon />
							</IconButton>
						</Tooltip>
					)}
					{this.entity.unsaved && (
						<React.Fragment>
							<Tooltip title="Discard changes" placement="bottom">
								<IconButton color="default" onClick={this.discardChanges}>
									<UndoIcon />
								</IconButton>
							</Tooltip>
							<div className={classes.iconsDivider} />
							<Tooltip title="Save entity" placement="bottom">
								<IconButton color="secondary" onClick={this.save}>
									<SaveIcon />
								</IconButton>
							</Tooltip>
						</React.Fragment>
					)}
					<IconButton onClick={this.toggleMenu}>
						<MoreVertIcon />
					</IconButton>
					<Menu
						anchorEl={this.moreMenuAnchorEl}
						keepMounted={true}
						open={Boolean(this.moreMenuAnchorEl)}
						onClose={this.closeMenu}
						elevation={0}
						getContentAnchorEl={null}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
					>
						<MenuItem disabled={this.entity!.created || this.entity!.deleted} onClick={this.deleteEntity}>
							<ListItemIcon>
								<RemoveCircleIcon />
							</ListItemIcon>
							<Typography variant="inherit">Delete entity</Typography>
						</MenuItem>
						<MenuItem disabled={!this.entity!.unsaved} onClick={this.discardChanges}>
							<ListItemIcon>
								<UndoIcon />
							</ListItemIcon>
							<Typography variant="inherit">Discard changes</Typography>
						</MenuItem>
						<Divider />
						<MenuItem disabled={!this.entity!.unsaved} onClick={this.save}>
							<ListItemIcon>
								<SaveIcon />
							</ListItemIcon>
							<Typography variant="inherit">Save entity</Typography>
						</MenuItem>
					</Menu>
				</div>
				<Divider />
				<div>
					{this.entityLoading && <LinearProgress />}
					{this.entity.data && (
						<>
							<Tabs
								value={this.selectedTab}
								onChange={(event, value: TabName) => this.injected.routes.setFilters({ selectedTab: value })}
								variant="scrollable"
								scrollButtons="auto"
								className={classes.tabs}
							>
								<Tab value={TabName.Templates} label="Templates" className={classes.tab} />
								<Tab value={TabName.Entity} label="Entity" className={classes.tab} />
								{this.hasDeviceTwin && (
									<Tab
										value={TabName.DeviceTwin}
										label={
											<>
												Device Twin
												{this.entity.deviceOnline ? (
													<Done className={classes.tabIcon} />
												) : (
													<OfflineBoltOutlined className={classes.tabIcon} color="error" />
												)}
											</>
										}
										className={classes.tab}
									/>
								)}
								{this.entity.isDevice && (
									<Tab value={TabName.DevicePoint} label="Device Points" className={classes.tab} />
								)}
								<Tab value={TabName.EntityPoint} label="Entity Points" className={classes.tab} />
								<Tab value={TabName.Integration} label="Integration" className={classes.tab} />
							</Tabs>
							{this.selectedTab === TabName.Templates && (
								<div>
									<TextField
										value={this.entity.data.templates.entity || ''}
										select={true}
										label="Entity template"
										onChange={this.updateEntityTemplate}
										margin="normal"
										variant="outlined"
										className={classes.field}
										SelectProps={{
											native: true,
										}}
										InputLabelProps={{
											shrink: true,
										}}
									>
										<option value="">No template</option>
										{entityTemplates.map(({ id, data }) => (
											<option value={id} key={id}>
												{data?.name}
											</option>
										))}
									</TextField>
									{this.entity.isDevice && (
										<TextField
											value={this.entity.data.templates.device || ''}
											select={true}
											label="Device template"
											onChange={this.updateDeviceTemplate}
											margin="normal"
											variant="outlined"
											className={classes.field}
											SelectProps={{
												native: true,
											}}
											InputLabelProps={{
												shrink: true,
											}}
										>
											<option value="">No device template</option>
											{deviceTemplates.map(({ id, data }) => (
												<option value={id} key={id}>
													{data?.name}
												</option>
											))}
										</TextField>
									)}
								</div>
							)}
							{this.selectedTab === TabName.Entity && (
								<div>
									<TextField
										label="Name"
										value={this.entity.data.name}
										onChange={e => (this.entity!.data!.name = e.target.value)}
										margin="normal"
										variant="outlined"
										className={classes.field}
										disabled={this.entityLoading}
									/>
									<EntityProperty
										label="Lifecycle status"
										type="string"
										value={this.entity.data.lifecycleStatus}
										select={[
											{ value: 'Configuration', label: 'Configuration' },
											{ value: 'Roll Out', label: 'Roll out / Tuning' },
											{ value: 'On Hold', label: 'On Hold' },
											{ value: 'Live', label: 'Live' },
											{ value: 'Decommissioned', label: 'Decommissioned' },
										]}
										onChange={value => (this.entity!.data!.lifecycleStatus = value as EntityLifecycleStatus)}
										className={classes.field}
									/>
									<TagsInput
										label="Tags"
										value={this.entity.data.tags}
										onChange={this.updateTags}
										className={classes.field}
										disabled={this.entityLoading}
									/>
									<Relations entity={this.entity} loading={this.entityLoading} />
									{this.entityTemplate?.data?.template.properties && (
										<div>
											{this.entityTemplate.data.template.properties.map(prop => {
												return (
													<EntityProperty
														key={prop.name}
														label={prop.label}
														type={prop.type}
														value={this.entity!.getProperty(prop.name)}
														select={prop.select}
														onChange={value => (this.entity!.data!.properties[prop.name] = value)}
														className={classes.field}
													/>
												);
											})}
										</div>
									)}
									{this.deviceTemplate?.data?.template.properties && (
										<div>
											{this.deviceTemplate.data.template.properties.map(prop => {
												return (
													<EntityProperty
														key={prop.name}
														label={prop.label}
														type={prop.type}
														value={this.entity!.getProperty(prop.name)}
														select={prop.select}
														onChange={value => (this.entity!.data!.properties[prop.name] = value)}
														className={classes.field}
													/>
												);
											})}
										</div>
									)}
									{this.entity.updatedTime && (
										<div>
											<Typography variant="body1">{`Last modified: ${this.entity.updatedTime.toLocaleString()}`}</Typography>
										</div>
									)}
								</div>
							)}
							{this.selectedTab === TabName.DeviceTwin && (
								<div>
									<div className={classes.sectionTitle}>
										<Typography>Device twin</Typography>
										{this.entity.deviceOnline !== undefined && (
											<Tooltip
												title={this.entity.deviceStatusString.split('\n').map((item, key) => {
													return (
														<React.Fragment key={key}>
															<span>{item}</span>
															<br />
														</React.Fragment>
													);
												})}
												placement="right"
											>
												<Typography
													variant="caption"
													className={classNames({
														[classes.badge]: true,
														[classes.button]: true,
														[classes.okBadge]: this.entity.deviceOnline,
														[classes.errorbadge]: !this.entity.deviceOnline,
													})}
												>
													{this.entity.deviceOnline ? 'Online' : 'Offline'}
												</Typography>
											</Tooltip>
										)}
									</div>
									{this.deviceTemplate?.data?.template.device_twin && (
										<div>
											{this.deviceTemplate?.data?.template.device_twin.properties.map(prop => {
												if (prop.readOnly) {
													return (
														<DeviceReadOnlyProperty
															key={prop.name}
															label={prop.label}
															type={prop.type}
															value={this.entity!.getDeviceProperty(prop.name)}
															className={classes.field}
														/>
													);
												} else {
													return (
														<DeviceTwinProperty
															key={prop.name}
															label={prop.label}
															type={prop.type}
															select={prop.select}
															value={this.entity!.getDeviceProperty(prop.name)}
															onChange={v => this.entity!.setDeviceDesiredProperty(prop.name, v)}
															className={classes.field}
														/>
													);
												}
											})}
										</div>
									)}
								</div>
							)}
							{this.selectedTab === TabName.DevicePoint && <DevicePointsList entity={this.entity} />}
							{this.selectedTab === TabName.EntityPoint && <EntityPointsList entity={this.entity} />}
							{this.selectedTab === TabName.Integration && (
								<div className={classes.externalMappings}>
									{this.injected.externalSystemTemplates.templates.map(
										({ id, data }, index) =>
											data && (
												<div key={index}>
													<div className={classes.sectionTitle}>
														<Typography>{data.name}</Typography>
													</div>
													<EntityExternalSystemMapping
														entity={this.entity!}
														templateId={id}
														systemName={data.name}
														template={data.template}
													/>
												</div>
											),
									)}
								</div>
							)}
						</>
					)}
				</div>
			</div>
		);
	}

	@action.bound
	private closeMenu(e: React.MouseEvent<EventTarget>) {
		if (!this.moreMenuAnchorEl || this.moreMenuAnchorEl.contains(e.target as HTMLElement)) {
			return;
		}

		this.moreMenuAnchorEl = null;
	}

	@action.bound
	private toggleMenu(e: React.MouseEvent<HTMLElement>) {
		this.moreMenuAnchorEl = this.moreMenuAnchorEl === null ? e.currentTarget : null;
	}

	@action.bound
	private discardChanges() {
		if (!this.entity) {
			return;
		}

		this.entity.discardChanges();
		this.moreMenuAnchorEl = null;
	}

	@action.bound
	private deleteEntity() {
		if (!this.entity) {
			return;
		}

		this.entity.delete();
		this.moreMenuAnchorEl = null;
	}

	@action.bound
	private save() {
		if (!this.entity) {
			return;
		}

		this.entity.save();
		this.moreMenuAnchorEl = null;
	}

	@action.bound
	private updateTags(tags: string[]) {
		if (!this.entity || !this.entity.data) {
			return;
		}

		this.entity.data.tags = tags;
	}

	@action.bound
	private updateEntityTemplate(event: React.ChangeEvent<HTMLInputElement>) {
		if (!this.entity) {
			return;
		}

		this.entity.setEntityTemplate(event.target.value);
	}

	@action.bound
	private updateDeviceTemplate(event: React.ChangeEvent<HTMLInputElement>) {
		if (!this.entity) {
			return;
		}

		this.entity.setDeviceTemplate(event.target.value);
	}
}

export default withStyles(styles)(EntityView);

import * as React from 'react';
import { IExternalSystemTemplate } from '@mitie/metadata-api-types';

import Entity from 'store/entity';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { FormControlLabel, Switch, TextField, Typography } from '@material-ui/core';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
	createStyles({
		field: {
			marginRight: '1rem',
			minWidth: '25rem',
		},
		enableSwitch: {
			marginTop: '16px',
			marginRight: '1rem',
			marginBottom: '8px',
			marginLeft: 0,
			height: '56px',
		},
		featuresContainer: {
			marginTop: '0.5rem',
		},
	});

interface IEntityExternalSystemMappingProps extends WithStyles<typeof styles> {
	entity: Entity;
	templateId: string;
	systemName: string;
	template: IExternalSystemTemplate;
}

@observer
class EntityExternalSystemMapping extends React.Component<IEntityExternalSystemMappingProps> {
	@computed
	private get enabled() {
		const { entity, templateId } = this.props;

		if (!entity.data) {
			return undefined;
		}

		return entity.data.externalMappings[templateId] !== undefined;
	}

	private set enabled(checked: boolean | undefined) {
		if (checked === undefined) {
			return;
		}

		const { entity, templateId } = this.props;

		if (!entity.data) {
			return;
		}

		if (checked) {
			entity.data.externalMappings[templateId] = {};
		} else {
			delete entity.data.externalMappings[templateId];
		}
	}

	public render() {
		const { entity, template, templateId, systemName, classes } = this.props;

		if (!entity.data) {
			return;
		}

		return (
			<>
				<div className={classes.featuresContainer}>
					<Typography variant="subtitle1">{`Integration levels for ${systemName}:`}</Typography>
					<ul>
						{template.features.map(feature => {
							switch (feature) {
								case 'manual':
									return (
										<li key={feature}>
											No automated synchronisation. The corresponding entity in the external system needs to be created
											manually and the corresponding IDs to be added manually below
										</li>
									);
								case 'slave':
									return (
										<li key={feature}>
											One-way automated synchronisation. Entity in the external system is created and updated based on
											the entity data.
										</li>
									);
								case 'telemetry':
									return (
										<li key={feature}>
											The telemetry will be forwarded automatically to the external system if the entity is a device and
											the corresponding entity exists in the external system.
										</li>
									);
								default:
									return null;
							}
						})}
					</ul>
				</div>

				<FormControlLabel
					control={
						<Switch checked={this.enabled} color="primary" onChange={(ev, checked) => (this.enabled = checked)} />
					}
					label="Enabled"
					className={classes.enableSwitch}
				/>

				{template.properties.map(({ label, name, type }) => {
					return (
						<TextField
							key={name}
							label={label}
							value={entity.getExternalMapping(templateId, name) || ''}
							onChange={e => entity.setExternalMapping(templateId, name, e.target.value)}
							margin="normal"
							variant="outlined"
							type={type}
							className={classes.field}
							disabled={!this.enabled}
						/>
					);
				})}
			</>
		);
	}
}

export default withStyles(styles)(EntityExternalSystemMapping);

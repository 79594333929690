import * as React from 'react';
import { Fragment, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import {
	Typography,
	ListItem,
	List,
	ListItemText,
	Avatar,
	LinearProgress,
	IconButton,
	Button,
	Tooltip,
	Divider,
} from '@material-ui/core';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import {
	ListAlt as ListAltIcon,
	Error as ErrorIcon,
	CloudUpload as CloudUploadIcon,
	RemoveCircleOutline as RemoveCircleIcon,
	Remove as RemoveIcon,
	Add as AddIcon,
	ArrowBack as ArrowBackIcon,
} from '@material-ui/icons';
import classNames from 'classnames';
import { FixedSizeList, VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { IParsedCsvFile } from '@mitie/metadata-api-types';

import { Status } from 'DataTypes';

const styles = (theme: Theme) =>
	createStyles({
		'@keyframes progress': {
			'0%': {
				backgroundPosition: '0 0',
			},
			'100%': {
				backgroundPosition: '-70px 0',
			},
		},
		dropzone: {
			minWidth: '40vw',
			height: 'calc(80vh - 96px)',
			overflowY: 'auto',
			overflowX: 'hidden',
			display: 'flex',
			flexDirection: 'column',
			textAlign: 'center',
			border: '2px solid transparent',
			flexGrow: 1,
		},
		uploadIcon: {
			width: 48,
			height: 48,
			color: theme.palette.grey[500],
		},
		dropzoneText: {
			color: theme.palette.grey[500],
		},
		stripes: {
			border: `2px solid ${theme.palette.grey[300]}`,
			backgroundImage: `repeating-linear-gradient(-45deg, ${theme.palette.grey[100]}, ${theme.palette.grey[100]} 25px, ${theme.palette.grey[300]} 25px, ${theme.palette.grey[300]} 50px)`,
			animation: 'progress 2s linear infinite !important',
			backgroundSize: '150% 100%',
		},
		grow: {
			flexGrow: 1,
		},
		removeIcon: {
			padding: '8px',
			width: '40px',
			height: '40px',
		},
		itemText: {},
		buttonContainer: {
			display: 'flex',
			margin: '1rem 0',
		},
		iconButton: {
			marginLeft: '0.5rem',
		},
		button: {
			margin: '0 0.5rem',
		},
		previewContainer: {
			flex: 1,
			display: 'flex',
			width: '200%',
			transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
		},
		listContainer: {
			padding: 0,
			height: '100%',
			overflowY: 'auto',
			width: '50%',
		},
		selectableListItem: {
			cursor: 'pointer',
			'&:hover': {
				backgroundColor: theme.palette.grey[200],
			},
		},
		detailsContainer: {
			textAlign: 'left',
			display: 'flex',
			flexDirection: 'column',
			width: '50%',
		},
		detailsContent: {
			overflowY: 'auto',
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
		},
		backButton: {
			width: '3rem',
			zIndex: 1,
		},
		detailsTopBar: {
			display: 'flex',
		},
		detailsTitle: {
			lineHeight: '3rem',
			flex: 1,
			textAlign: 'center',
			position: 'relative',
			right: '1.5rem',
		},
		listItem: {
			whiteSpace: 'nowrap',
			padding: '10px 0',
			borderBottom: `1px solid ${theme.palette.grey[300]}`,
			boxSizing: 'border-box',
		},
	});

interface IUploaderProps extends WithStyles<typeof styles> {
	files: Array<{ name: string; status: Status; content?: IParsedCsvFile; statusText?: string; error?: string }>;
	expandedFile: { name: string; content: IParsedCsvFile } | null;
	addFiles: (files: File[]) => void;
	removeFile: (index: number) => void;
	removeAllFiles: () => void;
	toggleDetailsPanel: (index: number | null) => void;
}

const Uploader: React.SFC<IUploaderProps> = props => {
	const { classes, files, expandedFile, addFiles, removeFile, removeAllFiles, toggleDetailsPanel } = props;
	const onDrop = useCallback(addFiles, []);
	const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
		onDrop,
		noClick: true,
		noKeyboard: true,
	});

	return (
		<div className={classNames({ [classes.dropzone]: true, [classes.stripes]: isDragActive })} {...getRootProps()}>
			<input {...getInputProps()} />
			<div className={classes.buttonContainer}>
				<div className={classes.grow} />
				<Button variant="contained" color="primary" onClick={open} className={classes.button}>
					Add file...
					<AddIcon className={classes.iconButton} />
				</Button>
				<Button
					variant="contained"
					color="default"
					onClick={removeAllFiles}
					className={classes.button}
					disabled={files.length === 0}
				>
					Remove all
					<RemoveIcon className={classes.iconButton} />
				</Button>
				<div className={classes.grow} />
			</div>
			{isDragActive || files.length === 0 ? (
				<Fragment>
					<div className={classes.grow} />
					<div>
						<Typography variant="subtitle1" className={classes.dropzoneText}>
							{!isDragActive ? 'Drag and drop files here' : 'Drop here...'}
						</Typography>
						<CloudUploadIcon className={classes.uploadIcon} />
					</div>
					<div className={classes.grow} />
				</Fragment>
			) : (
				<div
					className={classes.previewContainer}
					style={expandedFile !== null ? { transform: 'translateX(-50%)' } : {}}
				>
					<List className={classes.listContainer}>
						{files.map((file, i) => (
							<ListItem
								key={i}
								onClick={!file.error && file.content ? () => toggleDetailsPanel(i) : undefined}
								className={classNames({ [classes.selectableListItem]: !file.error && file.content })}
							>
								<Avatar>{file.status === Status.Error ? <ErrorIcon color="error" /> : <ListAltIcon />}</Avatar>
								<ListItemText className={classes.itemText}>
									{file.status === Status.Loading && <LinearProgress />}
									<Typography variant="subtitle2">{file.name}</Typography>
									{file.error ? (
										<Typography variant="caption" color="error">
											{file.error}
										</Typography>
									) : file.statusText ? (
										<Typography variant="caption">{file.statusText}</Typography>
									) : (
										file.content && (
											<Fragment>
												<Typography variant="caption">{`Content: ${file.content.fileType}. `}</Typography>
												{file.content.data && (
													<Typography variant="caption">{`${
														file.content.data.new ? `${file.content.data.new.length} new entities, ` : ''
													}${
														file.content.data.changed ? `${file.content.data.changed.length} modified entities` : ''
													}`}</Typography>
												)}
											</Fragment>
										)
									)}
								</ListItemText>
								<Tooltip title="Remove file">
									<IconButton
										className={classes.removeIcon}
										onClick={e => {
											e.stopPropagation();
											removeFile(i);
										}}
									>
										<RemoveCircleIcon />
									</IconButton>
								</Tooltip>
							</ListItem>
						))}
					</List>
					{expandedFile !== null && (
						<div className={classes.detailsContainer}>
							<div className={classes.detailsTopBar}>
								<Tooltip title="Back to list">
									<IconButton onClick={() => toggleDetailsPanel(null)} className={classes.backButton}>
										<ArrowBackIcon />
									</IconButton>
								</Tooltip>
								<Typography
									variant="subtitle2"
									className={classes.detailsTitle}
								>{`Content preview for file '${expandedFile.name}'`}</Typography>
							</div>
							<div className={classes.detailsContent}>
								<Typography variant="subtitle1">{`${
									expandedFile.content.errors ? expandedFile.content.errors.length : 0
								} errors`}</Typography>
								<div style={{ flex: 1 }}>
									{expandedFile.content.errors ? (
										<AutoSizer>
											{({ height, width }) => (
												<FixedSizeList
													height={height}
													itemCount={expandedFile.content.errors ? expandedFile.content.errors.length : 0}
													itemSize={41}
													width={width}
												>
													{({ index, style }) => (
														<div className={classes.listItem} style={style}>
															{expandedFile.content.errors ? expandedFile.content.errors[index] : ''}
														</div>
													)}
												</FixedSizeList>
											)}
										</AutoSizer>
									) : (
										<Typography variant="body1">No errors</Typography>
									)}
								</div>
								<Divider />
								<Typography variant="subtitle1">{`${
									expandedFile.content.warnings ? expandedFile.content.warnings.length : 0
								} warnings`}</Typography>
								<div style={{ flex: 1 }}>
									{expandedFile.content.warnings ? (
										<AutoSizer>
											{({ height, width }) => (
												<VariableSizeList
													height={height}
													itemCount={expandedFile.content.warnings ? expandedFile.content.warnings.length : 0}
													itemSize={i =>
														expandedFile.content.warnings
															? expandedFile.content.warnings[i].split('\n').length * 20 + 21
															: 21
													}
													width={width}
												>
													{({ index, style }) => (
														<div className={classes.listItem} style={style}>
															{expandedFile.content.warnings ? formatText(expandedFile.content.warnings[index]) : ''}
														</div>
													)}
												</VariableSizeList>
											)}
										</AutoSizer>
									) : (
										<Typography variant="body1">No warnings</Typography>
									)}
								</div>
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

function formatText(text: string) {
	return text.split('\n').map((item, key) => {
		return (
			<React.Fragment key={key}>
				<span style={key === 0 ? { fontWeight: 'bold' } : {}}>{item}</span>
				<br />
			</React.Fragment>
		);
	});
}

export default withStyles(styles)(Uploader);

import * as React from 'react';
import { MobXProviderContext } from 'mobx-react';

import Routes from './routes';
import User from './user';
import Entities from './entities';
import Notifications from './notifications';
import Globals from './globals';
import EntityTemplates from './entityTemplates';
import DeviceTemplates from './deviceTemplates';
import ExternalSystemTemplates from './externalSystemTemplates';
import DevicePointTemplates from './devicePointTemplates';
import EntityPointTemplates from './entityPointTemplates';
export { default as Routes } from './routes';
export { default as Entities } from './entities';

export class Root {
	public notifications = new Notifications();
	public routes = new Routes(this);
	public user = new User();
	public entities = new Entities(this);
	public entityTemplates = new EntityTemplates(this);
	public deviceTemplates = new DeviceTemplates(this);
	public externalSystemTemplates = new ExternalSystemTemplates(this);
	public devicePointTemplates = new DevicePointTemplates(this);
	public entityPointTemplates = new EntityPointTemplates(this);
	public globals = new Globals();

	constructor() {
		Object.keys(this).forEach(storeName => this[storeName].init && this[storeName].init());
	}
}

const stores = new Root();

export const useStores = () => {
	return React.useContext<Root>(MobXProviderContext as any);
};

(window as any).stores = stores;

export default stores;

import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

interface IConfirmDialogProps {
	title: string;
	message: string;
	confirmLabel: string;
	cancelLabel: string;
	onClose: () => void;
	onConfirm: () => void;
}

export default class ConfirmDialog extends React.Component<IConfirmDialogProps> {
	public render() {
		const { title, message, confirmLabel, cancelLabel, onClose, onConfirm } = this.props;
		return (
			<Dialog open={true} onClose={onClose}>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent>
					<DialogContentText>{message}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose} color="primary">
						{cancelLabel}
					</Button>
					<Button
						onClick={() => {
							onConfirm();
							onClose();
						}}
						color="primary"
						autoFocus={true}
					>
						{confirmLabel}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IconButton, Typography, MenuItem, Divider, Tooltip, Menu, ListItemIcon, Select } from '@material-ui/core';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { MoreVert as MoreVertIcon, Undo as UndoIcon, Add as AddIcon } from '@material-ui/icons';
import { ContentSaveAll, FileImport } from 'mdi-material-ui';
import { observable, action, IReactionDisposer, autorun, computed } from 'mobx';
import { IApiEntityUpdate } from '@mitie/metadata-api-types';

import Entities from '../store/entities';
import Routes, { Hierarchy } from '../store/routes';
import ImportDialog from './ImportDialog';
import ConfirmDialog from './ConfirmDialog';

const styles = (theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			borderRight: '1px solid rgba(0, 0, 0, 0.12)',
			minWidth: '20vw',
			maxWidth: '25vw',
			height: '100%',
		},
		unsavedCount: {
			height: '1rem',
			lineHeight: '1rem',
			padding: '0.125rem 0.5rem',
			display: 'inline',
			background: theme.palette.secondary.main,
			borderRadius: '0.75rem',
			color: theme.palette.secondary.contrastText,
			marginTop: '13px',
			border: `1px solid ${theme.palette.secondary.dark}`,
		},
		titleText: {
			lineHeight: '48px',
			margin: '0 0.5rem',
		},
		topBar: {
			display: 'flex',
		},
		grow: {
			flexGrow: 1,
		},
		moreMenu: {
			zIndex: 2,
		},
		browseByLabel: {
			marginTop: '7px',
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
		},
		browseBySelect: {
			...theme.typography.h6,
			lineHeight: 'default',
		},
	});

interface IEntitiesNavigatorProps extends WithStyles<typeof styles> {
	treeComponent: React.ReactElement;
	createRootEntityLabel: string;
	createRootEntity: () => IApiEntityUpdate | undefined;
	createChildEntity: () => IApiEntityUpdate | undefined;
}

@inject('entities', 'routes')
@observer
class EntitiesNavigator extends React.Component<IEntitiesNavigatorProps> {
	private disposables: IReactionDisposer[] = [];
	@observable private moreMenuAnchorEl: HTMLElement | null = null;
	@observable private importDialogOpen = false;
	@observable private expandedChildren: string[] = [];
	@observable private showSaveConfirmDialog = false;
	@observable private showDiscardConfirmDialog = false;

	private get injected() {
		return (this.props as any) as {
			routes: Routes;
			entities: Entities;
		};
	}

	@computed
	private get selectedEntityName() {
		const { entity } = this.injected.routes;

		if (!entity || !entity.data) {
			return '';
		}

		return entity.data.name;
	}

	@computed
	private get entitySelected() {
		const { entity } = this.injected.routes;

		if (!entity || !entity.data) {
			return false;
		}

		return true;
	}

	public componentDidMount() {
		this.disposables.push(
			autorun(() => {
				// Makes sure that the parents of the selected entity are always expanded
				const {
					entities,
					routes: { parentEntityIds },
				} = this.injected;

				if (parentEntityIds && parentEntityIds.length > 0) {
					for (const client of entities.clients) {
						if (parentEntityIds.includes(client.id) && !this.expandedChildren.includes(client.id)) {
							this.expandedChildren.push(client.id);
						}
					}
				}
			}),
		);
	}

	public componentWillUnmount() {
		this.disposables.forEach(d => d());
	}

	public render() {
		const { classes, treeComponent, createRootEntityLabel } = this.props;
		const { entities, routes } = this.injected;

		return (
			<div className={classes.container}>
				<div className={classes.topBar}>
					<div style={{ display: 'flex' }}>
						<Typography variant="h6" className={classes.browseByLabel}>
							Browse by:
						</Typography>
						<Select
							native={true}
							value={routes.routeParams.browseBy}
							onChange={e => routes.setFilters({ browseBy: e.target.value as Hierarchy })}
							className={classes.browseBySelect}
						>
							<option value="location">Location</option>
							<option value="devices">Devices</option>
							<option value="assets">Assets</option>
						</Select>
					</div>

					{entities.entitiesUnsavedCount > 0 && (
						<Typography
							variant="caption"
							className={classes.unsavedCount}
						>{`${entities.entitiesUnsavedCount} unsaved`}</Typography>
					)}
					<div className={classes.grow} />
					{entities.entitiesUnsavedCount > 0 && (
						<Tooltip title="Save all" placement="bottom">
							<IconButton color="secondary" onClick={this.promptSaveAll}>
								<ContentSaveAll />
							</IconButton>
						</Tooltip>
					)}
					<IconButton onClick={this.toggleMenu}>
						<MoreVertIcon />
					</IconButton>
					<Menu
						anchorEl={this.moreMenuAnchorEl}
						keepMounted={true}
						open={Boolean(this.moreMenuAnchorEl)}
						onClose={this.closeMenu}
						elevation={0}
						getContentAnchorEl={null}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
					>
						<MenuItem onClick={this.addRootEntity}>
							<ListItemIcon>
								<AddIcon />
							</ListItemIcon>
							<Typography variant="inherit">{createRootEntityLabel}</Typography>
						</MenuItem>
						{this.entitySelected && (
							<MenuItem onClick={this.addChildEntity}>
								<ListItemIcon>
									<AddIcon />
								</ListItemIcon>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<Typography variant="inherit">Add child entity</Typography>
									<Typography variant="body2" color="textSecondary">{`Parent: ${this.selectedEntityName}`}</Typography>
								</div>
							</MenuItem>
						)}
						<MenuItem onClick={this.showImportDialog}>
							<ListItemIcon>
								<FileImport />
							</ListItemIcon>
							<Typography variant="inherit">Bulk import...</Typography>
						</MenuItem>
						<Divider />
						<MenuItem disabled={entities.entitiesUnsavedCount === 0} onClick={this.promptSaveAll}>
							<ListItemIcon>
								<ContentSaveAll />
							</ListItemIcon>
							<Typography variant="inherit">Save all changes</Typography>
						</MenuItem>
						<MenuItem disabled={entities.entitiesUnsavedCount === 0} onClick={this.promptDiscardAll}>
							<ListItemIcon>
								<UndoIcon />
							</ListItemIcon>
							<Typography variant="inherit">Discard all changes</Typography>
						</MenuItem>
					</Menu>
				</div>
				<Divider />
				{treeComponent}
				{this.importDialogOpen && <ImportDialog onClose={this.hideImportDialog} />}
				{this.showSaveConfirmDialog && (
					<ConfirmDialog
						title="Save all changes?"
						message={`${entities.entitiesUnsavedCount} ${
							entities.entitiesUnsavedCount > 1 ? 'entities' : 'entity'
						} will be created, modified or deleted.`}
						cancelLabel="Cancel"
						confirmLabel="Save all"
						onClose={this.cancelSaveAll}
						onConfirm={this.saveAll}
					/>
				)}
				{this.showDiscardConfirmDialog && (
					<ConfirmDialog
						title="Discard all changes?"
						message={`${entities.entitiesUnsavedCount} ${
							entities.entitiesUnsavedCount > 1 ? 'changes' : 'change'
						} will be discarded.`}
						cancelLabel="Cancel"
						confirmLabel="Discard all"
						onClose={this.cancelDiscardAll}
						onConfirm={this.discardAll}
					/>
				)}
			</div>
		);
	}

	@action.bound
	private closeMenu() {
		this.moreMenuAnchorEl = null;
	}

	@action.bound
	private toggleMenu(e: React.MouseEvent<HTMLElement>) {
		this.moreMenuAnchorEl = this.moreMenuAnchorEl === null ? e.currentTarget : null;
	}

	@action.bound
	private promptDiscardAll() {
		this.showDiscardConfirmDialog = true;
	}

	@action.bound
	private cancelDiscardAll() {
		this.showDiscardConfirmDialog = false;
	}

	@action.bound
	private discardAll() {
		this.injected.entities.discardAll();

		this.moreMenuAnchorEl = null;
	}

	@action.bound
	private promptSaveAll() {
		this.showSaveConfirmDialog = true;
	}

	@action.bound
	private cancelSaveAll() {
		this.showSaveConfirmDialog = false;
	}

	@action.bound
	private saveAll() {
		this.injected.entities.saveAll();
	}

	@action.bound
	private showImportDialog() {
		this.closeMenu();
		this.importDialogOpen = true;
	}

	@action.bound
	private hideImportDialog() {
		this.importDialogOpen = false;
	}

	@action.bound
	private addRootEntity() {
		this.closeMenu();

		const { routes, entities } = this.injected;
		const newEntity = this.props.createRootEntity();

		if (!newEntity) {
			return;
		}

		entities.addEntitiesFromUi([newEntity]);
		routes.setFilters({ entityId: newEntity.id });
	}

	@action.bound
	private addChildEntity() {
		this.closeMenu();

		const { entities, routes } = this.injected;

		const newEntity = this.props.createChildEntity();

		if (!newEntity) {
			return;
		}

		entities.addEntitiesFromUi([newEntity]);
		routes.setFilters({ entityId: newEntity.id });
	}
}

export default withStyles(styles)(EntitiesNavigator);

import * as React from 'react';
import { TextField, InputAdornment, Tooltip, IconButton } from '@material-ui/core';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import classNames from 'classnames';
import { IDeviceProperty } from '@mitie/metadata-api-types';
import { Done, HelpOutline } from '@material-ui/icons';
import { formatRelative } from 'date-fns';

const styles = (theme: Theme) =>
	createStyles({
		green: {
			color: '#009900',
		},
	});

interface IDevicePropertyProps extends WithStyles<typeof styles> {
	value?: IDeviceProperty<string | number | boolean>;
	label: string;
	type: 'string' | 'number' | 'boolean';
	className: string;
}

@observer
class DeviceReadOnlyProperty extends React.Component<IDevicePropertyProps> {
	@computed
	private get reportedTooltip() {
		const { value } = this.props;

		if (!value) {
			return 'No reported value';
		}

		return value.reported !== undefined
			? `Reported time: ${value.lastReported ? formatRelative(new Date(value.lastReported), new Date()) : 'unknown'}`
			: 'No reported value';
	}

	@computed
	private get reportedValue() {
		const { value } = this.props;

		if (value === undefined) {
			return '';
		}

		return value.reported;
	}

	public render() {
		const { label, type, className, classes } = this.props;

		return (
			<TextField
				value={this.reportedValue}
				label={label}
				type={type}
				disabled={true}
				margin="normal"
				variant="outlined"
				className={classNames([className])}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<Tooltip title={this.reportedTooltip}>
								<IconButton>{this.reportedValue ? <Done className={classes.green} /> : <HelpOutline />}</IconButton>
							</Tooltip>
						</InputAdornment>
					),
				}}
			/>
		);
	}
}

export default withStyles(styles)(DeviceReadOnlyProperty);

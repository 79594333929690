import * as React from 'react';
import { v4 as uuid } from 'uuid';

import EntitiesNavigator from '../components/EntitiesNavigator';
import EntityView from '../components/EntityView';
import LocationTree from 'components/LocationTree';
import { IApiEntityUpdate } from '@mitie/metadata-api-types';
import { useStores } from 'store';
import DeviceTree from 'components/DeviceTree';
import AssetTree from 'components/AssetTree';

interface IEntitiesPageProps {
	className?: string;
}

export default function EntitiesPage({ className }: IEntitiesPageProps) {
	const { routes } = useStores();

	return (
		<div className={className} style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
			<div style={{ flex: 1, display: 'flex', height: '100%' }}>
				<div>
					{routes.routeParams.browseBy === 'location' && (
						<EntitiesNavigator
							treeComponent={<LocationTree />}
							createRootEntityLabel="New client"
							createRootEntity={() => {
								const entityId = uuid();
								const newEntity: IApiEntityUpdate = {
									id: entityId,
									name: 'New client',
									properties: {},
									tags: ['client'],
									relations: {},
									external_mappings: {},
									lifecycle_status: 'Configuration',
								};

								return newEntity;
							}}
							createChildEntity={() => {
								if (!routes.entity || !routes.entity.data) {
									return;
								}

								const entityId = uuid();
								const { client } = routes.entity.data.relations;
								const location = routes.entity.id;

								const newEntity: IApiEntityUpdate = {
									id: entityId,
									name: 'New entity',
									properties: {},
									tags: [],
									relations: {
										location,
										client,
									},
									external_mappings: {},
									lifecycle_status: 'Configuration',
								};

								return newEntity;
							}}
						/>
					)}
					{routes.routeParams.browseBy === 'devices' && (
						<EntitiesNavigator
							treeComponent={<DeviceTree />}
							createRootEntityLabel="Add device"
							createRootEntity={() => {
								const entityId = uuid();
								const newEntity: IApiEntityUpdate = {
									id: entityId,
									name: 'New device',
									properties: {},
									tags: ['device'],
									relations: {},
									external_mappings: {},
									lifecycle_status: 'Configuration',
								};

								return newEntity;
							}}
							createChildEntity={() => {
								if (!routes.entity || !routes.entity.data) {
									return;
								}

								const entityId = uuid();
								const { location, client } = routes.entity.data.relations;
								const newEntity: IApiEntityUpdate = {
									id: entityId,
									name: 'New device',
									properties: {},
									tags: ['device'],
									relations: {
										gateway: routes.entity.id,
										location,
										client,
									},
									external_mappings: {},
									lifecycle_status: 'Configuration',
								};

								return newEntity;
							}}
						/>
					)}
					{routes.routeParams.browseBy === 'assets' && (
						<EntitiesNavigator
							treeComponent={<AssetTree />}
							createRootEntityLabel="Add asset"
							createRootEntity={() => {
								const entityId = uuid();
								const newEntity: IApiEntityUpdate = {
									id: entityId,
									name: 'New asset',
									properties: {},
									tags: ['equip', 'asset'],
									relations: {},
									external_mappings: {},
									lifecycle_status: 'Configuration',
								};

								return newEntity;
							}}
							createChildEntity={() => {
								if (!routes.entity || !routes.entity.data) {
									return;
								}

								const entityId = uuid();
								const { location, client } = routes.entity.data.relations;
								const newEntity: IApiEntityUpdate = {
									id: entityId,
									name: 'New asset',
									properties: {},
									tags: ['equip', 'asset'],
									relations: {
										equip: routes.entity.id,
										location,
										client,
									},
									external_mappings: {},
									lifecycle_status: 'Configuration',
								};

								return newEntity;
							}}
						/>
					)}
				</div>
				<div style={{ overflow: 'auto', flex: 1 }}>
					<EntityView />
				</div>
			</div>
		</div>
	);
}

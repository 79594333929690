import { observable, action, computed } from 'mobx';
import {
	IDevicePointTemplate,
	RelationType,
	IEntityTemplate,
	IEntityPointTemplate,
	IApiTemplateUpdate,
} from '@mitie/metadata-api-types';
import { v4 } from 'uuid';

import { Template } from './template';
import { Root } from 'store';
import { DevicePointTemplate } from './devicePointTemplate';
import Templates from './templates';

export default class DevicePointTemplates extends Templates<IDevicePointTemplate> {
	@observable private list: { [id: string]: DevicePointTemplate } = {};

	constructor(rootStore: Root) {
		super(rootStore, 'device_point');
	}

	public getTemplate(id: string): DevicePointTemplate | undefined {
		return this.list[id];
	}

	@action.bound
	public addAndGetTemplate(templateId: string): DevicePointTemplate {
		const template = this.getTemplate(templateId);

		if (template) {
			return template;
		}

		const newTemplate = new DevicePointTemplate(templateId);
		this.list[templateId] = newTemplate;

		return newTemplate;
	}

	@computed
	public get templates() {
		return Object.keys(this.list).map(id => this.list[id]);
	}

	public createTemplate(
		name: string,
		metricName: string,
		datatype: 'number' | 'boolean',
		tags: string[],
		units?: string,
		defaultBinding?: {
			entityRelation: RelationType;
			entityTemplate: Template<IEntityTemplate>;
			entityPointTemplate: Template<IEntityPointTemplate>;
		},
	) {
		const id = v4();
		const newTemplate = this.addAndGetTemplate(id);

		const data: IApiTemplateUpdate<IDevicePointTemplate> = {
			id,
			name,
			template_type: 'device_point',
			template: {
				properties: { metric_name: metricName, datatype, units },
				tags,
			},
		};

		if (defaultBinding) {
			data.template.default_binding = {
				entity_relation: defaultBinding.entityRelation,
				entity_template: defaultBinding.entityTemplate.id,
				entity_point_template: defaultBinding.entityPointTemplate.id,
			};
		}

		newTemplate.setDataFromUi(data);

		return newTemplate;
	}

	public deleteTemplate(template: DevicePointTemplate) {
		delete this.list[template.id];
	}
}

import React, { useState } from 'react';
import { Chip, IconButton, Input, FormControl } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			margin: theme.spacing(2),
			overflow: 'auto',
		},
		selectChip: {
			marginRight: theme.spacing(1),
			marginBottom: theme.spacing(1) / 2,
		},
		propertyValue: {
			display: 'inline',
		},
		tabs: {
			borderBottom: `1px solid ${theme.palette.divider}`,
		},
		tab: {
			'&>span:first-child': {
				flexDirection: 'row',
			},
		},
		field: {
			marginRight: '1rem',
			minWidth: '25rem',
		},
	}),
);

interface IDropdownOptionsInputProps {
	value:
		| Array<{
				value: number | string;
				label: string;
		  }>
		| undefined;
	type: 'string' | 'number' | 'boolean';
	onChange: (
		newOptions:
			| Array<{
					value: number | string;
					label: string;
			  }>
			| undefined,
	) => void;
}

export default function DropdownOptionsInput({ value, type, onChange }: IDropdownOptionsInputProps) {
	const classes = useStyles();

	const [labelInput, setLabelInput] = useState('');
	const [valueInput, setValueInput] = useState('');

	return (
		<>
			{value?.map(({ label, value: v }, index) => (
				<Chip
					className={classes.selectChip}
					key={v}
					label={`${label} (${v})`}
					onDelete={() => {
						if (value) {
							const newItems = [...value];
							newItems.splice(index, 1);

							onChange(newItems);
						}
					}}
				/>
			))}
			<div style={{ flexDirection: 'row', display: 'inline' }}>
				<FormControl>
					<Input
						placeholder="Label"
						style={{ fontSize: '13px', marginLeft: '8px', marginRight: '8px' }}
						value={labelInput}
						onChange={e => setLabelInput(e.currentTarget.value)}
					/>
				</FormControl>
				<FormControl>
					<Input
						placeholder="Value"
						style={{ fontSize: '13px' }}
						value={valueInput}
						onChange={e => setValueInput(e.currentTarget.value)}
					/>
				</FormControl>
			</div>
			<IconButton
				size="small"
				disabled={!labelInput || !valueInput || (value && value.find(s => s.value === valueInput) !== undefined)}
				onClick={() => {
					const existingItems = value || [];

					const newItem = {
						label: labelInput,
						value: type === 'number' ? parseFloat(valueInput) : valueInput,
					};

					onChange([...existingItems, newItem]);

					setLabelInput('');
					setValueInput('');
				}}
			>
				<Add fontSize="small" />
			</IconButton>
		</>
	);
}

import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable, autorun } from 'mobx';
import { WithStyles, Theme, withStyles } from '@material-ui/core/styles';

import './app.css';
import Routes from './store/routes';
import Entities from './store/entities';
import { initWebsocket } from './services/apiService';
import NotificationStore from './store/notifications';
import NavBar from './components/NavBar';
import HeaderBar from './components/HeaderBar';
import Notifications from './components/Notifications';
import User from 'store/user';
import CustomTheme from './Theme';

const styles = (theme: Theme) => ({
	container: {
		height: 'calc(100% - 64px)',
		marginTop: '64px',
	},
	componentContainer: {
		flex: 1,
		display: 'flex',
		height: '100%',
	},
	snackbarContainer: {},
});

interface IAppProps extends WithStyles<typeof styles> {}

@inject('routes', 'entities', 'user')
@observer
class App extends React.Component<IAppProps> {
	@observable private navBarOpen = false;

	constructor(props: IAppProps) {
		super(props);

		const { entities, routes } = this.injected;
		routes.router.start();

		initWebsocket();

		entities.fetchClients();

		autorun(() => {
			if (entities.entitiesUnsavedCount > 0) {
				window.addEventListener('beforeunload', this.keepOnPage);
			} else {
				window.removeEventListener('beforeunload', this.keepOnPage);
			}
		});
	}
	private get injected() {
		return (this.props as any) as {
			routes: Routes;
			entities: Entities;
			notifications: NotificationStore;
			user: User;
		};
	}

	public renderRoute = () => {
		const { routeParams, routeComponent } = this.injected.routes;
		if (!routeComponent) {
			return null;
		}

		return routeComponent(routeParams);
	};

	public render() {
		const { user } = this.injected;
		const { classes } = this.props;

		if (!user.loggedIn) {
			return null;
		}

		return (
			<CustomTheme>
				<div className={classes.container}>
					<HeaderBar onNavBarToggle={() => (this.navBarOpen = !this.navBarOpen)} />
					<NavBar open={this.navBarOpen} onClose={() => (this.navBarOpen = false)} />
					<Notifications />
					<div className={classes.componentContainer}>{this.renderRoute()}</div>
				</div>
			</CustomTheme>
		);
	}

	private keepOnPage = (event: BeforeUnloadEvent) => {
		event.preventDefault();

		event.returnValue = '';
	};
}

export default withStyles(styles)(App);

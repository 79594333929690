import * as React from 'react';
import { Typography, Badge } from '@material-ui/core';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { ChevronRight, ExpandMore, FolderOutlined } from '@material-ui/icons';
import { observer, inject } from 'mobx-react';
import { observable, computed } from 'mobx';
import classNames from 'classnames';

import Entity from 'store/entity';
import TreeItem from './TreeItem';
import { RelationType } from '@mitie/metadata-api-types';

const styles = (theme: Theme) =>
	createStyles({
		container: {},
		itemContainer: {
			display: 'flex',
			padding: '0.25rem',
		},
		itemName: {
			cursor: 'pointer',
			marginLeft: '0.25rem',
			display: 'flex',
			width: 'calc(100% - 20px)',
			flex: 1,
			boxSizing: 'border-box',
		},
		childrenContainer: {
			paddingLeft: '1rem',
		},
		icon: {},
		leafIcon: {
			marginLeft: '4px',
		},
		offlineIcon: {
			float: 'right',
		},
		label: {
			display: 'inline',
			marginLeft: '0.5rem',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			flex: 1,
			lineHeight: 1.25,
		},
		expandButton: {
			cursor: 'pointer',
			'&:hover': {
				borderRadius: '50%',
				backgroundColor: theme.palette.grey[300],
			},
		},
		selected: {
			color: `${theme.palette.primary.dark} !important`,
			fontWeight: 'bold',
		},
		unsaved: {
			color: theme.palette.secondary.dark,
		},
		deleted: {
			textDecoration: 'line-through',
		},
		unsavedCountBadge: {
			marginRight: '0.5rem',
			marginLeft: '0.25rem',
			'&>span': {
				top: '10px',
			},
		},
		unsavedBadge: {
			marginRight: '0.5rem',
			marginLeft: '0.25rem',
			'&>span': {
				top: '10px',
			},
		},
	});

interface ITreeItemGroupProps extends WithStyles<typeof styles> {
	label: string;
	childrenRelationType: RelationType;
	children: Entity[];
	expanded: boolean;
	onExpand: () => void;
	onCollapse: () => void;
}

@inject('routes')
@observer
class UnstyledTreeItemGroup extends React.Component<ITreeItemGroupProps> {
	@observable private expandedChildren: string[] = [];

	@computed
	private get childSelected() {
		return false;
	}

	@computed
	private get childrenUnsavedCount() {
		const { children } = this.props;

		return children.reduce((total, e) => {
			total += e.childrenUnsavedCount;
			return total;
		}, 0);
	}

	public render() {
		const { label, children, childrenRelationType, classes, expanded, onExpand, onCollapse } = this.props;

		return (
			<div className={classes.container}>
				<div className={classes.itemContainer}>
					{!expanded ? (
						<ChevronRight
							fontSize="small"
							onClick={onExpand}
							className={classNames([classes.expandButton, classes.icon])}
						/>
					) : (
						<ExpandMore
							fontSize="small"
							onClick={!this.childSelected ? onCollapse : undefined}
							className={classNames({ [classes.expandButton]: !this.childSelected, [classes.icon]: true })}
						/>
					)}
					<span className={classes.itemName}>
						<FolderOutlined fontSize="small" className={classes.icon} />
						{this.childrenUnsavedCount > 0 && (
							<Badge badgeContent={this.childrenUnsavedCount} color="secondary" className={classes.unsavedCountBadge}>
								{' '}
							</Badge>
						)}
						<Typography
							variant="subtitle1"
							className={classNames({
								[classes.label]: true,
							})}
						>
							{label}
						</Typography>
					</span>
				</div>
				{expanded && (
					<div className={classes.childrenContainer}>
						{children.map(e => {
							return (
								<TreeItem
									key={e.id}
									childrenRelationType={childrenRelationType}
									entity={e}
									expanded={this.expandedChildren.includes(e.id)}
									onExpand={() => this.expandedChildren.push(e.id)}
									onCollapse={() => (this.expandedChildren = this.expandedChildren.filter(id => id !== e.id))}
								/>
							);
						})}
					</div>
				)}
			</div>
		);
	}
}

const TreeItemGroup = withStyles(styles)(UnstyledTreeItemGroup);

export default TreeItemGroup;

import * as React from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { ChevronRight, ExpandMore, FolderOutlined, NoteOutlined } from '@material-ui/icons';
import { observer, inject } from 'mobx-react';
import { observable, computed, IReactionDisposer, autorun } from 'mobx';
import classNames from 'classnames';

import Entity from 'store/entity';
import { Status } from 'DataTypes';
import Routes from 'store/routes';
import { RelationType } from '@mitie/metadata-api-types';

const styles = (theme: Theme) =>
	createStyles({
		container: {},
		itemContainer: {
			display: 'flex',
			padding: '0.25rem',
		},
		itemName: {
			cursor: 'pointer',
			marginLeft: '0.25rem',
			display: 'flex',
			width: 'calc(100% - 20px)',
			flex: 1,
			boxSizing: 'border-box',
		},
		childrenContainer: {
			paddingLeft: '1rem',
		},
		icon: {},
		leafIcon: {
			marginLeft: '4px',
		},
		offlineIcon: {
			float: 'right',
		},
		label: {
			display: 'inline',
			marginLeft: '0.5rem',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			flex: 1,
			lineHeight: 1.25,
		},
		expandButton: {
			cursor: 'pointer',
			'&:hover': {
				borderRadius: '50%',
				backgroundColor: theme.palette.grey[300],
			},
		},
		selected: {
			color: `${theme.palette.primary.dark} !important`,
			fontWeight: 'bold',
		},
		unsaved: {
			color: theme.palette.secondary.dark,
		},
		deleted: {
			textDecoration: 'line-through',
		},
		unsavedCountBadge: {
			marginRight: '0.5rem',
			marginLeft: '0.25rem',
			'&>span': {
				top: '10px',
			},
		},
		unsavedBadge: {
			marginRight: '0.5rem',
			marginLeft: '0.25rem',
			'&>span': {
				top: '10px',
			},
		},
	});

interface ITreeItemProps extends WithStyles<typeof styles> {
	childrenRelationType: RelationType;
	entity: Entity;
	selected: Entity | null;
	expanded: boolean;
	onExpand: () => void;
	onCollapse: () => void;
	onSelect: (e: Entity) => void;
}

@inject('routes')
@observer
class UnstyledTreeItem extends React.Component<ITreeItemProps> {
	private disposables: IReactionDisposer[] = [];
	@observable private expandedChildren: string[] = [];

	private get injected() {
		return (this.props as any) as {
			routes: Routes;
		};
	}

	public componentDidMount() {
		this.disposables.push(
			autorun(() => {
				// Makes sure that the parents of the selected entity are always expanded
				const { parentEntityIds, parentDeviceIds } = this.injected.routes;
				const { childrenRelationType } = this.props;
				const parents = childrenRelationType === 'location' ? parentEntityIds : parentDeviceIds;

				if (parents && parents.length > 0) {
					for (const child of this.children) {
						if (parents.includes(child.id) && !this.expandedChildren.includes(child.id)) {
							this.expandedChildren.push(child.id);
						}
					}
				}
			}),
			autorun(() => {
				// Fetch children if necessary
				const { childrenRelationType, expanded, entity } = this.props;

				if (expanded && !entity.created && entity.isLocation) {
					if (this.childrenRequest === Status.None) {
						entity.fetchChildren(childrenRelationType);
					}
				}
			}),
		);
	}

	public componentWillUnmount() {
		this.disposables.forEach(d => d());
	}

	@computed
	private get loadingStatus() {
		const { childrenRelationType, entity } = this.props;

		return entity.childrenRequest[childrenRelationType];
	}

	@computed
	private get childSelected() {
		const { parentEntityIds, parentDeviceIds } = this.injected.routes;
		const { childrenRelationType, entity } = this.props;
		const parentEntities = childrenRelationType === 'gateway' ? parentDeviceIds : parentEntityIds;

		return parentEntities && parentEntities.includes(entity.id);
	}

	@computed
	private get children() {
		const { entity, childrenRelationType } = this.props;

		return entity.children[childrenRelationType] || [];
	}

	@computed
	private get childrenRequest() {
		const { entity, childrenRelationType } = this.props;

		return entity.childrenRequest[childrenRelationType] || Status.None;
	}

	public render() {
		const { entity, selected, childrenRelationType, classes, expanded, onExpand, onCollapse, onSelect } = this.props;
		const isLeaf = !entity.isLocation;
		const isSelected = selected?.id === entity.id;

		return (
			<div className={classes.container}>
				<div className={classes.itemContainer}>
					{!isLeaf &&
						(!expanded ? (
							<ChevronRight
								fontSize="small"
								onClick={onExpand}
								className={classNames([classes.expandButton, classes.icon])}
							/>
						) : (
							<ExpandMore
								fontSize="small"
								onClick={!this.childSelected ? onCollapse : undefined}
								className={classNames({ [classes.expandButton]: !this.childSelected, [classes.icon]: true })}
							/>
						))}
					<span onClick={() => onSelect(entity)} className={classes.itemName}>
						{this.loadingStatus === Status.Loading && <CircularProgress size={16} />}
						{isLeaf ? (
							<NoteOutlined fontSize="small" className={classNames([classes.icon, classes.leafIcon])} />
						) : (
							<FolderOutlined fontSize="small" className={classes.icon} />
						)}
						<Typography
							variant="subtitle1"
							className={classNames({
								[classes.label]: true,
								[classes.selected]: isSelected,
							})}
						>
							{entity.displayName}
						</Typography>
					</span>
				</div>
				{expanded && (
					<div className={classes.childrenContainer}>
						{this.children.map(e => {
							return (
								<SelectableTreeItem
									key={e.id}
									childrenRelationType={childrenRelationType}
									entity={e}
									selected={selected}
									expanded={this.expandedChildren.includes(e.id)}
									onExpand={() => this.expandedChildren.push(e.id)}
									onCollapse={() => (this.expandedChildren = this.expandedChildren.filter(id => id !== e.id))}
									onSelect={newSelected => onSelect(newSelected)}
								/>
							);
						})}
					</div>
				)}
			</div>
		);
	}
}

const SelectableTreeItem = withStyles(styles)(UnstyledTreeItem);

export default SelectableTreeItem;

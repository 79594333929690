import React, { useState, useEffect } from 'react';
import {
	List,
	ListItem,
	ListItemText,
	LinearProgress,
	Typography,
	Divider,
	TableContainer,
	Table,
	TableBody,
	TableHead,
	TableCell,
	TableRow,
	Paper,
	Button,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Check, Error as ErrorIcon } from '@material-ui/icons';
import { ITaskStatus, ITaskDefinition } from '@mitie/telemetry-api-types';

import * as ApiService from '../services/apiService';
import { handleError } from 'errors';
import { Status } from 'DataTypes';
import CreateTaskDialog from '../components/CreateTaskDialog';
import useRecursiveTimeout from 'useRecursiveTimeout';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexGrow: 1,
		},
		listContainer: {
			display: 'flex',
			flexDirection: 'column',
			minWidth: '20rem',
		},
		list: {
			overflow: 'auto',
			padding: 0,
		},
		titleText: {
			lineHeight: '48px',
			margin: '0 0.5rem',
		},
		topBar: {
			display: 'flex',
		},
		taskDetailsContainer: {
			flexGrow: 1,
			overflow: 'auto',
		},
		taskDetails: {
			margin: theme.spacing(2),
		},
		okIcon: {
			color: 'green',
		},
		errorIcon: {
			color: 'red',
		},
		outputTable: {
			marginBottom: theme.spacing(1),
		},
		buttonsContainer: {
			margin: theme.spacing(2),
			flexGrow: 1,
		},
		marginLeft: {
			marginLeft: theme.spacing(2),
		},
		row: {
			display: 'flex',
			marginBottom: theme.spacing(1),
			'&>h6': {
				width: '12rem',
				flexShrink: 0,
			},
		},
	}),
);

function fetchTasksDefinition() {
	return ApiService.get<ITaskDefinition[]>(`${process.env.REACT_APP_API_URL}/telemetry/api/tasks/definitions`);
}

function fetchTasks() {
	return ApiService.get<ITaskStatus[]>(`${process.env.REACT_APP_API_URL}/telemetry/api/tasks`);
}

function cancelTask(instanceId: string) {
	return ApiService.post<ITaskStatus[]>(
		`${process.env.REACT_APP_API_URL}/telemetry/api/tasks/instances/${instanceId}/terminate`,
		{},
	);
}

export default function ReplayPage() {
	const classes = useStyles();
	const [tasks, setTasks] = useState([] as ITaskStatus[]);
	const [tasksDefinition, setTasksDefinition] = useState([] as ITaskDefinition[]);
	const [taskLabels, setTaskLabels] = useState({} as { [taskName: string]: string });
	const [loadingStatus, setLoadingStatus] = useState(Status.None);
	const [cancelRequestStatus, setCancelRequestStatus] = useState(Status.None);
	const [selectedTask, setSelectedTask] = useState(undefined as ITaskStatus | undefined);
	const [selectedTaskDefinition, setSelectedTaskDefinition] = useState(undefined as ITaskDefinition | undefined);
	const [dialogOpen, setDialogOpen] = useState(false);

	useEffect(() => {
		setLoadingStatus(Status.Loading);

		fetchTasksDefinition()
			.then(defs => {
				setTaskLabels(
					defs.reduce((labels, def) => {
						labels[def.orchestratorName] = def.displayName;

						return labels;
					}, {} as { [taskName: string]: string }),
				);
				setTasksDefinition(defs);
			})
			.catch(error => {
				handleError(new Error(`Failed to load tasks definition (${error.message})`));
				setLoadingStatus(Status.Error);
			});
	}, []);

	useRecursiveTimeout(async () => {
		try {
			const newTasks = await fetchTasks();
			setTasks(newTasks);

			if (!selectedTask && newTasks.length) {
				const task = newTasks[0];
				setSelectedTaskDefinition(tasksDefinition.find(d => d.orchestratorName === task.name));
				setSelectedTask(task);
			}

			if (loadingStatus !== Status.Done) {
				setLoadingStatus(Status.Done);
			}
		} catch (error) {
			handleError(new Error(`Failed to refresh tasks list (${error.message})`));
			setLoadingStatus(Status.Error);
		}
	}, 5000);

	return (
		<div className={classes.root}>
			{dialogOpen && (
				<CreateTaskDialog
					tasksDefinition={tasksDefinition}
					onClose={() => {
						setDialogOpen(false);
					}}
				/>
			)}
			<div className={classes.listContainer}>
				<List className={classes.list}>
					{loadingStatus === Status.Loading && <LinearProgress />}
					{tasks.map(task => (
						<ListItem
							button={true}
							key={task.instanceId}
							selected={selectedTask && task.instanceId === selectedTask.instanceId}
							onClick={() => {
								setSelectedTaskDefinition(tasksDefinition.find(d => d.orchestratorName === task.name));
								setSelectedTask(task);
							}}
						>
							<ListItemText
								primary={taskLabels[task.name]}
								secondary={`Created ${new Date(task.createdTime).toLocaleString()}. Status: ${task.runtimeStatus} ${
									task.runtimeStatus === 'Running' && task.progress ? ` (${task.progress.toFixed(1)}%)` : ''
								}`}
							/>
						</ListItem>
					))}
				</List>
			</div>
			<Divider orientation="vertical" />
			<div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
				<div className={classes.buttonsContainer}>
					<Button
						variant="contained"
						color="primary"
						onClick={() => setDialogOpen(true)}
						disabled={loadingStatus !== Status.Done}
					>
						New replay task...
					</Button>
					{selectedTask && ['Pending', 'Running'].includes(selectedTask.runtimeStatus) && (
						<Button
							variant="contained"
							color="secondary"
							onClick={async () => {
								setCancelRequestStatus(Status.Loading);

								try {
									await cancelTask(selectedTask.instanceId);

									// Wait until next tasks refresh before updating request status.
									// That way it is not possible to re-send the request until there is feedback whether it has worked
									setTimeout(() => setCancelRequestStatus(Status.Done), 5000);
								} catch (error) {
									setCancelRequestStatus(Status.Error);
								}
							}}
							disabled={cancelRequestStatus === Status.Loading}
							className={classes.marginLeft}
						>
							Cancel task
						</Button>
					)}
				</div>
				<Divider />
				<div className={classes.taskDetailsContainer}>
					{selectedTask && selectedTaskDefinition && (
						<div className={classes.taskDetails}>
							<div className={classes.row}>
								<Typography variant="subtitle2">Task type:</Typography>
								<Typography variant="body1">{selectedTaskDefinition.displayName}</Typography>
							</div>
							<div className={classes.row}>
								<Typography variant="subtitle2">Status:</Typography>
								<Typography variant="body1">{selectedTask.runtimeStatus}</Typography>
							</div>
							{selectedTask.runtimeStatus === 'Running' && selectedTask.progress && (
								<div className={classes.row}>
									<Typography variant="subtitle2">Progress:</Typography>
									<Typography variant="body1">{`${selectedTask.progress.toFixed(1)}%`}</Typography>
								</div>
							)}
							<div className={classes.row}>
								<Typography variant="subtitle2">Created time:</Typography>
								<Typography variant="body1">{new Date(selectedTask.createdTime).toLocaleString()}</Typography>
							</div>
							<div className={classes.row}>
								<Typography variant="subtitle2">Last updated:</Typography>
								<Typography variant="body1">{new Date(selectedTask.lastUpdatedTime).toLocaleString()}</Typography>
							</div>
							<div className={classes.row}>
								<Typography variant="subtitle2">Input parameters:</Typography>
								<TableContainer className={classes.outputTable} component={Paper}>
									<Table size="small">
										<TableHead>
											<TableRow>
												<TableCell>Parameter</TableCell>
												<TableCell>Value</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{selectedTaskDefinition.parameters.map(definition => {
												const inputValue =
													(selectedTask.input && selectedTask.input[definition.name]) || definition.default;
												const value =
													definition.type === 'Date'
														? new Date(String(inputValue)).toLocaleString()
														: String(inputValue);

												return (
													<TableRow key={definition.name}>
														<TableCell>{definition.label}</TableCell>
														<TableCell>{value}</TableCell>
													</TableRow>
												);
											})}
										</TableBody>
									</Table>
								</TableContainer>
							</div>
							<div className={classes.row}>
								<Typography variant="subtitle2">Output:</Typography>
								{selectedTask.output &&
									(typeof selectedTask.output === 'string' ? (
										<Typography>{selectedTask.output}</Typography>
									) : (
										<TableContainer className={classes.outputTable} component={Paper}>
											<Table size="small">
												<TableHead>
													<TableRow>
														<TableCell />
														<TableCell>Status</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{Object.keys(selectedTask.output).map(key => {
														const status = selectedTask.output[key];

														return (
															<TableRow key={key}>
																<TableCell>{key}</TableCell>
																<TableCell>
																	{status === 'ok' ? (
																		<Check className={classes.okIcon} />
																	) : (
																		<ErrorIcon className={classes.errorIcon} />
																	)}
																</TableCell>
															</TableRow>
														);
													})}
												</TableBody>
											</Table>
										</TableContainer>
									))}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

import io from 'socket.io-client';
import { action, runInAction } from 'mobx';

import * as HttpService from './httpService';
import rootStore from '../store';
import { ImportDialog } from 'components/ImportDialog';
import { Status } from 'DataTypes';
import { IParsedCsvFile, IApiEntity } from '@mitie/metadata-api-types';

export let socket: SocketIOClient.Socket;
const importers: { [importId: string]: { component: ImportDialog; index: number } } = {};
const pointsSubscribedToTelemetry: string[] = [];

export function get<T>(path: string, params?: any) {
	return HttpService.get<T>(path, params);
}

export function post<T>(path: string, params: any) {
	return HttpService.post<T>(path, params);
}

export function put<T>(path: string, params: any) {
	return HttpService.put<T>(path, params);
}

export function delete_<T>(path: string, params?: any) {
	return HttpService.delete_<T>(path, params);
}

export async function initWebsocket() {
	socket = io.connect(`${process.env.REACT_APP_API_URL}`, { transports: ['websocket'] });

	socket.on(
		'disconnect',
		action(() => {
			rootStore.globals.websocketConnected = false;
		}),
	);

	socket.on('connect', () => {
		// resubscribe on connect
		for (const existingPointId of pointsSubscribedToTelemetry) {
			socket.emit('subscribe-telemetry', existingPointId);
		}

		runInAction(() => {
			rootStore.globals.websocketConnected = true;
		});
	});

	socket.on('unauthorized', () => socket.disconnect());

	socket.on('import-status', (importId: string, statusText: string) => {
		const importer = importers[importId];

		if (!importer) {
			return;
		}

		importer.component.setFileContent(importer.index, { status: Status.Loading, statusText });
	});

	socket.on('import-error', (importId: string, error: string) => {
		const importer = importers[importId];

		if (!importer) {
			return;
		}

		importer.component.setFileContent(importer.index, { status: Status.Error, error });
	});

	socket.on('import-done', (importId: string, content: IParsedCsvFile) => {
		const importer = importers[importId];

		if (!importer) {
			return;
		}

		importer.component.setFileContent(importer.index, { status: Status.Done, content, statusText: undefined });
	});

	socket.on('entity-updated', (entity: IApiEntity) => {
		rootStore.entities.updateEntity(entity);
		rootStore.globals.savePendingCount--;
	});

	socket.on('entity-deleted', (entityId: string) => {
		rootStore.entities.deleteEntity(entityId);
		rootStore.globals.savePendingCount--;
	});
}

export function socketId() {
	return socket.id;
}

export function registerImporter(importId: string, component: ImportDialog, index: number) {
	importers[importId] = {
		component,
		index,
	};
}

export function subscribeToPointData(pointId: string) {
	if (!pointsSubscribedToTelemetry.includes(pointId)) {
		while (pointsSubscribedToTelemetry.length > 0) {
			const existingPointId = pointsSubscribedToTelemetry.pop();

			if (rootStore.globals.websocketConnected) {
				socket.emit('unsubscribe-telemetry', existingPointId);
			}
		}

		pointsSubscribedToTelemetry.push(pointId);

		if (rootStore.globals.websocketConnected) {
			socket.emit('subscribeTelemetry', pointId);
		}
	}
}

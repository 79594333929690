import { observable, action, computed } from 'mobx';
import { IEntityPointTemplate, IApiTemplateUpdate } from '@mitie/metadata-api-types';
import { v4 } from 'uuid';

import Templates from './templates';
import { Root } from 'store';
import { EntityPointTemplate } from './entityPointTemplate';

export default class EntityPointTemplates extends Templates<IEntityPointTemplate> {
	@observable private list: { [id: string]: EntityPointTemplate } = {};

	constructor(rootStore: Root) {
		super(rootStore, 'entity_point');
	}

	public getTemplate(id: string): EntityPointTemplate | undefined {
		return this.list[id];
	}

	@action.bound
	public addAndGetTemplate(templateId: string): EntityPointTemplate {
		const template = this.getTemplate(templateId);

		if (template) {
			return template;
		}

		const newTemplate = new EntityPointTemplate(templateId);
		this.list[templateId] = newTemplate;

		return newTemplate;
	}

	@computed
	public get templates() {
		return Object.keys(this.list).map(id => this.list[id]);
	}

	public createTemplate(name: string, tags: string[], datatype?: 'number' | 'boolean', defaultUnits?: string) {
		const id = v4();
		const newTemplate = this.addAndGetTemplate(id);

		const data: IApiTemplateUpdate<IEntityPointTemplate> = {
			id,
			name,
			template_type: 'entity_point',
			template: {
				tags,
			},
		};

		if (datatype) {
			data.template.datatype = datatype;
		}

		if (defaultUnits) {
			data.template.default_units = defaultUnits;
		}

		newTemplate.setDataFromUi(data);

		return newTemplate;
	}

	public deleteTemplate(template: EntityPointTemplate) {
		delete this.list[template.id];
	}
}

import { IApiTemplateUpdate, IDeviceTemplate, IApiTemplate } from '@mitie/metadata-api-types';
import { action, computed } from 'mobx';
import { cloneDeep } from 'lodash';

import { Template } from './template';
import stores from './index';
import { deepEqual } from 'utils';
import { Status } from 'DataTypes';
import * as ApiService from '../services/apiService';
import { logError } from 'errors';

export class DeviceTemplate extends Template<IDeviceTemplate> {
	public constructor(id: string) {
		super(id, 'device');
	}

	@computed
	public get modified() {
		if (!this.data || !this.savedData) {
			return false;
		}

		if (!deepEqual(this.data, this.savedData)) {
			return true;
		}

		if (this.data.template.default_points_templates) {
			for (const dpTemplateId of this.data.template.default_points_templates) {
				const dpTemplate = stores.devicePointTemplates.addAndGetTemplate(dpTemplateId);
				if (dpTemplate.modified) {
					return true;
				}
			}
		}

		return false;
	}

	@action.bound
	public setDataFromUi(template: IApiTemplateUpdate<IDeviceTemplate>) {
		this.data = this.extractDetails(template);
	}

	@action.bound
	public discardChanges() {
		if (this.savedData) {
			const originalDevicePoints = this.savedData.template.default_points_templates;

			if (originalDevicePoints) {
				for (const originalDevicePoint of originalDevicePoints) {
					const ptTemplate = stores.devicePointTemplates.addAndGetTemplate(originalDevicePoint);
					ptTemplate.discardChanges();
				}
			}

			this.data = cloneDeep(this.savedData);
		}
	}

	@action.bound
	public async save() {
		if (this.unsaved) {
			this.saveRequest = Status.Loading;

			try {
				if (this.created) {
					const data: IApiTemplate<IDeviceTemplate> = await ApiService.post(
						`${process.env.REACT_APP_API_URL}/metadata/secure/templates`,
						this.parseForApi(),
					);
					this.setDataFromApi(data, true);
				} else if (this.deleted) {
					await ApiService.delete_(`${process.env.REACT_APP_API_URL}/metadata/secure/templates/device/${this.id}`);

					stores.deviceTemplates.deleteTemplate(this);
					this.dataRequest = Status.Done;
				} else {
					const data: IApiTemplate<IDeviceTemplate> = await ApiService.put(
						`${process.env.REACT_APP_API_URL}/metadata/secure/templates/${this.id}`,
						this.parseForApi(),
					);
					this.setDataFromApi(data, true);
				}

				const devicePoints = this.data?.template.default_points_templates;

				if (devicePoints) {
					for (const devicePoint of devicePoints) {
						const ptTemplate = stores.devicePointTemplates.addAndGetTemplate(devicePoint);
						ptTemplate.save();
					}
				}

				this.saveRequest = Status.Done;
			} catch (error) {
				logError(error);
				this.saveRequest = Status.Error;
			}
		}
	}
}

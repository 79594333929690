import * as React from 'react';
import { TextField, InputAdornment, Tooltip, IconButton } from '@material-ui/core';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import classNames from 'classnames';
import { IDeviceProperty } from '@mitie/metadata-api-types';
import { Done, ErrorOutline, HelpOutline } from '@material-ui/icons';
import { formatRelative } from 'date-fns';

const styles = (theme: Theme) =>
	createStyles({
		green: {
			color: '#009900',
		},
	});

interface IDeviceTwinPropertyProps extends WithStyles<typeof styles> {
	value?: IDeviceProperty<string | number | boolean>;
	label: string;
	type: 'string' | 'number' | 'boolean';
	select?: Array<{ value: number | string; label: string }>;
	className: string;
	onChange: (v: number | string | boolean) => void;
}

@observer
class DeviceTwinProperty extends React.Component<IDeviceTwinPropertyProps> {
	@computed
	private get match() {
		const { value } = this.props;

		if (!value) {
			return undefined;
		}

		if (value.reported === undefined) {
			return false;
		}

		return value.reported === value.desired;
	}

	@computed
	private get reportedTooltip() {
		const { value } = this.props;

		if (!value) {
			return 'Value not set';
		}

		return value.reported !== undefined ? (
			<>
				{`Reported value: ${value.reported}`}
				<br />
				{`Reported time: ${value.lastReported ? formatRelative(new Date(value.lastReported), new Date()) : 'unknown'}`}
			</>
		) : (
			<span>No reported value</span>
		);
	}

	@computed
	private get desiredValue() {
		const { value } = this.props;

		return this.parseInputValue(value ? value.desired : null);
	}

	public render() {
		const { label, type, className, onChange, select, classes } = this.props;

		if (type === 'boolean') {
			return (
				<TextField
					value={this.desiredValue}
					select={true}
					label={label}
					type={type}
					onChange={e => onChange(this.parseValue(e.target.value))}
					margin="normal"
					variant="outlined"
					className={classNames([className])}
					SelectProps={{
						native: true,
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Tooltip title={this.reportedTooltip}>
									<IconButton>
										{this.match === undefined ? (
											<HelpOutline />
										) : this.match ? (
											<Done className={classes.green} />
										) : (
											<ErrorOutline color="error" />
										)}
									</IconButton>
								</Tooltip>
							</InputAdornment>
						),
					}}
					InputLabelProps={{
						shrink: true,
					}}
				>
					<option value={''}>No</option>
					<option value={'1'}>Yes</option>
				</TextField>
			);
		} else if (select) {
			return (
				<TextField
					value={this.desiredValue}
					select={true}
					label={label}
					type={type}
					onChange={e => onChange(this.parseValue(e.target.value))}
					margin="normal"
					variant="outlined"
					className={classNames([className])}
					SelectProps={{
						native: true,
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Tooltip title={this.reportedTooltip}>
									<IconButton>
										{this.match === undefined ? (
											<HelpOutline />
										) : this.match ? (
											<Done className={classes.green} />
										) : (
											<ErrorOutline color="error" />
										)}
									</IconButton>
								</Tooltip>
							</InputAdornment>
						),
					}}
					InputLabelProps={{
						shrink: true,
					}}
				>
					{select.map(({ value, label: l }) => (
						<option key={value} value={value}>
							{l}
						</option>
					))}
				</TextField>
			);
		} else {
			return (
				<TextField
					value={this.desiredValue}
					label={label}
					type={type}
					onChange={e => onChange(this.parseValue(e.target.value))}
					margin="normal"
					variant="outlined"
					className={classNames([className])}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Tooltip title={this.reportedTooltip}>
									<IconButton>
										{this.match === undefined ? (
											<HelpOutline />
										) : this.match ? (
											<Done className={classes.green} />
										) : (
											<ErrorOutline color="error" />
										)}
									</IconButton>
								</Tooltip>
							</InputAdornment>
						),
					}}
				/>
			);
		}
	}

	private parseValue(value: string) {
		const { type } = this.props;

		switch (type) {
			case 'boolean':
				return !!value;
			case 'number':
				return parseFloat(value);
			default:
				return value;
		}
	}

	private parseInputValue(value?: string | boolean | number | null) {
		const { type } = this.props;

		switch (type) {
			case 'boolean':
				return value ? '1' : '';
			case 'number':
				return Number(value);
			default:
				return value || '';
		}
	}
}

export default withStyles(styles)(DeviceTwinProperty);

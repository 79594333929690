import { observable, action, computed } from 'mobx';
import { IExternalSystemTemplate } from '@mitie/metadata-api-types';
import { v4 } from 'uuid';

import Templates from './templates';
import { Root } from 'store';
import { ExternalSystemTemplate } from './externalSystemTemplate';

export default class ExternalSystemTemplates extends Templates<IExternalSystemTemplate> {
	@observable private list: { [id: string]: ExternalSystemTemplate } = {};

	constructor(rootStore: Root) {
		super(rootStore, 'external_system');
	}

	public getTemplate(id: string): ExternalSystemTemplate | undefined {
		return this.list[id];
	}

	@action.bound
	public addAndGetTemplate(templateId: string): ExternalSystemTemplate {
		const template = this.getTemplate(templateId);

		if (template) {
			return template;
		}

		const newTemplate = new ExternalSystemTemplate(templateId);
		this.list[templateId] = newTemplate;

		return newTemplate;
	}

	@computed
	public get templates() {
		return Object.keys(this.list)
			.map(id => this.list[id])
			.sort((a, b) => a.displayName.localeCompare(b.displayName));
	}

	public createTemplate() {
		const entityId = v4();
		const newTemplate = this.addAndGetTemplate(entityId);

		newTemplate.setDataFromUi({
			id: entityId,
			name: 'New integration template',
			template_type: 'external_system',
			template: {
				system_id: '',
				features: [],
				properties: [],
				device_twin_tags: {},
			},
		});

		return newTemplate;
	}

	public deleteTemplate(template: ExternalSystemTemplate) {
		delete this.list[template.id];
	}
}

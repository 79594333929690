import React, { useState } from 'react';
import { useObserver } from 'mobx-react';
import { autorun } from 'mobx';
import {
	List,
	ListItem,
	ListItemText,
	LinearProgress,
	Divider,
	Typography,
	Tooltip,
	IconButton,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import classNames from 'classnames';
import { TemplateType } from '@mitie/metadata-api-types';

import { useStores } from 'store';
import { Status } from 'DataTypes';
import TemplateDetails from 'components/TemplateDetails';
import { templateTypeLabel } from 'utils';
import { DeviceTemplate } from 'store/deviceTemplate';
import { EntityTemplate } from 'store/entityTemplate';
import { ExternalSystemTemplate } from 'store/externalSystemTemplate';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexGrow: 1,
		},
		listContainer: {
			display: 'flex',
			flexDirection: 'column',
		},
		list: {
			display: 'flex',
			minHeight: 0,
		},
		titleText: {
			lineHeight: '48px',
			margin: '0 0.5rem',
		},
		topBar: {
			display: 'flex',
		},
		grow: {
			flexGrow: 1,
		},
		unsaved: {
			color: theme.palette.secondary.dark,
		},
		deleted: {
			textDecoration: 'line-through',
		},
	}),
);

interface ITemplatesListProps {
	templateType: TemplateType;
}

export default function TemplatesList({ templateType }: ITemplatesListProps) {
	const classes = useStyles();
	const { deviceTemplates, entityTemplates, externalSystemTemplates } = useStores();

	const [selectedTemplate, setSelectedTemplate] = useState(
		null as DeviceTemplate | EntityTemplate | ExternalSystemTemplate | null,
	);

	const [templates, setTemplates] = useState(
		templateType === 'device' ? deviceTemplates : templateType === 'entity' ? entityTemplates : externalSystemTemplates,
	);

	React.useEffect(() => {
		if (templates.fetchStatus === Status.None) {
			templates.fetchAll();
		}

		setSelectedTemplate(null);
	}, [templates]);

	React.useEffect(() => {
		autorun(() => {
			for (const template of templates.templates) {
				if (template.usageRequest === Status.None) {
					template.fetchUsage();
				}
			}

			if (
				selectedTemplate &&
				!(templates.templates as Array<DeviceTemplate | EntityTemplate | ExternalSystemTemplate>).find(
					t => t.id === selectedTemplate.id,
				)
			) {
				// Deselect template if it doesn't exist (e.g. it was deleted)
				setSelectedTemplate(null);
			}
		});
	}, [selectedTemplate, templates]);

	React.useEffect(() => {
		setTemplates(
			templateType === 'device'
				? deviceTemplates
				: templateType === 'entity'
				? entityTemplates
				: externalSystemTemplates,
		);
	}, [templateType, deviceTemplates, entityTemplates, externalSystemTemplates]);

	return useObserver(() => (
		<div className={classes.root}>
			<div className={classes.listContainer}>
				<div className={classes.topBar}>
					<Typography variant="h6" className={classes.titleText}>
						Templates
					</Typography>
					<div className={classes.grow} />
					<Tooltip title={`New ${templateTypeLabel[templateType]} template`} placement="bottom">
						<IconButton
							color="secondary"
							onClick={() => {
								const newTemplate = templates.createTemplate();

								setSelectedTemplate(newTemplate);
							}}
						>
							<Add />
						</IconButton>
					</Tooltip>
				</div>
				<Divider />
				<div className={classes.list}>
					<div style={{ overflow: 'auto', minWidth: '12rem' }}>
						{templates.fetchStatus === Status.Loading && <LinearProgress />}
						<List style={{ padding: 0 }}>
							{(templates.templates as Array<DeviceTemplate | EntityTemplate | ExternalSystemTemplate>).map(
								template => (
									<ListItem
										button={true}
										key={template.id}
										selected={selectedTemplate?.id === template.id}
										onClick={() => setSelectedTemplate(template)}
									>
										<ListItemText
											style={{ whiteSpace: 'nowrap' }}
											primary={
												<Typography
													variant="subtitle1"
													className={classNames({
														[classes.unsaved]: template.unsaved,
														[classes.deleted]: template.deleted,
													})}
												>
													{template.displayName}
												</Typography>
											}
											secondary={
												template.usage === undefined ? 'Fetching usage...' : `Used by ${template.usage} entities`
											}
										/>
									</ListItem>
								),
							)}
						</List>
					</div>
				</div>
			</div>
			<Divider orientation="vertical" />
			{selectedTemplate && <TemplateDetails template={selectedTemplate} templateType={templateType} />}
		</div>
	));
}

import * as React from 'react';
import { useLocalStore, useObserver } from 'mobx-react';
import { List, ListItem, ListItemText, Divider, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { TemplateType } from '@mitie/metadata-api-types';

import TemplatesList from 'components/TemplatesList';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexGrow: 1,
		},
		listContainer: {
			display: 'flex',
			flexDirection: 'column',
			minWidth: '12rem',
		},
		list: {
			overflow: 'auto',
			padding: 0,
		},
		titleText: {
			lineHeight: '48px',
			margin: '0 0.5rem',
		},
		topBar: {
			display: 'flex',
		},
	}),
);

const templateTypes: Array<{ type: TemplateType; label: string }> = [
	{
		type: 'device',
		label: 'Devices',
	},
	{
		type: 'entity',
		label: 'Entities',
	},
	{
		type: 'external_system',
		label: 'Integration',
	},
];

interface ITemplatesPageStore {
	templateType: TemplateType | null;
	setTemplateType: (type: TemplateType) => void;
}

export default function TemplatesPage() {
	const classes = useStyles();
	const store = useLocalStore(
		(): ITemplatesPageStore => ({
			templateType: null,
			setTemplateType: (type: TemplateType) => (store.templateType = type),
		}),
	);

	return useObserver(() => (
		<div className={classes.root}>
			<div className={classes.listContainer}>
				<div className={classes.topBar}>
					<Typography variant="h6" className={classes.titleText}>
						Template types
					</Typography>
				</div>
				<Divider />
				<List className={classes.list}>
					{templateTypes.map(({ type, label }) => (
						<ListItem
							button={true}
							key={type}
							selected={store.templateType === type}
							onClick={() => store.setTemplateType(type)}
						>
							<ListItemText primary={label} />
						</ListItem>
					))}
				</List>
			</div>
			<Divider orientation="vertical" />
			{store.templateType && <TemplatesList templateType={store.templateType} />}
		</div>
	));
}

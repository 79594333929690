import * as React from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import Entity from 'store/entity';
import { observer } from 'mobx-react';
import { autorun, IReactionDisposer } from 'mobx';

const styles = (theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
		},
		listContainer: {
			flexGrow: 0,
		},
	});

interface IEntityPointsListProps extends WithStyles<typeof styles> {
	entity: Entity;
}

@observer
class EntityPointsList extends React.Component<IEntityPointsListProps> {
	private disposables: IReactionDisposer[] = [];

	public componentDidMount() {
		this.disposables.push(
			autorun(() => {
				const { entity } = this.props;

				if (!entity.childrenRequest.entity) {
					entity.fetchChildren('entity');
				}
			}),
		);
	}

	public componentWillUnmount() {
		this.disposables.forEach(d => d());
	}

	public render() {
		const { entity, classes } = this.props;

		return (
			<div className={classes.container}>
				<div className={classes.listContainer}>
					<List>
						{entity.children.entity &&
							entity.children.entity.map(pointEntity => (
								<ListItem key={pointEntity.id}>
									<ListItemText primary={pointEntity.displayName} />
								</ListItem>
							))}
					</List>
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(EntityPointsList);

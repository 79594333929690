import { observable, action, computed, reaction, toJS, runInAction } from 'mobx';
import { IApiEntityUpdate, IApiEntity, IApiEntitiesSaveRequest, RelationType } from '@mitie/metadata-api-types';

import Entity from './entity';
import { Root } from './';
import { Status } from 'DataTypes';
import * as ApiService from '../services/apiService';
import { handleError } from 'errors';

class Entities {
	@computed
	public get entitiesUnsavedCount() {
		return Object.keys(this.unsavedList).reduce((count, id) => {
			const entity = this.unsavedList[id];

			if (!entity.isPoint) {
				count++;
			}

			return count;
		}, 0);
	}
	@observable public unsavedList: { [id: string]: Entity } = {};
	@observable public clients: Entity[] = [];
	@observable public rootDevices: Entity[] = [];
	@observable public orphanedEntities: Entity[] = [];
	@observable public clientsFetchStatus: Status = Status.None;
	@observable public rootDevicesFetchStatus: Status = Status.None;
	@observable public entitiesToFetch: string[] = [];
	@observable
	public childrenToFetch: { [relationType in RelationType]: string[] } = {
		device: [],
		entity: [],
		location: [],
		client: [],
		gateway: [],
		equip: [],
	};
	@observable private list: { [id: string]: Entity } = {};
	private rootStore: Root;

	constructor(rootStore: Root) {
		this.rootStore = rootStore;

		reaction(
			() => toJS(this.entitiesToFetch),
			entitiesToFetch => {
				if (entitiesToFetch.length === 0) {
					return;
				}

				const chunkSize = 100;

				while (entitiesToFetch.length > 0) {
					const chunk = entitiesToFetch.splice(0, chunkSize);
					this.fetchEntitiesByIds(chunk);
				}

				this.entitiesToFetch = [];
			},
			{ delay: 300 },
		);

		reaction(
			() => toJS(this.childrenToFetch),
			childrenToFetch => {
				const chunkSize = 100;

				for (const relationType of Object.keys(childrenToFetch)) {
					const typedRelationType = relationType as RelationType;
					const ids = childrenToFetch[typedRelationType];

					if (ids.length > 0) {
						while (ids.length > 0) {
							const chunk = ids.splice(0, chunkSize);
							this.fetchMultipleEntitiesChildren(typedRelationType, chunk);
						}

						this.childrenToFetch[typedRelationType] = [];
					}
				}
			},
			{ delay: 300 },
		);
	}

	public getEntity(entityId: string): Entity | undefined {
		return this.list[entityId];
	}

	@action.bound
	public addAndGetEntity(entityId: string): Entity {
		const entity = this.getEntity(entityId);

		if (entity) {
			return entity;
		}

		const newEntity = new Entity(this.rootStore, entityId);
		this.list[entityId] = newEntity;

		return newEntity;
	}

	@action.bound
	public discardAll() {
		for (const entityId of Object.keys(this.unsavedList)) {
			const entity = this.list[entityId];

			if (entity) {
				entity.discardChanges();
			}
		}
	}

	public async saveAll() {
		const request: IApiEntitiesSaveRequest = {
			create: [],
			update: [],
			delete: [],
		};

		for (const entityId of Object.keys(this.unsavedList)) {
			const entity = this.list[entityId];

			if (!entity) {
				continue;
			}

			if (entity.deleted) {
				request.delete.push(entity.id);
			} else {
				const parsedEntity = entity.parseForApi();

				if (!parsedEntity) {
					continue;
				}

				if (entity.created) {
					request.create.push(parsedEntity);
				} else if (entity.modified) {
					request.update.push(parsedEntity);
				}
			}
		}

		await ApiService.post(`${process.env.REACT_APP_API_URL}/metadata/secure/entities/batchSave`, request);

		const totalRequests = request.update.length + request.create.length + request.delete.length;
		this.rootStore.globals.savePendingCount += totalRequests;
		this.rootStore.globals.saveTotalCount += totalRequests;
	}

	@action.bound
	public addEntitiesFromUi(entities: IApiEntityUpdate[]) {
		for (const e of entities) {
			const entity = this.addAndGetEntity(e.id);
			entity.setDataFromUi(e);
		}
	}

	@action.bound
	public deleteEntity(entity: Entity | string) {
		const { routes } = this.rootStore;
		const entityId = typeof entity === 'string' ? entity : entity.id;

		if (this.clients.find(e => e.id === entityId)) {
			this.clients = this.clients.filter(e => e.id !== entityId);
		}

		if (this.rootDevices.find(e => e.id === entityId)) {
			this.rootDevices = this.rootDevices.filter(e => e.id !== entityId);
		}

		if (routes.entityId === entityId) {
			routes.setFilters({ entity: null });
		}

		delete this.list[entityId];
		delete this.unsavedList[entityId];
	}

	@action.bound
	public async fetchClients() {
		if (this.clientsFetchStatus !== Status.None) {
			return;
		}

		this.clientsFetchStatus = Status.Loading;

		try {
			const resp: IApiEntity[] = await ApiService.get(
				`${process.env.REACT_APP_API_URL}/metadata/secure/entities/clients`,
			);

			this.clients = resp
				.sort((a, b) => {
					if (a.name < b.name) {
						return -1;
					}
					if (a.name > b.name) {
						return 1;
					}
					return 0;
				})
				.map(entityData => {
					const entity = this.addAndGetEntity(entityData.id);
					entity.setDataFromApi(entityData);

					return entity;
				});

			const { routes } = this.rootStore;

			if (routes.route.name === 'entities') {
				if (this.clients.length > 0) {
					if (!routes.entity) {
						routes.setFilters({ entity: this.clients[0] });
					}
				} else {
					routes.setFilters({ entity: null });
				}
			}

			this.clientsFetchStatus = Status.Done;
		} catch (error) {
			handleError(new Error(`Failed to load clients list (${error.message})`));
			this.clientsFetchStatus = Status.Error;
		}
	}

	@action.bound
	public async fetchRootDevices() {
		if (this.rootDevicesFetchStatus !== Status.None) {
			return;
		}

		this.rootDevicesFetchStatus = Status.Loading;

		try {
			const resp: IApiEntity[] = await ApiService.get(
				`${process.env.REACT_APP_API_URL}/metadata/secure/entities/devices/root`,
			);

			this.rootDevices = resp
				.sort((a, b) => {
					if (a.name < b.name) {
						return -1;
					}
					if (a.name > b.name) {
						return 1;
					}
					return 0;
				})
				.map(entityData => {
					const entity = this.addAndGetEntity(entityData.id);
					entity.setDataFromApi(entityData);

					return entity;
				});

			const { routes } = this.rootStore;

			if (routes.route.name === 'devices') {
				if (this.rootDevices.length > 0) {
					if (!routes.entity) {
						routes.setFilters({ entity: this.rootDevices[0] });
					}
				} else {
					routes.setFilters({ entity: null });
				}
			}

			this.rootDevicesFetchStatus = Status.Done;
		} catch (error) {
			handleError(new Error(`Failed to load list of top level devices (${error.message})`));
			this.rootDevicesFetchStatus = Status.Error;
		}
	}

	@action.bound
	public updateEntity(apiEntity: IApiEntity) {
		const entity = this.addAndGetEntity(apiEntity.id);
		entity.setDataFromApi(apiEntity, true);
	}

	private async fetchEntitiesByIds(ids: string[]) {
		try {
			const resp: IApiEntity[] = await ApiService.post(
				`${process.env.REACT_APP_API_URL}/metadata/secure/entities/byIds`,
				{
					ids,
				},
			);

			runInAction(() => {
				let idsMissing = [...ids];

				resp.forEach(entityData => {
					const entity = this.rootStore.entities.addAndGetEntity(entityData.id);
					entity.setDataFromApi(entityData);

					idsMissing = idsMissing.filter(id => id !== entityData.id);
				});

				for (const id of idsMissing) {
					const entity = this.rootStore.entities.getEntity(id);

					if (entity) {
						entity.dataRequest = Status.Empty;
					}
				}
			});
		} catch (error) {
			for (const id of ids) {
				const entity = this.rootStore.entities.getEntity(id);

				if (entity) {
					entity.dataRequest = Status.Error;
				}
			}

			handleError(new Error(`Failed to load entities (${error.message})`));
		}
	}

	private async fetchMultipleEntitiesChildren(relationType: RelationType, ids: string[]) {
		try {
			const resp: IApiEntity[] = await ApiService.post(
				`${process.env.REACT_APP_API_URL}/metadata/secure/entities/children/byIds`,
				{
					relation_type: relationType,
					ids,
				},
			);

			runInAction(() => {
				// Add entities to store
				resp.forEach(entityData => {
					const entity = this.rootStore.entities.addAndGetEntity(entityData.id);
					entity.setDataFromApi(entityData, false);
				});

				// Update request status
				for (const entityId of ids) {
					const entity = this.rootStore.entities.getEntity(entityId);

					if (entity) {
						entity.childrenRequest[relationType] = Status.Done;
					}
				}
			});
		} catch (error) {
			// Update request status
			for (const entityId of ids) {
				const entity = this.rootStore.entities.getEntity(entityId);

				if (entity) {
					entity.childrenRequest[relationType] = Status.Error;
				}
			}

			handleError(new Error(`Failed to load entities children (${error.message})`));
		}
	}
}

export default Entities;

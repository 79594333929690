import * as React from 'react';
import {
	AppBar,
	IconButton,
	Menu,
	MenuItem,
	Toolbar,
	Typography,
	Tooltip,
	CircularProgress,
	Divider,
} from '@material-ui/core';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import {
	AccountCircle,
	Menu as MenuIcon,
	OfflineBoltOutlined,
	NotificationImportantOutlined,
} from '@material-ui/icons';
import { observable, computed } from 'mobx';
import { Routes } from 'store';
import Globals from 'store/globals';
import User from 'store/user';

const styles = (theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
		},
		grow: {
			flexGrow: 1,
		},
		menuButton: {
			marginLeft: -12,
			marginRight: 20,
		},
		notificationButtonProgress: {
			color: theme.palette.secondary.main,
			position: 'relative',
			left: -48,
			zIndex: -1,
		},
	});

interface IHeaderBarProps extends WithStyles<typeof styles> {
	onNavBarToggle: () => void;
}

@inject('routes', 'globals', 'user')
@observer
class HeaderBar extends React.Component<IHeaderBarProps> {
	@observable private userMenuAnchorEl: HTMLElement | null = null;

	private get injected() {
		return (this.props as any) as {
			routes: Routes;
			globals: Globals;
			user: User;
		};
	}

	@computed
	private get userMenuOpen() {
		return Boolean(this.userMenuAnchorEl);
	}

	public render() {
		const { classes, onNavBarToggle } = this.props;
		const { routes, user } = this.injected;

		return (
			<AppBar>
				<Toolbar>
					<IconButton color="inherit" aria-label="Open menu" onClick={onNavBarToggle}>
						<MenuIcon />
					</IconButton>
					<Typography variant="h6" color="inherit" noWrap={true}>
						{routes.routeLabel}
					</Typography>
					<div className={classes.grow} />
					{this.injected.globals.connected === false && (
						<IconButton>
							<OfflineBoltOutlined color="error" />
						</IconButton>
					)}
					{this.injected.globals.savePendingCount > 0 && (
						<>
							<Tooltip title={`Saving in progress. ${this.injected.globals.savePendingCount} entities pending`}>
								<IconButton color="inherit">
									{/* <Badge badgeContent={256} color="secondary" max={}> */}
									<NotificationImportantOutlined />
									{/* </Badge> */}
								</IconButton>
							</Tooltip>
							<CircularProgress
								size={48}
								className={classes.notificationButtonProgress}
								variant="determinate"
								value={this.injected.globals.saveProgress}
							/>
						</>
					)}
					<IconButton onClick={this.openMenu} color="inherit">
						<AccountCircle />
					</IconButton>
					<Menu
						anchorEl={this.userMenuAnchorEl}
						keepMounted={true}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={this.userMenuOpen}
						onClose={this.closeMenu}
						elevation={0}
						getContentAnchorEl={null}
					>
						<Typography variant="subtitle2" style={{ padding: '0.5rem 1rem' }}>
							{user.username}
						</Typography>
						<Divider />
						<MenuItem onClick={this.logout}>Logout</MenuItem>
					</Menu>
				</Toolbar>
			</AppBar>
		);
	}

	private openMenu = (e: React.MouseEvent<HTMLElement>) => {
		this.userMenuAnchorEl = e.currentTarget;
	};

	private closeMenu = () => {
		this.userMenuAnchorEl = null;
	};

	private logout = () => {
		this.closeMenu();
		this.injected.user.logout();
	};
}

export default withStyles(styles)(HeaderBar);

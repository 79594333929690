import React, { useState, forwardRef } from 'react';
import { useObserver } from 'mobx-react';
import { Chip, Select, Checkbox } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Edit, Check, AddBox, DeleteOutline, ArrowUpward, Clear, Remove } from '@material-ui/icons';
import { IDeviceTwinPropertyDefinition } from '@mitie/metadata-api-types';
import MaterialTable from 'material-table';

import { DeviceTemplate } from 'store/deviceTemplate';
import DropdownOptionsInput from './DropdownOptionsInput';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			margin: theme.spacing(2),
			overflow: 'auto',
		},
		selectChip: {
			marginRight: theme.spacing(1),
			marginBottom: theme.spacing(1) / 2,
		},
		propertyValue: {
			display: 'inline',
		},
		tabs: {
			borderBottom: `1px solid ${theme.palette.divider}`,
		},
		tab: {
			'&>span:first-child': {
				flexDirection: 'row',
			},
		},
		field: {
			marginRight: '1rem',
			minWidth: '25rem',
		},
	}),
);

interface IDeviceTwinPropertiesTableProps {
	template: DeviceTemplate;
}

interface ITableRow extends IDeviceTwinPropertyDefinition {
	tableData?: {
		editing?: boolean;
	};
}

export default function DeviceTwinPropertiesTable({ template }: IDeviceTwinPropertiesTableProps) {
	const classes = useStyles();

	const properties = template.data?.template.device_twin?.properties;
	const [tableData, setTableData] = useState(properties?.map(p => ({ ...p } as ITableRow)) || []);

	React.useEffect(() => {
		if (properties) {
			setTableData(properties?.map(p => ({ ...p })));
		} else {
			setTableData([]);
		}
	}, [properties]);

	return useObserver(() => (
		<MaterialTable<ITableRow>
			title="Device twin properties"
			columns={[
				{ title: 'Name', field: 'name', editable: 'onAdd' },
				{ title: 'Display name', field: 'label' },
				{
					title: 'Data type',
					field: 'type',
					initialEditValue: 'string',
					editComponent: props => (
						<Select native={true} value={props.rowData.type} onChange={v => props.onChange(v.currentTarget.value)}>
							<option value="string">string</option>
							<option value="number">number</option>
							<option value="boolean">boolean</option>
						</Select>
					),
				},
				{
					title: 'Read-only',
					field: 'readOnly',
					initialEditValue: false,
					type: 'boolean',
					editComponent: props => (
						<Checkbox checked={props.rowData.readOnly || false} onChange={v => props.onChange(v.target.checked)} />
					),
				},
				{ title: 'Default value', field: 'default' },
				{ title: 'Parent property for default value', field: 'inheritedDefault' },
				{
					title: 'Values for drop down',
					field: 'select',
					editComponent: ({ value, rowData, onChange }) => (
						<DropdownOptionsInput value={value} type={rowData.type} onChange={onChange} />
					),
					render: ({ select }) => {
						if (!select) {
							return null;
						}

						return select.map(({ label, value }) => <Chip className={classes.selectChip} key={value} label={label} />);
					},
				},
			]}
			data={tableData}
			editable={{
				onRowAdd: newData => {
					if (!newData.name || !newData.label || (properties && properties.find(p => p.name === newData.name))) {
						return Promise.reject();
					} else {
						if (!template.data) {
							return Promise.reject();
						}

						if (!template.data?.template.device_twin) {
							template.data.template.device_twin = { tags: {}, properties: [newData] };
						} else {
							template.data.template.device_twin.properties.push(newData);
						}

						setTableData(template.data.template.device_twin.properties.map(p => ({ ...p })));

						return Promise.resolve();
					}
				},
				onRowUpdate: (newData, oldData) => {
					if (properties && oldData) {
						if (!newData.name || !newData.label || properties.filter(p => p.name === newData.name).length > 1) {
							return Promise.reject();
						} else {
							const propertyIndex = properties.findIndex(p => p.name === oldData.name);

							if (propertyIndex !== -1) {
								properties[propertyIndex] = newData;
								setTableData(properties.map(p => ({ ...p })));
							}
						}
					}

					return Promise.resolve();
				},
				onRowDelete: oldData => {
					if (properties) {
						const propertyIndex = properties.findIndex(p => p.name === oldData.name);

						if (propertyIndex !== -1) {
							properties.splice(propertyIndex, 1);
							setTableData(properties.map(p => ({ ...p })));
						}
					}

					return Promise.resolve();
				},
			}}
			options={{ paging: false, padding: 'dense', search: false, draggable: false }}
			icons={{
				Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
				Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
				Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
				SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
				Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
				Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
				ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
			}}
		/>
	));
}

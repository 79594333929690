import { observable, computed, autorun, action } from 'mobx';
import { NavigationOptions, State } from 'router5';

import { ILinkData, routes, options } from '../routing/routes';
import { makeMobxRouter } from '../routing/mobxRouterMiddleware';
import Entity from './entity';
import { Root } from '.';
import { Status } from 'DataTypes';
import { TabName } from 'components/EntityView';

export type Hierarchy = 'location' | 'devices' | 'assets';

export interface IFilters {
	entity?: Entity | null;
	entityId?: string;
	selectedTab?: TabName;
	browseBy?: Hierarchy;
	location?: Entity | null;
	locationId?: string;
}

class Routes {
	public routes = routes;
	public router = makeMobxRouter(this.routes, options, this);
	@observable
	public route: {
		name: string | null;
		params: IFilters;
	} = {
		name: null,
		params: {},
	};
	@observable public entity?: Entity;
	@observable public location?: Entity;
	private rootStore: Root;

	constructor(rootStore: Root) {
		this.rootStore = rootStore;

		autorun(() => {
			// Load data for selected entity
			if (this.entityId) {
				const entity = this.rootStore.entities.addAndGetEntity(this.entityId);

				if (!entity.created && entity.dataRequest === Status.None) {
					entity.fetchData();
				}

				this.entity = entity;
			} else {
				this.entity = undefined;
			}
		});

		autorun(() => {
			// Load data for selected location
			if (this.locationId) {
				const entity = this.rootStore.entities.addAndGetEntity(this.locationId);

				if (!entity.created && entity.dataRequest === Status.None) {
					entity.fetchData();
				}

				this.location = entity;
			} else {
				this.location = undefined;
			}
		});

		autorun(() => {
			if (
				this.entity &&
				!this.entity.created &&
				(this.entity.dataRequest === Status.Error || this.entity.dataRequest === Status.Empty)
			) {
				this.setFilters({ entity: null, location: null });
			}
		});
	}

	public get routeParams() {
		return this.route.params;
	}

	@computed
	public get routeComponent() {
		if (!this.route.name) {
			return null;
		}

		return this.routes[this.route.name].component;
	}

	@computed
	public get routeLabel() {
		if (!this.route.name) {
			return undefined;
		}

		return this.routes[this.route.name].label;
	}

	@computed
	public get entityId() {
		return this.route.params.entityId;
	}

	@computed
	public get locationId() {
		return this.route.params.locationId;
	}

	@computed
	public get selectedTab() {
		const param = this.route.params.selectedTab;

		if (param === undefined) {
			return TabName.Entity;
		} else {
			return Number(param) as TabName;
		}
	}

	@computed
	public get parentEntityIds() {
		if (!this.entity) {
			return undefined;
		}

		const parents: string[] = [];

		for (
			let parent: Entity | undefined = this.entity.parentLocation;
			parent !== undefined;
			parent = parent.parentLocation
		) {
			parents.push(parent.id);
		}

		return parents;
	}

	@computed
	public get parentDeviceIds() {
		if (!this.entity) {
			return undefined;
		}

		const parents: string[] = [];

		for (
			let parent: Entity | undefined = this.entity.parentDevice;
			parent !== undefined;
			parent = parent.parentDevice
		) {
			parents.push(parent.id);
		}

		return parents;
	}

	public onTransitionStart = (nextState: State) => {
		this.route = {
			params: this.parseParams(nextState.params),
			name: nextState.name,
		};
	};

	public navigate(linkData: ILinkData, navigateOptions?: NavigationOptions) {
		const name = linkData.name || this.route.name;

		if (!name) {
			return;
		}

		const newParams = this.updateParamsWithFilters(
			linkData.params || {},
			navigateOptions && navigateOptions.clearParams,
		);

		this.router.navigate(name, newParams, navigateOptions || {});
	}

	// Changes query params while staying on the same page
	@action.bound
	public setFilters(filters: IFilters, navigateOptions?: NavigationOptions) {
		this.navigate({ params: filters }, navigateOptions);
	}

	private updateParamsWithFilters(filters: IFilters, clearParams?: boolean) {
		const { entity, entityId, location, locationId, selectedTab, browseBy } = filters;
		let navigateParams: Record<string, any> = {};

		// initialise with existing params
		if (!clearParams) {
			navigateParams = { ...this.route.params };
		}

		// merge in the new filters

		if (browseBy) {
			navigateParams.browseBy = browseBy;
		}

		if (entity === null) {
			navigateParams.entityId = undefined;
		} else if (entity) {
			navigateParams.entityId = entity.id;
		} else if (entityId) {
			navigateParams.entityId = entityId;
		}

		if (location === null) {
			navigateParams.locationId = undefined;
		} else if (location) {
			navigateParams.locationId = location.id;
		} else if (locationId) {
			navigateParams.locationId = locationId;
		}

		if (selectedTab !== undefined) {
			navigateParams.selectedTab = selectedTab;
		}

		return navigateParams;
	}

	private parseParams(params: Record<string, any>) {
		const parsedParams: IFilters = {};

		Object.keys(params).forEach(key => {
			const value = params[key];

			if (value !== undefined) {
				parsedParams[key] = value;
			}
		});

		return parsedParams;
	}
}
export default Routes;

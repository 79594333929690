import React, { useState } from 'react';
import { Dialog, LinearProgress, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { useStores } from 'store';
import Entity from 'store/entity';
import { Status } from 'DataTypes';
import SelectableTreeItem from './SelectableTreeItem';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {},
	}),
);

interface ILocationSelectPopupProps {
	location?: Entity;
	onClose: (entity: Entity | null) => void;
}

export default function LocationSelectPopup({ location, onClose }: ILocationSelectPopupProps) {
	const classes = useStyles();
	const { entities } = useStores();
	const [selectedEntity, setSelectedEntity] = useState((location || null) as null | Entity);
	const [expandedClients, setExpandedClients] = useState(
		location?.locationParents?.[0] ? [location.locationParents[0].id] : [],
	);

	return (
		<Dialog open={true} onClose={() => onClose(null)}>
			<DialogTitle>{`Select location entity`}</DialogTitle>
			<DialogContent>
				<div className={classes.container}>
					{entities.clientsFetchStatus === Status.Loading ? (
						<LinearProgress />
					) : (
						entities.clients.map(e => {
							return (
								<SelectableTreeItem
									key={e.id}
									childrenRelationType="location"
									entity={e}
									selected={selectedEntity}
									expanded={expandedClients.includes(e.id)}
									onExpand={() => {
										const newExpandedChildren = [...expandedClients];
										newExpandedChildren.push(e.id);
										setExpandedClients(newExpandedChildren);
									}}
									onCollapse={() => setExpandedClients(expandedClients.filter(id => id !== e.id))}
									onSelect={entity => setSelectedEntity(entity)}
								/>
							);
						})
					)}
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose(null)} color="primary">
					Cancel
				</Button>
				<Button onClick={() => onClose(selectedEntity)} color="primary" disabled={!selectedEntity}>
					OK
				</Button>
			</DialogActions>
		</Dialog>
	);
}

import { observable, action, computed } from 'mobx';
import { v4 } from 'uuid';

import { INotification, NotificationTypes } from '../DataTypes';

class NotificationStore {
	@observable public displayedNotification: INotification | null = null;
	@observable private notifications: INotification[] = [];
	private displayedNotificationTimeout: NodeJS.Timeout | null = null;

	@computed public get notificationsCount() {
		return this.notifications.length;
	}

	@computed public get displayedNotificationIndex() {
		return this.notifications.findIndex(arrNotification => arrNotification.id === this.displayedNotification?.id);
	}

	@action
	public addNotification = (type: NotificationTypes, message: string) => {
		const existingNotification = this.notifications.find(arrNotification => arrNotification.message === message);

		if (existingNotification) {
			// Don't add notification if one exists with same message
			this.displayNotification(existingNotification);
		} else {
			const timeout = type === 'error' ? null : 5000;
			const id = v4();
			const newNotification = {
				id,
				message,
				type,
				timeout,
			};

			this.notifications.push(newNotification);
			this.displayNotification(newNotification);
		}
	};

	@action
	public deleteDisplayedNotification = () => {
		// Delete notification
		if (this.displayedNotification) {
			this.notifications.splice(this.displayedNotificationIndex, 1);
		}

		// Display next notification
		if (this.notifications.length) {
			this.displayNotification(this.notifications[this.notifications.length - 1]);
		} else {
			this.displayedNotification = null;
		}
	};

	@action public displayNextNotification() {
		if (!this.displayedNotification) {
			return;
		}

		if (this.displayedNotificationIndex < this.notifications.length - 1) {
			this.displayNotification(this.notifications[this.displayedNotificationIndex + 1]);
		}
	}

	@action public displayPreviousNotification() {
		if (!this.displayedNotification) {
			return;
		}

		if (this.displayedNotificationIndex > 0) {
			this.displayNotification(this.notifications[this.displayedNotificationIndex - 1]);
		}
	}

	@action
	private displayNotification(notification: INotification) {
		this.displayedNotification = notification;

		if (this.displayedNotificationTimeout) {
			clearInterval(this.displayedNotificationTimeout);
		}

		if (notification.timeout) {
			this.displayedNotificationTimeout = setTimeout(() => this.deleteDisplayedNotification(), notification.timeout);
		}
	}
}

export default NotificationStore;

import React, { useState, forwardRef } from 'react';
import { useObserver } from 'mobx-react';
import { runInAction, autorun } from 'mobx';
import { Chip } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Edit, Check, AddBox, DeleteOutline, ArrowUpward, Clear, Remove } from '@material-ui/icons';
import MaterialTable from 'material-table';

import { useStores } from 'store';
import { EntityTemplate } from 'store/entityTemplate';
import { Status } from 'DataTypes';
import TagsInput from './TagsInput';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			margin: theme.spacing(2),
			overflow: 'auto',
		},
		selectChip: {
			marginRight: theme.spacing(1),
			marginBottom: theme.spacing(1) / 2,
		},
		propertyValue: {
			display: 'inline',
		},
		tabs: {
			borderBottom: `1px solid ${theme.palette.divider}`,
		},
		tab: {
			'&>span:first-child': {
				flexDirection: 'row',
			},
		},
		field: {
			marginRight: '1rem',
			minWidth: '25rem',
		},
		tagsInput: {
			margin: 0,
		},
	}),
);

interface IEntityChildrenDefinitionTableProps {
	template: EntityTemplate;
}

interface ITableRow {
	name: string;
	tags: string[];
	templateId: string;
	tableData?: {
		editing?: boolean;
	};
}

export default function EntityChildrenDefinitionTable({ template }: IEntityChildrenDefinitionTableProps) {
	const classes = useStyles();

	const { entityTemplates: entityTemplatesStore } = useStores();
	const [tableData, setTableData] = useState([] as ITableRow[]);
	const [entityTemplatesSelect, setEntityTemplatesSelect] = useState({} as { [value: string]: string });

	// Fetch list of entity templates for drop down
	React.useEffect(() => {
		if (entityTemplatesStore.fetchStatus === Status.None) {
			entityTemplatesStore.fetchAll();
		}
	}, [entityTemplatesStore]);

	// Build values for template drop down
	React.useEffect(() => {
		autorun(() => {
			setEntityTemplatesSelect(
				entityTemplatesStore.templates.reduce((acc, t) => {
					acc[t.id] = t.displayName;

					return acc;
				}, {}),
			);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Update table data with template data
	React.useEffect(() => {
		if (!template.data?.template.children) {
			setTableData([]);
		} else {
			const data = template.data.template.children.map(({ name, templateId, tags }) => {
				return {
					name,
					tags,
					templateId,
				} as ITableRow;
			});

			setTableData(data);
		}
	}, [entityTemplatesStore, template.data]);

	return useObserver(() => (
		<MaterialTable<ITableRow>
			columns={[
				{ title: 'Name', field: 'name', editable: 'onAdd' },
				{
					title: 'Entity template',
					field: 'templateId',
					lookup: entityTemplatesSelect,
				},
				{
					title: 'Tags',
					field: 'tags',
					editComponent: ({ value, onChange }) => <TagsInput value={value || []} onChange={onChange} />,
					render: ({ tags }) => (tags || []).map(tag => <Chip className={classes.selectChip} key={tag} label={tag} />),
				},
			]}
			data={tableData}
			editable={{
				onRowAdd: newData => {
					if (!newData.name || tableData.find(p => p.name === newData.name)) {
						return Promise.reject();
					} else {
						if (!template.data) {
							return Promise.reject();
						}

						if (!template.data!.template.children) {
							template.data!.template.children = [];
						}

						template.data!.template.children.push({
							name: newData.name,
							tags: newData.tags,
							templateId: newData.templateId,
						});

						const newTableData = [...tableData];
						newTableData.push(newData);
						setTableData(newTableData);

						return Promise.resolve();
					}
				},
				onRowUpdate: (newData, oldData) => {
					if (!newData.name || tableData.filter(p => p.name === newData.name).length > 1) {
						return Promise.reject();
					} else {
						if (oldData) {
							const rowIndex = tableData.findIndex(p => p.name === oldData.name);
							if (rowIndex !== -1) {
								const child = template.data!.template.children!.find(c => c.name === newData.name);

								if (child) {
									child.tags = newData.tags;
									child.templateId = newData.templateId;
								}

								const newTableData = [...tableData];
								newTableData[rowIndex] = newData;
								setTableData(newTableData);
							}
						}
						return Promise.resolve();
					}
				},
				onRowDelete: oldData => {
					const rowIndex = tableData.findIndex(p => p.name === oldData.name);
					if (rowIndex !== -1) {
						runInAction(() => {
							if (template.data!.template.children) {
								template.data!.template.children = template.data!.template.children.filter(
									({ templateId }) => templateId !== oldData.templateId,
								);
							}
						});

						const newTableData = [...tableData];
						newTableData.splice(rowIndex, 1);
						setTableData(newTableData);
					}
					return Promise.resolve();
				},
			}}
			options={{ paging: false, padding: 'dense', search: false, draggable: false, showTitle: false }}
			icons={{
				Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
				Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
				Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
				SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
				Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
				Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
				ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
			}}
		/>
	));
}

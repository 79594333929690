import React, { useState } from 'react';
import { Chip, Fab, TextField, Select, FormControl, InputLabel } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import { IDeviceTwinTags } from '@mitie/metadata-api-types';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			margin: theme.spacing(2),
			overflow: 'auto',
		},
		selectChip: {
			marginTop: theme.spacing(2),
			marginRight: theme.spacing(1),
			marginBottom: theme.spacing(1) / 2,
		},
		fab: {
			marginLeft: theme.spacing(1),
		},
	}),
);

interface IDeviceTwinTagsListProps {
	tags: IDeviceTwinTags;
	setTags: (tags: IDeviceTwinTags) => void;
}

export default function DeviceTwinTagsList({ tags, setTags }: IDeviceTwinTagsListProps) {
	const classes = useStyles();

	const [tagName, setTagName] = useState('');
	const [tagValue, setTagValue] = useState('' as string | number | boolean);
	const [datatype, setDatatype] = useState('string');

	return (
		<>
			{Object.entries(tags).map(([tag, value]) => (
				<Chip
					className={classes.selectChip}
					key={tag}
					label={`${tag} = ${value}`}
					onDelete={() => {
						if (tags[tag]) {
							const newTags = { ...tags };
							delete newTags[tag];
							setTags(newTags);
						}
					}}
				/>
			))}
			<div style={{ flexDirection: 'row', display: 'inline' }}>
				<TextField
					label="Tag name"
					style={{ marginLeft: '8px', marginRight: '8px' }}
					value={tagName}
					onChange={e => setTagName(e.currentTarget.value)}
				/>
				<FormControl>
					<InputLabel htmlFor="twin-tag-datatype">Type</InputLabel>
					<Select
						native={true}
						inputProps={{
							name: 'Type',
							id: 'twin-tag-datatype',
						}}
						value={datatype}
						onChange={e => {
							if (e.currentTarget.value === 'boolean') {
								setTagValue(true);
							} else if (e.currentTarget.value === 'number') {
								setTagValue(0);
							} else {
								setTagValue('');
							}

							setDatatype(e.currentTarget.value as string);
						}}
					>
						<option value="string">string</option>
						<option value="number">number</option>
						<option value="boolean">boolean</option>
					</Select>
				</FormControl>
				{datatype === 'boolean' ? (
					<FormControl>
						<InputLabel htmlFor="twin-tag-value">Value</InputLabel>
						<Select
							native={true}
							inputProps={{
								name: 'Value',
								id: 'twin-tag-value',
							}}
							value={tagValue ? 'true' : 'false'}
							onChange={e => {
								const value = e.currentTarget.value === 'true';
								setTagValue(value);
							}}
						>
							<option value="true">true</option>
							<option value="false">false</option>
						</Select>
					</FormControl>
				) : (
					<TextField
						label="Value"
						value={tagValue}
						onChange={e => {
							const value = datatype === 'number' ? parseFloat(e.currentTarget.value) : e.currentTarget.value;
							setTagValue(value);
						}}
					/>
				)}
			</div>
			<Fab
				size="small"
				disabled={!tagName || tagValue === '' || tags[tagName] !== undefined}
				color="secondary"
				className={classes.fab}
				onClick={() => {
					setTags({ ...tags, [tagName]: tagValue });

					setTagName('');

					if (datatype === 'boolean') {
						setTagValue(true);
					} else if (datatype === 'number') {
						setTagValue(0);
					} else {
						setTagValue('');
					}
				}}
			>
				<Add />
			</Fab>
		</>
	);
}

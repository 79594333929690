import React, { useState } from 'react';
import { useObserver } from 'mobx-react';
import { Typography, Tabs, Tab, LinearProgress } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { Status } from 'DataTypes';
import EntityProperty from './EntityProperty';
import TagsInput from './TagsInput';
import CustomPropertiesTable from './CustomPropertiesTable';
import DeviceTwinPropertiesTable from './DeviceTwinPropertiesTable';
import DeviceTwinTagsList from './DeviceTwinTagsList';
import DevicePointsDefinitionTable from './DevicePointsDefinitionTable';
import { DeviceTemplate } from 'store/deviceTemplate';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			margin: theme.spacing(2),
			overflow: 'auto',
		},
		selectChip: {
			marginRight: theme.spacing(1),
			marginBottom: theme.spacing(1) / 2,
		},
		propertyValue: {
			display: 'inline',
		},
		tabs: {
			borderBottom: `1px solid ${theme.palette.divider}`,
		},
		tab: {
			'&>span:first-child': {
				flexDirection: 'row',
			},
		},
		field: {
			marginRight: '1rem',
			minWidth: '25rem',
		},
	}),
);

interface IDeviceTemplateDetailsProps {
	template: DeviceTemplate;
}

enum TabName {
	Properties = 0,
	DeviceTwin = 1,
	DefaultDevicePoints = 2,
}

export default function DeviceTemplateDetails({ template }: IDeviceTemplateDetailsProps) {
	const classes = useStyles();

	const [selectedTab, setSelectedTab] = useState(TabName.Properties as TabName);

	return useObserver(() => (
		<div className={classes.root}>
			{template.dataRequest === Status.Loading && <LinearProgress />}
			{template.data && (
				<>
					<Tabs
						value={selectedTab}
						onChange={(event, value: TabName) => setSelectedTab(value)}
						variant="scrollable"
						scrollButtons="auto"
						className={classes.tabs}
					>
						<Tab value={TabName.Properties} label="Properties" className={classes.tab} />
						<Tab value={TabName.DeviceTwin} label="Device Twin" className={classes.tab} />
						<Tab value={TabName.DefaultDevicePoints} label="Default Device points" className={classes.tab} />
					</Tabs>
					{selectedTab === TabName.Properties && (
						<>
							<EntityProperty
								label="Template ID"
								type="string"
								value={template.id}
								onChange={value => (template.id = value as string)}
								className={classes.field}
								disabled={true}
							/>
							<EntityProperty
								label="Name"
								type="string"
								value={template.data?.name}
								onChange={value => template.data && (template.data.name = value as string)}
								className={classes.field}
							/>
							<TagsInput
								label="Tags"
								value={template.data.template.tags || []}
								onChange={newTags => template.data && (template.data.template.tags = newTags)}
								className={classes.field}
							/>
							<div>
								<Typography variant="body1">{`Last modified: ${template.modifiedTime?.toLocaleString()}`}</Typography>
							</div>
							<CustomPropertiesTable properties={template.data.template.properties} />
						</>
					)}
					{selectedTab === TabName.DeviceTwin && (
						<>
							<Typography variant="h6">Device twin tags</Typography>
							<DeviceTwinTagsList
								tags={template.data?.template.device_twin?.tags || {}}
								setTags={tags => {
									if (!template.data) {
										return;
									}

									if (!template.data.template.device_twin) {
										template.data.template.device_twin = { tags, properties: [] };
									} else {
										template.data.template.device_twin.tags = tags;
									}
								}}
							/>
							<DeviceTwinPropertiesTable template={template} />
						</>
					)}
					{selectedTab === TabName.DefaultDevicePoints && <DevicePointsDefinitionTable template={template} />}
				</>
			)}
		</div>
	));
}

import * as React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';

const mitiePurple = '#5B1F69';
// const mitieLight = '#d6c7d9';
// const mitieViolet = '#7A2182';
// const mitieMagenta = '#A90061';
const mitieRed = '#d71920';
const mitieOrange = '#fb4f14';
// const mitieNavy = '#002855';

const theme = createMuiTheme({
	palette: {
		primary: {
			light: mitieOrange,
			main: mitieRed,
			dark: mitiePurple,
			contrastText: '#FFFFFF',
		},
	},
});

interface IThemeProps {
	children: any;
}

export default function CustomTheme(props: IThemeProps) {
	return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}

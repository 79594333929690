import * as React from 'react';
import { TextField, InputAdornment, Button } from '@material-ui/core';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import Entity from 'store/entity';
import { observable, computed, autorun, IReactionDisposer } from 'mobx';
import { Status } from 'DataTypes';
import LocationSelectPopup from './LocationSelectPopup';

const styles = (theme: Theme) =>
	createStyles({
		input: {
			disabled: 'disabled',
		},
		container: {},
	});

interface ILocationSelectProps extends WithStyles<typeof styles> {
	entity: Entity;
	label: string;
	className: string;
	disabled: boolean;
	onChange: (entityId: string | null) => void;
}

@observer
class LocationSelect extends React.Component<ILocationSelectProps> {
	private disposables: IReactionDisposer[] = [];
	@observable private popupOpen = false;

	public componentDidMount() {
		this.disposables.push(
			autorun(() => {
				const { entity } = this.props;

				if (entity.locationParents) {
					for (const parent of entity.locationParents) {
						if (parent.dataRequest === Status.None) {
							parent.fetchData();
						}

						if (!parent.childrenRequest.location) {
							parent.fetchChildren('location');
						}
					}
				}
			}),
		);
	}

	public componentWillUnmount() {
		this.disposables.forEach(d => d());
	}

	@computed
	private get locationsList() {
		const { entity } = this.props;

		if (!entity.locationParents) {
			return [];
		}

		const locationsList = entity.locationParents.map(parent => {
			return parent.displayName;
		});

		return locationsList;
	}

	public render() {
		const { label, classes, className, disabled, entity, onChange } = this.props;

		return (
			<>
				<TextField
					label={label}
					margin="normal"
					variant="outlined"
					className={className}
					disabled={disabled}
					value={this.locationsList.join(' > ')}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Button onClick={() => (this.popupOpen = true)}>...</Button>
							</InputAdornment>
						),
						className: classes.input,
						disabled: true,
					}}
				/>
				{this.popupOpen && (
					<LocationSelectPopup
						location={entity.parentLocation}
						onClose={selected => {
							if (selected) {
								onChange(selected.id);
							}

							this.popupOpen = false;
						}}
					/>
				)}
			</>
		);
	}
}

export default withStyles(styles)(LocationSelect);

import * as React from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';

import { useStores } from 'store';
import { useObserver } from 'mobx-react';
import { IconButton } from '@material-ui/core';
import { ChevronLeft, ChevronRight, Close } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		navButtons: {
			marginRight: theme.spacing(2),
		},
	}),
);

export default function Notifications() {
	const classes = useStyles();
	const { notifications } = useStores();

	return useObserver(
		() =>
			notifications.displayedNotification && (
				<Alert
					severity={notifications.displayedNotification.type}
					action={
						<>
							{notifications.notificationsCount > 1 && (
								<div className={classes.navButtons}>
									<IconButton
										size="small"
										disabled={notifications.displayedNotificationIndex === 0}
										onClick={() => notifications.displayPreviousNotification()}
									>
										<ChevronLeft />
									</IconButton>
									{`${notifications.displayedNotificationIndex + 1} / ${notifications.notificationsCount}`}
									<IconButton
										size="small"
										disabled={notifications.displayedNotificationIndex === notifications.notificationsCount - 1}
										onClick={() => notifications.displayNextNotification()}
									>
										<ChevronRight />
									</IconButton>
								</div>
							)}
							<IconButton size="small" onClick={() => notifications.deleteDisplayedNotification()}>
								<Close />
							</IconButton>
						</>
					}
				>
					{notifications.displayedNotification.message}
				</Alert>
			),
	);
}

import { observable, action, computed, autorun, runInAction } from 'mobx';
import {
	IApiEntity,
	IApiEntityUpdate,
	IDeviceProperties,
	IDeviceStatus,
	RelationType,
	TemplateType,
	IDevicePointTemplate,
	EntityLifecycleStatus,
} from '@mitie/metadata-api-types';
import { cloneDeep } from 'lodash';
import { differenceInMinutes, formatRelative } from 'date-fns';
import { v4 as uuid } from 'uuid';

import * as ApiService from '../services/apiService';
import { logError } from '../errors';
import { Status, IDataPoint } from '../DataTypes';
import { deepEqual } from 'utils';
import { Root } from '.';
import { Template } from './template';

export interface IEntityData {
	name: string;
	properties: { [property: string]: string | number | boolean };
	tags: string[];
	relations: { [relation in RelationType]?: string };
	externalMappings: { [system: string]: { [property: string]: string | number | boolean } };
	templates: { [type in TemplateType]?: string };
	deviceProperties?: IDeviceProperties;
	deviceStatus?: IDeviceStatus;
	lifecycleStatus: EntityLifecycleStatus;
}

export default class Entity {
	@computed
	public get created() {
		return Boolean(this.data && !this.originalData);
	}

	@computed
	public get deleted() {
		return Boolean(!this.data && this.originalData);
	}

	@computed
	public get modified() {
		if (!this.data || !this.originalData) {
			return false;
		}

		const { deviceStatus, deviceProperties, ...dataToCompare } = this.data;
		const {
			deviceStatus: originalDeviceStatus,
			deviceProperties: originalDeviceProperties,
			...originalDataToCompare
		} = this.originalData;

		if (!deepEqual(dataToCompare, originalDataToCompare)) {
			return true;
		}

		const devicePropertiesStr = deviceProperties
			? JSON.stringify(
					Object.entries(deviceProperties)
						.filter(([key, value]) => value.desired !== undefined)
						.map(([key, value]) => [key, value.desired]),
			  )
			: '';
		const originalDevicePropertiesStr = originalDeviceProperties
			? JSON.stringify(
					Object.entries(originalDeviceProperties)
						.filter(([key, value]) => value.desired !== undefined)
						.map(([key, value]) => [key, value.desired]),
			  )
			: '';

		return devicePropertiesStr !== originalDevicePropertiesStr;
	}

	@computed
	public get unsaved() {
		return this.created || this.deleted || this.modified;
	}

	@computed
	public get childrenUnsavedCount() {
		if (!this.children.location) {
			return 0;
		}

		return this.children.location.reduce((acc, e) => {
			acc += e.childrenUnsavedCount;

			if (e.unsaved) {
				acc++;
			}

			return acc;
		}, 0);
	}

	@computed
	public get childrenDevicesUnsavedCount() {
		if (!this.children.gateway) {
			return 0;
		}

		return this.children.gateway.reduce((acc, e) => {
			acc += e.childrenDevicesUnsavedCount;

			if (e.unsaved) {
				acc++;
			}

			return acc;
		}, 0);
	}

	@computed
	public get parentDevice() {
		if (!this.data) {
			return undefined;
		}

		const parentId = this.data.relations.gateway;

		if (!parentId) {
			return undefined;
		}

		return this.rootStore.entities.addAndGetEntity(parentId);
	}

	@computed
	public get parentLocation() {
		if (!this.data) {
			return undefined;
		}

		const parentId = this.data.relations.location;

		if (!parentId) {
			return undefined;
		}

		return this.rootStore.entities.addAndGetEntity(parentId);
	}

	@computed
	public get locationParents() {
		if (!this.data) {
			return undefined;
		}

		const parents: Entity[] = [];

		for (let parent: Entity | undefined = this.parentLocation; parent !== undefined; parent = parent.parentLocation) {
			parents.unshift(parent);
		}

		return parents;
	}

	@computed
	public get displayName() {
		if (!this.data) {
			if (this.originalData) {
				return this.originalData.name;
			} else {
				return this.id;
			}
		}

		return this.data.name;
	}

	@computed
	public get deviceOnline() {
		if (!this.data || !this.data.deviceStatus) {
			return undefined;
		}

		if (!this.lastTelemetryTime) {
			return false;
		} else {
			return differenceInMinutes(new Date(), new Date(this.lastTelemetryTime)) < 180;
		}
	}

	@computed
	public get lastTelemetryTime() {
		if (!this.data) {
			return null;
		}

		const lastTelemetryTime = this.getDeviceProperty('lastTelemetryTime');

		if (!lastTelemetryTime || typeof lastTelemetryTime.reported !== 'string') {
			return null;
		}

		return lastTelemetryTime.reported;
	}

	@computed
	public get deviceStatusString() {
		if (!this.data || !this.data.deviceStatus) {
			return '';
		}

		function parseDeviceDateRelative(dateString: string) {
			const now = new Date();

			if (dateString === '0001-01-01T00:00:00Z') {
				return 'Never';
			} else {
				return formatRelative(new Date(dateString), now);
			}
		}

		let statusString = '';
		const {
			lastActivityTime,
			status,
			statusReason,
			statusUpdatedTime,
			connectionState,
			connectionStateUpdatedTime,
		} = this.data.deviceStatus;

		if (lastActivityTime) {
			statusString += `Last activity time: ${parseDeviceDateRelative(lastActivityTime)}`;
		}

		if (this.lastTelemetryTime) {
			statusString += statusString ? '\n' : '';
			statusString += `Time of last telemetry: ${parseDeviceDateRelative(this.lastTelemetryTime)}`;
		}

		if (connectionState) {
			statusString += statusString ? '\n' : '';
			statusString += `Connection state: ${connectionState}`;
		}

		if (connectionStateUpdatedTime) {
			statusString += statusString ? '\n' : '';
			statusString += `Connection state updated time: ${parseDeviceDateRelative(connectionStateUpdatedTime)}`;
		}

		if (status) {
			statusString += statusString ? '\n' : '';
			statusString += `Status: ${status}`;
		}

		if (statusReason) {
			statusString += statusString ? '\n' : '';
			statusString += `Status reason: ${statusReason}`;
		}

		if (statusUpdatedTime) {
			statusString += statusString ? '\n' : '';
			statusString += `Status updated time: ${parseDeviceDateRelative(statusUpdatedTime)}`;
		}

		return statusString;
	}

	@computed
	public get isPoint() {
		if (this.data && this.data.tags.includes('point')) {
			return true;
		}

		if (this.originalData && this.originalData.tags.includes('point')) {
			return true;
		}

		return false;
	}

	@computed
	public get isDevice() {
		if (this.data && this.data.tags.includes('device')) {
			return true;
		}

		return false;
	}

	@computed
	public get isLocation() {
		if (this.data && this.data.tags.includes('location')) {
			return true;
		}

		return false;
	}

	@computed
	public get isEquip() {
		if (this.data && this.data.tags.includes('equip')) {
			return true;
		}

		return false;
	}

	public id: string;
	@observable public updatedTime?: Date;
	@observable public data?: IEntityData;
	@observable public children: { [relation in RelationType]?: Entity[] } = {};
	@observable public dataRequest = Status.None;
	@observable public childrenRequest: { [relation in RelationType]?: Status } = {};
	@observable public saveRequest = Status.None;
	@observable private originalData?: IEntityData;
	private deletedRelations: { [relation in RelationType]?: string } = {}; // deletedRelations stores the relation when an entity is deleted but not saved, so that it still appears in the tree
	private rootStore: Root;

	constructor(rootStore: Root, id: string) {
		this.id = id;

		this.rootStore = rootStore;

		autorun(() => {
			if (this.unsaved) {
				this.rootStore.entities.unsavedList[this.id] = this;
			} else {
				delete this.rootStore.entities.unsavedList[this.id];
			}
		});

		autorun(() => {
			// Fetch data for related entities
			const { entities } = this.rootStore;

			if (!this.data) {
				return;
			}

			for (const relationName of Object.keys(this.data.relations)) {
				const parentId = this.data.relations[relationName as RelationType];

				if (parentId) {
					const entity = entities.addAndGetEntity(parentId);

					if (!entity.created && entity.dataRequest === Status.None) {
						entity.fetchData();
					}
				}
			}
		});
	}

	public getDeviceProperty(propName: string) {
		if (!this.data || !this.data.deviceProperties) {
			return;
		}

		const prop = this.data.deviceProperties[propName];

		if (prop === undefined) {
			return;
		}

		return prop;
	}

	public getProperty(propName: string) {
		if (!this.data || !this.data.properties) {
			return;
		}

		const prop = this.data.properties[propName];

		if (prop === undefined) {
			return;
		}

		return prop;
	}

	public getExternalMapping(templateId: string, propName: string) {
		if (!this.data || !this.data.externalMappings[templateId]) {
			return;
		}

		const prop = this.data.externalMappings[templateId][propName];

		if (prop === undefined) {
			return;
		}

		return prop;
	}

	@action.bound
	public setExternalMapping(templateId: string, propName: string, value: string | number | boolean) {
		if (!this.data || !this.data.externalMappings[templateId]) {
			return;
		}

		this.data.externalMappings[templateId][propName] = value;
	}

	@action.bound
	public setDataFromApi(entity: IApiEntity, override: boolean = false) {
		this.originalData = this.extractDetails(entity);
		this.updatedTime = new Date(entity.modified_time);
		const existingRelations = this.data ? this.data.relations : {};

		if (override || !this.data) {
			this.data = cloneDeep(this.originalData);
		}

		// Remove entity from existing related objects, if the relation has been removed
		for (const existingRelation of Object.keys(existingRelations)) {
			const typedExistingRelation = existingRelation as RelationType;

			if (!entity.relations[typedExistingRelation]) {
				this.removeRelation(typedExistingRelation);
			}
		}

		// Add entity to related objects
		for (const relation of Object.keys(entity.relations)) {
			const typedRelation = relation as RelationType;
			const relatedEntityId = entity.relations[typedRelation] as string;
			this.setRelation(typedRelation, relatedEntityId);
		}

		this.dataRequest = Status.Done;
	}

	@action.bound
	public setDataFromUi(entity: IApiEntityUpdate) {
		const entitiesStore = this.rootStore.entities;
		const existingRelations = this.data ? this.data.relations : {};

		const {
			name,
			properties,
			tags,
			externalMappings,
			deviceProperties,
			relations,
			templates,
			lifecycleStatus,
		} = this.extractDetails(entity);

		// Update everything except templates, relations, and device properties
		if (!this.data) {
			this.data = {
				name,
				properties,
				tags,
				externalMappings,
				relations: {},
				templates: {},
				lifecycleStatus,
			};
		} else {
			this.data.name = name;
			this.data.properties = properties;
			this.data.tags = tags;
			this.data.externalMappings = externalMappings;
		}

		// Remove entity from existing related objects, if the relation has been removed
		for (const existingRelation of Object.keys(existingRelations)) {
			const typedExistingRelation = existingRelation as RelationType;

			if (!relations[typedExistingRelation]) {
				this.removeRelation(typedExistingRelation);
			}
		}

		// Add entity to related objects
		for (const relation of Object.keys(relations)) {
			const typedRelation = relation as RelationType;
			const relatedEntityId = relations[typedRelation] as string;
			this.setRelation(typedRelation, relatedEntityId);
		}

		// Apply templates. Needs to be after relations are updated as some templates require the parent entity
		if (templates.device) {
			this.setDeviceTemplate(templates.device);
		}

		if (templates.entity) {
			this.setEntityTemplate(templates.entity);
		}

		if (templates.device_point) {
			this.data.templates.device_point = templates.device_point;
		}

		if (templates.entity_point) {
			this.data.templates.entity_point = templates.entity_point;
		}

		if (templates.external_system) {
			this.data.templates.external_system = templates.external_system;
		}

		// Update device properties (this might override the default device properties set from the template)
		if (deviceProperties) {
			if (!this.data.deviceProperties) {
				this.data.deviceProperties = {};
			}

			for (const propName of Object.keys(deviceProperties)) {
				const deviceProp = deviceProperties[propName];

				const existingProp = this.data.deviceProperties[propName];

				if (!existingProp) {
					this.data.deviceProperties[propName] = deviceProp;
				} else {
					existingProp.desired = deviceProp.desired;
				}
			}
		}

		if (tags.includes('client')) {
			// add to list of clients
			if (!entitiesStore.clients.find(c => c.id === entity.id)) {
				entitiesStore.clients.push(this);
			}
		}

		if (tags.includes('device') && !relations.gateway) {
			// add to list of root devices
			if (!entitiesStore.rootDevices.find(c => c.id === entity.id)) {
				entitiesStore.rootDevices.push(this);
			}
		}

		if (!relations.location) {
			// add to list of orphaned entities
			if (!entitiesStore.orphanedEntities.find(c => c.id === entity.id)) {
				entitiesStore.orphanedEntities.push(this);
			}
		}
	}

	@action.bound
	public async fetchChildren(relationType: RelationType) {
		this.childrenRequest[relationType] = Status.Loading;
		this.rootStore.entities.childrenToFetch[relationType].push(this.id);
	}

	@action.bound
	public discardChanges() {
		const updatedRelations = this.data ? this.data.relations : {};

		if (this.originalData) {
			const originalRelations = this.originalData.relations;

			// update children of new / old parent entities if necessary
			for (const relation of Object.keys(originalRelations)) {
				if (originalRelations[relation] !== updatedRelations[relation]) {
					const originalEntity = this.rootStore.entities.getEntity(originalRelations[relation] as string);

					if (originalEntity) {
						originalEntity.addChild(this, relation as RelationType);
					}

					if (updatedRelations[relation]) {
						const updatedEntity = this.rootStore.entities.getEntity(updatedRelations[relation] as string);

						if (updatedEntity) {
							updatedEntity.removeChild(this, relation as RelationType);
						}
					}
				}
			}

			for (const relation of Object.keys(updatedRelations)) {
				if (!originalRelations[relation]) {
					const typedRelation = relation as RelationType;
					const parentId = updatedRelations[typedRelation];

					if (parentId) {
						const updatedEntity = this.rootStore.entities.addAndGetEntity(parentId);

						if (updatedEntity) {
							updatedEntity.removeChild(this, relation as RelationType);
						}
					}
				}
			}

			// Revert entity
			this.data = cloneDeep(this.originalData);

			// Revert child points
			if (this.children.device) {
				this.children.device.forEach(point => point.discardChanges());
			}

			if (this.children.entity) {
				this.children.entity.forEach(point => point.discardChanges());
			}

			delete this.rootStore.entities.unsavedList[this.id];
		} else {
			// Remove this entity from its parents
			for (const relation of Object.keys(updatedRelations)) {
				const typedRelation = relation as RelationType;
				const parentId = updatedRelations[typedRelation];

				if (parentId) {
					const parent = this.rootStore.entities.addAndGetEntity(parentId);

					if (parent) {
						parent.removeChild(this, typedRelation);
					}
				}
			}

			this.rootStore.entities.deleteEntity(this);
		}
	}

	@action.bound
	public delete() {
		if (!this.data) {
			return;
		}

		this.deletedRelations = this.data.relations; // stores the relations so the parents can be updated when the entity is saved
		this.data = undefined;

		// Delete children points
		if (this.children.device) {
			this.children.device.forEach(point => point.delete());
		}

		if (this.children.entity) {
			this.children.entity.forEach(point => point.delete());
		}
	}

	@action.bound
	public async save() {
		if (this.unsaved) {
			this.saveRequest = Status.Loading;

			try {
				if (this.created) {
					const data: IApiEntity = await ApiService.post(
						`${process.env.REACT_APP_API_URL}/metadata/secure/entities`,
						this.parseForApi(),
					);
					this.setDataFromApi(data, true);
				} else if (this.deleted) {
					await ApiService.delete_(`${process.env.REACT_APP_API_URL}/metadata/secure/entities/${this.id}`);

					// Remove this entity from its parents
					for (const relation of Object.keys(this.deletedRelations)) {
						const typedRelation = relation as RelationType;
						const parentId = this.deletedRelations[typedRelation];

						if (parentId) {
							const parent = this.rootStore.entities.addAndGetEntity(parentId);

							if (parent) {
								parent.removeChild(this, typedRelation);
							}
						}
					}

					this.rootStore.entities.deleteEntity(this);
					this.dataRequest = Status.Done;
				} else {
					const data: IApiEntity = await ApiService.put(
						`${process.env.REACT_APP_API_URL}/metadata/secure/entities/${this.id}`,
						this.parseForApi(),
					);
					this.setDataFromApi(data, true);
				}

				if (this.children.device) {
					await Promise.all(this.children.device.map(point => point.save()));
				}

				if (this.children.entity) {
					await Promise.all(this.children.entity.map(point => point.save()));
				}

				this.saveRequest = Status.Done;
			} catch (error) {
				logError(error);
				this.saveRequest = Status.Error;
			}
		}
	}

	@action.bound
	public async fetchData() {
		this.dataRequest = Status.Loading;
		this.rootStore.entities.entitiesToFetch.push(this.id);
	}

	@action.bound
	public addChild(entity: Entity, relationType: RelationType) {
		if (!this.children[relationType]) {
			this.children[relationType] = [];
		}

		const children = this.children[relationType]!;

		if (!children.find(e => e.id === entity.id)) {
			children.push(entity);

			this.children[relationType] = children.slice().sort((a, b) => {
				if (!a.data) {
					return 1;
				}

				if (!b.data) {
					return -1;
				}

				if (a.data.name < b.data.name) {
					return -1;
				}
				if (a.data.name > b.data.name) {
					return 1;
				}

				return 0;
			});
		}
	}

	@action.bound
	public removeChild(entity: Entity, relationType: RelationType) {
		if (!this.children[relationType]) {
			return;
		}

		if (this.children[relationType]!.find(e => e.id === entity.id)) {
			this.children[relationType] = this.children[relationType]!.filter(e => e.id !== entity.id);
		}
	}

	public parseForApi() {
		if (!this.data) {
			return;
		}

		const data = {
			id: this.id,
			name: this.data.name,
			properties: this.data.properties,
			tags: this.data.tags,
			relations: this.data.relations,
			external_mappings: this.data.externalMappings,
			templates: this.data.templates,
			device_properties: this.data.deviceProperties,
			lifecycle_status: this.data.lifecycleStatus,
		} as IApiEntityUpdate;

		return data;
	}

	@action.bound
	public setRelation(relation: RelationType, entityId: string) {
		if (!this.data) {
			return;
		}

		// Remove entity from existing parent
		const existingParent = this.data.relations[relation];

		if (existingParent) {
			const parentEntity = this.rootStore.entities.getEntity(existingParent);

			if (parentEntity && parentEntity.data) {
				parentEntity.removeChild(this, relation);
			}
		}

		// Add entity to new parent and update relation on entity
		const newParentEntity = this.rootStore.entities.addAndGetEntity(entityId);
		newParentEntity.addChild(this, relation);
		this.data.relations[relation] = entityId;
	}

	@action.bound
	public removeRelation(relation: RelationType) {
		if (!this.data) {
			return;
		}

		// Remove entity from existing parent
		const existingParent = this.data.relations[relation];

		if (existingParent) {
			const parentEntity = this.rootStore.entities.getEntity(existingParent);

			if (parentEntity && parentEntity.data) {
				parentEntity.removeChild(this, relation);
			}
		}

		// Remove relation from entity
		delete this.data.relations[relation];
	}

	@action.bound
	public setDeviceDesiredProperty(propertyName: string, value: string | boolean | number | null) {
		if (!this.data) {
			return;
		}

		if (typeof value === 'number' && Number.isNaN(value)) {
			value = null;
		}

		if (!this.data.deviceProperties) {
			this.data.deviceProperties = {};
		}

		if (!this.data.deviceProperties[propertyName]) {
			this.data.deviceProperties[propertyName] = { desired: value };
		} else {
			this.data.deviceProperties[propertyName].desired = value;
		}
	}

	@action.bound
	public setDeviceTemplate(templateId: string) {
		if (!this.data) {
			return;
		}

		const template = (templateId !== '' && this.rootStore.deviceTemplates.getTemplate(templateId)) || undefined;

		if (!template) {
			this.data.deviceProperties = {};
			this.data.templates.device = undefined;
		} else {
			this.data.templates.device = templateId;

			if (template.data?.template.properties) {
				for (const prop of template.data.template.properties) {
					if (prop.default && !this.data.properties[prop.name]) {
						this.data.properties[prop.name] = prop.default;
					}
				}
			}

			if (template.data?.template.tags) {
				for (const tag of template.data.template.tags) {
					if (!this.data.tags.includes(tag)) {
						this.data.tags.push(tag);
					}
				}
			}

			if (!template.data?.template.device_twin) {
				this.data.deviceProperties = undefined;
			} else {
				this.data.deviceProperties = template.data.template.device_twin.properties.reduce(
					(acc: IDeviceProperties, prop) => {
						let desired = prop.default;
						const parentDeviceId = this.data!.relations.gateway;

						if (parentDeviceId && prop.inheritedDefault) {
							const parent = this.rootStore.entities.getEntity(parentDeviceId);

							if (parent) {
								const [type, propName] = prop.inheritedDefault.split('.');

								if (type === 'deviceProperties') {
									const inheritedProp = parent.getDeviceProperty(propName);

									if (inheritedProp && inheritedProp.desired) {
										desired = inheritedProp.desired;
									}
								} else if (type === 'properties') {
									const inheritedProp = parent.getProperty(propName);

									if (inheritedProp !== undefined) {
										desired = inheritedProp;
									}
								}
							}
						}

						acc[prop.name] = { desired };

						return acc;
					},
					{},
				);
			}
		}

		runInAction(() => {
			// Add device points for this device
			const existingDevicePoints = this.children.device || [];
			const newDevicePointsTemplateIds = (template && template.data?.template.default_points_templates) || [];
			const newDevicePointsTemplates = newDevicePointsTemplateIds.reduce(
				(acc: Array<Template<IDevicePointTemplate>>, pointTemplateId) => {
					const t = this.rootStore.devicePointTemplates.getTemplate(pointTemplateId);

					if (t) {
						acc.push(t);
					}

					return acc;
				},
				[],
			);

			// Points to remove are points with device_point template that are not in new device template
			const devicePointsToRemove = existingDevicePoints.filter(p => {
				if (!p.data) {
					return false;
				}

				return p.data.templates.device_point && !newDevicePointsTemplateIds.includes(p.data.templates.device_point);
			});

			for (const e of devicePointsToRemove) {
				e.delete();
			}

			// Points to add are point in the new device template with no matching point
			const devicePointsTemplatesToAdd = newDevicePointsTemplates.filter(pointTemplate => {
				return !existingDevicePoints.find(p => Boolean(p.data && p.data.templates.device_point === pointTemplate.id));
			});

			for (const devicePointTemplate of devicePointsTemplatesToAdd) {
				if (devicePointTemplate.data?.template) {
					const entityId = uuid();
					const { metric_name, units, datatype } = devicePointTemplate.data.template.properties;
					const { name } = devicePointTemplate.data;
					const newDevicePoint: IApiEntityUpdate = {
						id: entityId,
						name,
						properties: {
							metric_name,
							datatype,
						},
						tags: ['point', ...devicePointTemplate.data.template.tags],
						relations: {
							device: this.id,
						},
						external_mappings: {},
						templates: {
							device_point: devicePointTemplate.id,
						},
						lifecycle_status: 'Configuration',
					};

					if (units) {
						newDevicePoint.properties.units = units;
					}

					this.rootStore.entities.addEntitiesFromUi([newDevicePoint]);
				}
			}

			for (const devicePointTemplate of newDevicePointsTemplates) {
				if (devicePointTemplate.data?.template.default_binding) {
					const defaultBinding = devicePointTemplate.data.template.default_binding;
					const relatedEntityId = this.data!.relations[defaultBinding.entity_relation];

					if (relatedEntityId) {
						const relatedEntity = this.rootStore.entities.getEntity(relatedEntityId);

						if (
							relatedEntity &&
							relatedEntity.data &&
							relatedEntity.data.templates.entity === defaultBinding.entity_template
						) {
							const childrenEntityPoints = relatedEntity.children.entity;

							if (
								!childrenEntityPoints ||
								!childrenEntityPoints.find(c => c.data!.templates.entity_point === defaultBinding.entity_point_template)
							) {
								const entityPointTemplate = this.rootStore.entityPointTemplates.getTemplate(
									defaultBinding.entity_point_template,
								);

								if (entityPointTemplate?.data) {
									const newEntityPoint: IApiEntityUpdate = {
										id: uuid(),
										name: entityPointTemplate.data.name,
										properties: {},
										tags: [...entityPointTemplate.data.template.tags],
										relations: {
											entity: relatedEntityId,
										},
										external_mappings: {},
										templates: {
											entity_point: defaultBinding.entity_point_template,
										},
										lifecycle_status: 'Configuration',
									};

									this.rootStore.entities.addEntitiesFromUi([newEntityPoint]);
								}
							}
						}
					}
				}
			}
		});
	}

	@action.bound
	public setEntityTemplate(templateId: string) {
		if (!this.data) {
			return;
		}

		const template = (templateId !== '' && this.rootStore.entityTemplates.getTemplate(templateId)) || undefined;

		if (!template) {
			this.data.templates.entity = undefined;
		} else {
			this.data.templates.entity = templateId;

			if (template.data?.template.properties) {
				for (const prop of template.data.template.properties) {
					if (prop.default && !this.data.properties[prop.name]) {
						this.data.properties[prop.name] = prop.default;
					}
				}
			}

			if (template.data?.template.tags) {
				for (const tag of template.data.template.tags) {
					if (!this.data.tags.includes(tag)) {
						this.data.tags.push(tag);
					}
				}
			}
		}

		const existingEntityPoints = this.children.entity || [];
		const newEntityPointsTemplateIds = (template && template.data?.template.points_templates) || [];

		// Unlink points with entity_point template that are not in new entity template
		const pointsToUnlink = existingEntityPoints.filter(p => {
			if (!p.data) {
				return false;
			}

			return p.data.templates.entity_point && !newEntityPointsTemplateIds.includes(p.data.templates.entity_point);
		});

		for (const point of pointsToUnlink) {
			this.removeChild(point, 'entity');

			if (point.data) {
				delete point.data.relations.entity;
				delete point.data.templates.entity_point;
			}
		}

		this.data.templates.entity = templateId;
	}

	public async fetchTelemetry(startDate: Date, endDate: Date) {
		const data: any[] = await ApiService.post(`${process.env.REACT_APP_API_URL}/telemetry/api/telemetry/byIds`, {
			ids: [this.id],
			startDate: startDate.toISOString(),
			endDate: endDate.toISOString(),
		});

		const parsedData: IDataPoint[] = data[0].data;

		return parsedData;
	}

	private extractDetails({
		name,
		tags,
		properties,
		relations,
		external_mappings,
		templates,
		device_properties,
		device_status,
		lifecycle_status,
	}: IApiEntity | IApiEntityUpdate) {
		const data: IEntityData = {
			name,
			properties,
			relations,
			tags,
			externalMappings: external_mappings,
			deviceProperties: device_properties,
			deviceStatus: device_status,
			templates: {},
			lifecycleStatus: lifecycle_status,
		};

		if (templates) {
			data.templates = templates;
		}

		return data;
	}
}

import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { LinearProgress } from '@material-ui/core';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { observable, IReactionDisposer, autorun } from 'mobx';

import Entities from '../store/entities';
import Routes from '../store/routes';
import { Status } from 'DataTypes';
import TreeItem from './TreeItem';

const styles = (theme: Theme) =>
	createStyles({
		container: {
			overflow: 'auto',
			padding: theme.spacing(1),
		},
		unsavedCount: {
			height: '1rem',
			lineHeight: '1rem',
			padding: '0.125rem 0.5rem',
			display: 'inline',
			background: theme.palette.secondary.main,
			borderRadius: '0.75rem',
			color: theme.palette.secondary.contrastText,
			marginTop: '13px',
			border: `1px solid ${theme.palette.secondary.dark}`,
		},
		titleText: {
			lineHeight: '48px',
			margin: '0 0.5rem',
		},
		topBar: {
			display: 'flex',
		},
		grow: {
			flexGrow: 1,
		},
		moreMenu: {
			zIndex: 2,
		},
	});

interface ILocationsTreeProps extends WithStyles<typeof styles> {}

@inject('entities', 'routes')
@observer
class LocationsTree extends React.Component<ILocationsTreeProps> {
	private disposables: IReactionDisposer[] = [];
	@observable private expandedChildren: string[] = [];

	private get injected() {
		return (this.props as any) as {
			routes: Routes;
			entities: Entities;
		};
	}

	public componentDidMount() {
		this.disposables.push(
			autorun(() => {
				// Makes sure that the parents of the selected entity are always expanded
				const {
					entities,
					routes: { parentEntityIds },
				} = this.injected;

				if (parentEntityIds && parentEntityIds.length > 0) {
					for (const client of entities.clients) {
						if (parentEntityIds.includes(client.id) && !this.expandedChildren.includes(client.id)) {
							this.expandedChildren.push(client.id);
						}
					}
				}
			}),
		);
	}

	public componentWillUnmount() {
		this.disposables.forEach(d => d());
	}

	public render() {
		const { classes } = this.props;
		const { entities } = this.injected;

		return (
			<div className={classes.container}>
				{entities.clientsFetchStatus === Status.Loading ? (
					<LinearProgress />
				) : (
					entities.clients.map(e => {
						return (
							<TreeItem
								key={e.id}
								childrenRelationType="location"
								entity={e}
								expanded={this.expandedChildren.includes(e.id)}
								onExpand={() => this.expandedChildren.push(e.id)}
								onCollapse={() => (this.expandedChildren = this.expandedChildren.filter(id => id !== e.id))}
							/>
						);
					})
				)}
			</div>
		);
	}
}

export default withStyles(styles)(LocationsTree);

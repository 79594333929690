import * as React from 'react';
import { useObserver } from 'mobx-react';
import { Typography, Divider, Tooltip, IconButton } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { RemoveCircle, Undo, Save } from '@material-ui/icons';
import { TemplateType } from '@mitie/metadata-api-types';
import classNames from 'classnames';

import DeviceTemplateDetails from './DeviceTemplateDetails';
import EntityTemplateDetails from './EntityTemplateDetails';
import IntegrationTemplateDetails from './IntegrationTemplateDetails';
import { DeviceTemplate } from 'store/deviceTemplate';
import { EntityTemplate } from 'store/entityTemplate';
import { ExternalSystemTemplate } from 'store/externalSystemTemplate';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
		},
		marginRight: {
			marginRight: theme.spacing(4),
		},
		titleText: {
			lineHeight: '48px',
			margin: '0 0.5rem',
		},
		grow: {
			flexGrow: 1,
		},
		topBar: {
			display: 'flex',
		},
		badge: {
			height: '1rem',
			lineHeight: '1rem',
			padding: '0.125rem 0.5rem',
			display: 'inline',
			borderRadius: '0.75rem',
			marginTop: '13px',
		},
		unsavedbadge: {
			background: theme.palette.secondary.main,
			color: theme.palette.secondary.contrastText,
		},
		iconsDivider: {
			width: '1px',
			backgroundColor: theme.palette.grey[400],
			height: '48px',
			margin: '0 12px',
		},
	}),
);

interface ITemplateDetailsProps {
	template: DeviceTemplate | EntityTemplate | ExternalSystemTemplate;
	templateType: TemplateType;
}

export default function TemplateDetails({ template, templateType }: ITemplateDetailsProps) {
	const classes = useStyles();

	return useObserver(() => {
		return (
			<div className={classes.root}>
				<div className={classes.topBar}>
					<Typography variant="h6" className={classes.titleText}>
						Template details
					</Typography>
					{template.unsaved && (
						<Typography variant="caption" className={classNames([classes.badge, classes.unsavedbadge])}>
							{template.created ? 'New' : template.deleted ? 'Deleted' : 'Modified'}
						</Typography>
					)}
					<div className={classes.grow} />
					{!template.created && !template.deleted && (
						<Tooltip title="Delete template" placement="bottom">
							<IconButton color="default" onClick={() => template.delete()}>
								<RemoveCircle />
							</IconButton>
						</Tooltip>
					)}
					{template.unsaved && (
						<>
							<Tooltip title="Discard changes" placement="bottom">
								<IconButton color="default" onClick={() => template.discardChanges()}>
									<Undo />
								</IconButton>
							</Tooltip>
							<div className={classes.iconsDivider} />
							<Tooltip title="Save template" placement="bottom">
								<IconButton color="secondary" onClick={() => template.save()}>
									<Save />
								</IconButton>
							</Tooltip>
						</>
					)}
				</div>
				<Divider />
				{template.data && templateType === 'device' && <DeviceTemplateDetails template={template as DeviceTemplate} />}
				{template.data && templateType === 'entity' && <EntityTemplateDetails template={template as EntityTemplate} />}
				{template.data && templateType === 'external_system' && (
					<IntegrationTemplateDetails template={template as ExternalSystemTemplate} />
				)}
			</div>
		);
	});
}

import * as React from 'react';
import { State, Options } from 'router5';

import RouteStore, { IFilters } from '../store/routes';
import Entities from 'pages/Entities';
import Templates from 'pages/Templates';
import Replay from 'pages/Replay';

export interface ILinkData {
	name?: string;
	params?: IFilters;
}

export interface IRoute {
	component?: (next?: Record<string, any>) => any;
	activate?: (store: RouteStore, current?: Record<string, any>, prev?: State) => void;
	deactivate?: (store: RouteStore, current?: Record<string, any>, next?: State) => void;
	path: string;
	defaultParams?: Record<string, any>;
	forwardTo?: string;
	name?: string;
	label?: string;
}

export interface IRoutes {
	[name: string]: IRoute;
}

export const routes: IRoutes = {
	default: {
		path: '/',
		forwardTo: 'entities',
	},
	entities: {
		path: '/entities/:browseBy',
		component: () => <Entities />,
		label: 'Manage entities',
		defaultParams: {
			browseBy: 'location',
		},
	},
	templates: {
		path: '/templates',
		component: () => <Templates />,
		label: 'Manage templates',
	},
	replay: {
		path: '/replay',
		component: () => <Replay />,
		label: 'Data replay',
	},
};

export const options: Partial<Options> = {
	queryParamsMode: 'loose',
	defaultRoute: 'entities',
};

import * as React from 'react';
import { TextField } from '@material-ui/core';
import classNames from 'classnames';

interface IEntityPropertyProps {
	value?: string | number | boolean;
	label: string;
	type: 'string' | 'number' | 'boolean';
	select?: Array<{ value: number | string; label: string }>;
	className: string;
	disabled?: boolean;
	onChange?: (value: string | number | boolean) => void;
}

export default class EntityProperty extends React.Component<IEntityPropertyProps> {
	public render() {
		const { label, type, className, onChange, select, value, disabled } = this.props;
		const parsedValue = this.parseInputValue(value);

		if (type === 'boolean') {
			return (
				<TextField
					value={parsedValue}
					select={true}
					label={label}
					type={type}
					onChange={e => onChange && onChange(this.parseValue(e.target.value))}
					margin="normal"
					variant="outlined"
					className={classNames([className])}
					disabled={disabled}
					SelectProps={{
						native: true,
					}}
					InputLabelProps={{
						shrink: true,
					}}
				>
					<option value={''}>No</option>
					<option value={'1'}>Yes</option>
				</TextField>
			);
		} else if (select) {
			return (
				<TextField
					value={parsedValue}
					select={true}
					label={label}
					type={type}
					onChange={e => onChange && onChange(this.parseValue(e.target.value))}
					margin="normal"
					variant="outlined"
					className={classNames([className])}
					disabled={disabled}
					SelectProps={{
						native: true,
					}}
					InputLabelProps={{
						shrink: true,
					}}
				>
					{select.map(({ value: v, label: l }) => (
						<option key={v} value={v}>
							{l}
						</option>
					))}
				</TextField>
			);
		} else {
			return (
				<TextField
					value={parsedValue}
					label={label}
					type={type}
					onChange={e => onChange && onChange(this.parseValue(e.target.value))}
					margin="normal"
					variant="outlined"
					className={classNames([className])}
					disabled={disabled}
				/>
			);
		}
	}

	private parseValue(value: string) {
		const { type } = this.props;

		switch (type) {
			case 'boolean':
				return !!value;
			case 'number':
				return parseFloat(value);
			default:
				return value;
		}
	}

	private parseInputValue(value?: string | number | boolean) {
		const { type } = this.props;

		switch (type) {
			case 'boolean':
				return value ? '1' : '';
			case 'number':
				return Number(value);
			default:
				return value || '';
		}
	}
}

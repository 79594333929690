import * as React from 'react';
import { WithStyles, createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { RelationType } from '@mitie/metadata-api-types';

import Entity from 'store/entity';
import LocationSelect from './LocationSelect';
import RelationSelect from './RelationSelect';

const styles = (theme: Theme) =>
	createStyles({
		field: {
			marginRight: '1rem',
			minWidth: '25rem',
		},
	});

interface IRelationsProps extends WithStyles<typeof styles> {
	entity: Entity;
	loading: boolean;
}

@observer
class Relations extends React.Component<IRelationsProps> {
	@computed
	private get displayLocation() {
		if (!this.props.entity.data) {
			return false;
		}

		return !this.props.entity.data.tags.includes('client');
	}

	@computed
	private get displayClient() {
		if (!this.props.entity.data) {
			return false;
		}

		return !this.props.entity.data.tags.includes('client');
	}

	@computed
	private get displayGateway() {
		if (!this.props.entity.data) {
			return false;
		}

		return this.props.entity.data.tags.includes('device');
	}

	@computed
	private get displayEquip() {
		if (!this.props.entity.data) {
			return false;
		}

		return this.props.entity.data.tags.includes('equip');
	}

	public render() {
		const { entity, loading, classes } = this.props;

		return (
			<>
				{this.displayLocation && (
					<LocationSelect
						label="Location"
						entity={entity}
						className={classes.field}
						disabled={loading}
						onChange={id => this.updateRelation('location', id)}
					/>
				)}
				{this.displayClient && (
					<RelationSelect
						label="Client"
						relationType="client"
						entity={entity}
						className={classes.field}
						disabled={loading}
						optional={false}
						onChange={id => this.updateRelation('client', id)}
					/>
				)}
				{this.displayGateway && (
					<RelationSelect
						label="Gateway"
						relationType="gateway"
						entity={entity}
						className={classes.field}
						disabled={loading}
						optional={true}
						onChange={id => this.updateRelation('gateway', id)}
					/>
				)}
				{this.displayEquip && (
					<RelationSelect
						label="Parent equip"
						relationType="equip"
						entity={entity}
						className={classes.field}
						disabled={loading}
						optional={true}
						onChange={id => this.updateRelation('equip', id)}
					/>
				)}
			</>
		);
	}

	private updateRelation = (relation: RelationType, entityId: string | null) => {
		const { entity } = this.props;

		if (!entity) {
			return;
		}

		if (entityId) {
			entity.setRelation(relation, entityId);
		} else {
			entity.removeRelation(relation);
		}
	};
}

export default withStyles(styles)(Relations);

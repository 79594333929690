import React, { useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Select,
	FormControlLabel,
	Checkbox,
	TextField,
	FormControl,
	InputLabel,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ITaskDefinition, ITaskParameters } from '@mitie/telemetry-api-types';

import { Status } from 'DataTypes';
import { handleError } from 'errors';
import * as ApiService from '../services/apiService';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {},
		input: {
			margin: theme.spacing(1),
			display: 'block',
		},
	}),
);

interface ICreateTaskDialogProps {
	tasksDefinition: ITaskDefinition[];
	onClose: () => void;
}

function createTask(orchestrator: string, parameters: ITaskParameters) {
	return ApiService.post<void>(`${process.env.REACT_APP_API_URL}/telemetry/api/tasks/orchestrator/${orchestrator}`, {
		parameters,
	});
}

function initialParameters(taskDefinition: ITaskDefinition) {
	return taskDefinition.parameters.reduce((parameters, current) => {
		if (current.default) {
			// Use default value if defined
			parameters[current.name] = current.default;
		} else if (current.type === 'Date') {
			// Use current date and time
			parameters[current.name] = new Date().toISOString().substring(0, 16);
		}

		return parameters;
	}, {} as ITaskParameters);
}

export default function CreateTaskDialog({ tasksDefinition, onClose }: ICreateTaskDialogProps) {
	const classes = useStyles();
	const [selectedTaskDefinition, setSelectedTaskDefinition] = useState(tasksDefinition[0]);
	const [requestStatus, setRequestStatus] = useState(Status.None);
	const [parameters, setParameters] = useState(initialParameters(selectedTaskDefinition) as ITaskParameters);

	return (
		<Dialog open={true} onClose={() => onClose()}>
			<DialogTitle>New data replay task</DialogTitle>
			<DialogContent>
				<div className={classes.container}>
					<FormControl className={classes.input}>
						<InputLabel htmlFor="task-type-input">Task type</InputLabel>
						<Select
							native={true}
							value={selectedTaskDefinition.orchestratorName}
							inputProps={{
								id: 'task-type-input',
							}}
							onChange={e => {
								const task = tasksDefinition.find(t => t.orchestratorName === e.currentTarget.value);

								if (task) {
									setParameters(initialParameters(selectedTaskDefinition));
									setSelectedTaskDefinition(task);
								}
							}}
						>
							{tasksDefinition.map(def => (
								<option key={def.orchestratorName} value={def.orchestratorName}>
									{def.displayName}
								</option>
							))}
						</Select>
					</FormControl>
					{selectedTaskDefinition.parameters.map(param => {
						const value = parameters[param.name] !== undefined ? parameters[param.name] : param.default;

						switch (param.type) {
							case 'boolean':
								return (
									<FormControlLabel
										control={
											<Checkbox
												checked={Boolean(value)}
												onChange={e => {
													setParameters({ ...parameters, [param.name]: e.target.checked });
												}}
											/>
										}
										label={param.label}
										key={param.name}
										className={classes.input}
									/>
								);
							case 'Date':
								return (
									<TextField
										label={param.label}
										type="datetime-local"
										value={value ? String(value).substring(0, 16) : undefined}
										InputLabelProps={{
											shrink: true,
										}}
										onChange={e => {
											const date = new Date(e.target.value);
											setParameters({ ...parameters, [param.name]: date.toISOString() });
										}}
										required={!param.optional}
										key={param.name}
										className={classes.input}
									/>
								);
							case 'number':
								return (
									<TextField
										label={param.label}
										type="number"
										value={value !== undefined ? Number(value) : undefined}
										InputLabelProps={{
											shrink: true,
										}}
										onChange={e => {
											setParameters({ ...parameters, [param.name]: Number(e.target.value) });
										}}
										required={!param.optional}
										key={param.name}
										className={classes.input}
									/>
								);
							default:
								return (
									<TextField
										label={param.label}
										value={value}
										onChange={e => {
											setParameters({ ...parameters, [param.name]: e.target.value });
										}}
										required={!param.optional}
										key={param.name}
										className={classes.input}
									/>
								);
						}
					})}
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose()} color="primary">
					Cancel
				</Button>
				<Button
					onClick={async () => {
						setRequestStatus(Status.Loading);

						try {
							await createTask(selectedTaskDefinition.orchestratorName, parameters);
							setRequestStatus(Status.Done);
							onClose();
						} catch (error) {
							handleError(new Error(`Failed to create replay task (${error.message})`));
							setRequestStatus(Status.Error);
						}
					}}
					color="primary"
					disabled={requestStatus === Status.Loading}
				>
					Create task
				</Button>
			</DialogActions>
		</Dialog>
	);
}

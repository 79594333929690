import * as React from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import Entity from 'store/entity';
import { observer } from 'mobx-react';
import { autorun, IReactionDisposer, observable } from 'mobx';
import SensorGraph from './SensorGraph';

const styles = (theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
		},
		listContainer: {
			flexGrow: 0,
		},
		graphContainer: {
			flexGrow: 1,
		},
	});

interface IDevicePointsListProps extends WithStyles<typeof styles> {
	entity: Entity;
}

@observer
class DevicePointsList extends React.Component<IDevicePointsListProps> {
	private disposables: IReactionDisposer[] = [];
	@observable private selectedPoint?: Entity;

	public componentDidMount() {
		this.disposables.push(
			autorun(() => {
				const { entity } = this.props;

				if (!entity.childrenRequest.device) {
					entity.fetchChildren('device');
				} else if (entity.children.device) {
					this.selectedPoint = entity.children.device[0];
				} else {
					this.selectedPoint = undefined;
				}
			}),
		);
	}

	public componentWillUnmount() {
		this.disposables.forEach(d => d());
	}

	public render() {
		const { entity, classes } = this.props;

		return (
			<div className={classes.container}>
				<div className={classes.listContainer}>
					<List>
						{entity.children.device &&
							entity.children.device.map(pointEntity => (
								<ListItem
									key={pointEntity.id}
									button={true}
									selected={this.selectedPoint && pointEntity.id === this.selectedPoint.id}
									onClick={() => (this.selectedPoint = pointEntity)}
								>
									<ListItemText
										primary={pointEntity.displayName}
										secondary={`Type: ${pointEntity.getProperty('datatype')}`}
									/>
								</ListItem>
							))}
					</List>
				</div>
				<div className={classes.graphContainer}>
					{this.selectedPoint && <SensorGraph entity={this.selectedPoint} />}
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(DevicePointsList);

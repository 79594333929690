import { IApiTemplateUpdate, IDevicePointTemplate, IApiTemplate } from '@mitie/metadata-api-types';
import { action } from 'mobx';

import { Template } from './template';
import stores from './index';
import { Status } from 'DataTypes';
import * as ApiService from '../services/apiService';
import { logError } from 'errors';

export class DevicePointTemplate extends Template<IDevicePointTemplate> {
	public constructor(id: string) {
		super(id, 'device_point');
	}

	@action.bound
	public setDataFromUi(template: IApiTemplateUpdate<IDevicePointTemplate>) {
		this.data = this.extractDetails(template);
	}

	@action.bound
	public async save() {
		if (this.unsaved) {
			this.saveRequest = Status.Loading;

			try {
				if (this.created) {
					const data: IApiTemplate<IDevicePointTemplate> = await ApiService.post(
						`${process.env.REACT_APP_API_URL}/metadata/secure/templates`,
						this.parseForApi(),
					);
					this.setDataFromApi(data, true);
				} else if (this.deleted) {
					await ApiService.delete_(
						`${process.env.REACT_APP_API_URL}/metadata/secure/templates/device_point/${this.id}`,
					);

					stores.devicePointTemplates.deleteTemplate(this);
					this.dataRequest = Status.Done;
				} else {
					const data: IApiTemplate<IDevicePointTemplate> = await ApiService.put(
						`${process.env.REACT_APP_API_URL}/metadata/secure/templates/${this.id}`,
						this.parseForApi(),
					);
					this.setDataFromApi(data, true);
				}

				this.saveRequest = Status.Done;
			} catch (error) {
				logError(error);
				this.saveRequest = Status.Error;
			}
		}
	}
}

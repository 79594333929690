import createRouter, { Plugin, PluginFactory, Router, State, Route, Options } from 'router5';
import browserPlugin from 'router5-plugin-browser';
import { IRoutes } from './routes';
import RoutesStore from '../store/routes';

function makeMobxRouterPlugin(routes: IRoutes, store: RoutesStore): PluginFactory {
	function mobxRouterPlugin(): Plugin {
		return {
			onTransitionStart(nextState: State) {
				store.onTransitionStart(nextState);
			},
			onTransitionSuccess(nextState?: State, prevState?: State) {
				const prevParams = (prevState || ({} as any)).params || {};
				const nextParams = nextState ? nextState.params || {} : {};
				const prevRoute = routes[(prevState || ({} as any)).name];
				const nextRoute = nextState ? routes[nextState.name] : null;

				if (prevRoute != null && prevRoute.deactivate != null) {
					prevRoute.deactivate(store, prevParams, nextState);
				}

				if (nextRoute && nextRoute.activate) {
					nextRoute.activate(store, nextParams, prevState || ({} as any));
				}
			},
		};
	}

	mobxRouterPlugin.pluginName = 'MOBX_PLUGIN';

	return mobxRouterPlugin;
}

export function makeMobxRouter(routes: IRoutes, options: Partial<Options>, store: RoutesStore): Router {
	Object.entries(routes).forEach(([key, route]) => (route.name = key));
	const router = createRouter(Object.values(routes) as Route[], options);
	router.usePlugin(browserPlugin(), makeMobxRouterPlugin(routes, store));
	return router;
}
